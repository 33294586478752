import { put, SagaReturnType, call } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helper';
import { ActionSaga } from 'src/types/saga.types';
import { createPayPalSuccess, executePayPalSuccess, paypalError } from './actions';
import { createPayPalAPI, createPayment, executePayPalAPI } from './apis';
import { CreatePayPalBody, ExecutePayPal } from './types';
import { PaymentBody } from 'src/features/cart/types';

export function* createPayPalWORKER({ payload }: ActionSaga<CreatePayPalBody>) {
  try {
    if (!payload) {
      throw new Error('payload is required');
    }
    const { data }: SagaReturnType<typeof createPayPalAPI> = yield call(createPayPalAPI, payload);
    yield put(createPayPalSuccess(data.data));

    if (payload.callback) {
      payload.callback(data.data);
    }

    // showSuccessToast('Create PayPal success');
  } catch (err) {
    yield put(paypalError());
    showErrorToast('Create PayPal error');
    console.error(err);
  }
}

export function* createPaymentWORKER({ payload }: ActionSaga<PaymentBody>) {
  try {
    if (!payload) {
      throw new Error('payload is required');
    }
    const { data } = yield call(createPayment, payload);
    yield put(createPayPalSuccess(data));

    if (payload.callback) {
      payload.callback(data);
    }

    // showSuccessToast('Create PayPal success');
  } catch (err) {
    yield put(paypalError());
    showErrorToast('Create PayPal error');
    console.error(err);
  }
}

export function* executePayPalWORKER({ payload }: ActionSaga<ExecutePayPal>) {
  try {
    if (!payload) {
      throw new Error('payload is required');
    }
    const { data }: SagaReturnType<typeof executePayPalAPI> = yield call(executePayPalAPI, payload);
    yield put(executePayPalSuccess());

    if (data.isSuccess) {
      showSuccessToast('Execute PayPal success');
      if (payload.callback) {
        payload.callback(data.data);
      }
    } else {
      showErrorToast('Execute PayPal error');
    }
  } catch (err) {
    yield put(paypalError());
    showErrorToast('Execute PayPal error');
    console.error(err);
  }
}
