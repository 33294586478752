import React from 'react';
import { Table } from 'react-bootstrap';
import Section from 'src/components/section/Section';
import table1 from 'src/assets/images/laserPrinting/table1.png';
import table2 from 'src/assets/images/laserPrinting/table2.png';
import table3 from 'src/assets/images/laserPrinting/table3.png';
import table4 from 'src/assets/images/laserPrinting/table4.png';
import { useTranslation } from 'react-i18next';

export const AvailableResource = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Section
        align="left"
        title={t('laserprintingservice available materials') as string}
        extra={t('laserprintingservice available materials context')}
        styleContent={{
          padding: '2rem 0',
        }}
      >
        <Table bordered size="xl" responsive>
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th>{t('material')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nylon 12 (PA 12)</td>
              <td>{t('laserprintingservice nylon desc')}</td>
            </tr>
            <tr>
              <td>{t('Glass-filled Nylon (PA 12 GF)')}</td>
              <td>{t('laserprintingservice glass-filled desc')}</td>
            </tr>
          </tbody>
        </Table>
      </Section>
      <Section
        align="left"
        title={t('laserprintingservice available surface') as string}
        extra={t('laserprintingservice available surface context')}
        styleContent={{
          padding: '2rem 0',
        }}
      >
        <Table bordered size="xl" className="min-800" responsive>
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th></th>
              <th>{t('material')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img src={table1} alt="" />
              </td>
              <td>{t('as printed')}</td>
              <td>{t('laserprintingservice nylon desc')}</td>
            </tr>
            <tr>
              <td>
                <img src={table2} alt="" />
              </td>
              <td>{t('tumble smoothing')} *</td>
              <td>{t('laserprintingservice tumble smoothing desc')}</td>
            </tr>
            <tr>
              <td>
                <img src={table3} alt="" />
              </td>
              <td>{t('dyed (black)')}</td>
              <td>{t('laserprintingservice dyed (black) desc')}</td>
            </tr>
            <tr>
              <td>
                <img src={table4} alt="" />
              </td>
              <td>{t('spray painting')} *</td>
              <td>{t('laserprintingservice spray painting desc')}</td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </div>
  );
};
