import { combineReducers } from 'redux';
import adminUserReducer from '../features/adminUser/reducers';
import authenticationReducer from '../features/authentication/reducers';
import cartReducer from '../features/cart/reducers';
import shippingAddressReducer from '../features/shippingAddress/reducers';
import createOrderReducer from '../features/createOrder/reducer';
import billingAddressReducer from '../features/billingAddress/reducer';
import paypalReducer from '../features/paypal/reducer';
import orderReducer from '../features/order/reducers';
import systemReducer from '../features/system/reducer';

export const sagaReducers = combineReducers({
  authentication: authenticationReducer,
  adminUser: adminUserReducer,
  cart: cartReducer,
  shippingAddress: shippingAddressReducer,
  billingAddress: billingAddressReducer,
  createOrder: createOrderReducer,
  paypal: paypalReducer,
  order: orderReducer,
  system: systemReducer,
});
