import React from 'react';
import B2CBanner from '../../components/resources/TopicBanner';
import ListPost from 'src/pages/components/resources/ListPost';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';

const ResourceTopic = () => {
  return (
    <>
      <B2CBanner />

      <ListPost />

      <DesignReadyBanner />
    </>
  );
};

export default React.memo(ResourceTopic);
