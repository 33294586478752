import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { CartReducer } from './types';
import {
  cartError,
  changeQuantityCart,
  createFromOrder,
  createFromOrderSuccess,
  createOrUpdateCart,
  createOrUpdateCartSuccess,
  createPayment,
  deleteCart,
  deleteCartSuccess,
  emptyCart,
  emptyCartSuccess,
  getUseCart,
  getUseCartSuccess,
  getViewCart,
  getViewCartSuccess,
} from './actions';

const initialState = {};

const cartReducer = createReducer<CartReducer>(initialState, (builder) => {
  builder.addCase(cartError, (state) => {
    state.ui = {};
  });
  builder.addCase(createOrUpdateCartSuccess, (state, { payload }) => {
    state.ui = { loadingBtn: false };
  });
  builder.addCase(deleteCartSuccess, (state, { payload }) => {
    state.ui = { loadingBtn: false };
  });
  builder.addCase(emptyCartSuccess, (state, { payload }) => {
    state.ui = { loadingBtn: false };
  });
  builder.addCase(getUseCartSuccess, (state, { payload }) => {
    state.ui = { loading: false };
    // state.carts = payload;
  });
  builder.addCase(createFromOrderSuccess, (state, { payload }) => {
    state.ui = { ...state, loadingBtn: false };
  });
  builder.addCase(getViewCartSuccess, (state, { payload }) => {
    state.ui = { loading: false };
    state.carts = payload;
  });
  builder.addMatcher(
    isAnyOf(
      createOrUpdateCart,
      changeQuantityCart,
      deleteCart,
      emptyCart,
      createFromOrder,
      createPayment
    ),
    (state) => {
      state.ui = { loadingBtn: true };
    }
  );
  builder.addMatcher(isAnyOf(getUseCart, getViewCart), (state) => {
    state.ui = { loading: true };
  });
});

export default cartReducer;
