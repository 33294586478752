import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  createOrder,
  createOrderError,
  createOrderSuccess,
  getBillingAddressByMemberInsideCreateOrder,
  getBillingAddressByMemberInsideCreateOrderSuccess,
  getCartInsideCreateOrder,
  getCartInsideCreateOrderSuccess,
  getListLeadTime,
  getListLeadTimeSuccess,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getShippingAddressByMemberInsideCreateOrder,
  getShippingAddressByMemberInsideCreateOrderSuccess,
  getShippingOptionCreateOrder,
  getShippingOptionCreateOrderSuccess,
  setFormCreateOrder,
  setPaymentMethodId,
} from './actions';
import { CreateOrderReducer } from './types';

const initialState = {};

const createOrderReducer = createReducer<CreateOrderReducer>(initialState, (builder) => {
  builder.addCase(createOrderError, (state) => {
    state.ui = {};
  });
  builder.addCase(getShippingAddressByMemberInsideCreateOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.shippingAddresses = payload;
  });
  builder.addCase(getBillingAddressByMemberInsideCreateOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.billingAddresses = payload;
  });
  builder.addCase(getCartInsideCreateOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.carts = payload;
  });
  builder.addCase(createOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addCase(setFormCreateOrder, (state, { payload }) => {
    state.form = { ...state.form, ...payload };
  });
  builder.addCase(getListLeadTimeSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.leadTime = payload;
  });
  builder.addCase(getShippingOptionCreateOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.shippingOptions = payload;
  });
  builder.addCase(setPaymentMethodId, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.paymentMethodId = payload;
  });
  builder.addCase(getPaymentMethodsSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.paymentMethods = payload;
  });

  builder.addMatcher(
    isAnyOf(
      getShippingAddressByMemberInsideCreateOrder,
      getBillingAddressByMemberInsideCreateOrder,
      getCartInsideCreateOrder,
      getListLeadTime,
      getShippingOptionCreateOrder,
      getPaymentMethods
    ),
    (state) => {
      state.ui = { ...state.ui, loading: true };
    }
  );
  builder.addMatcher(isAnyOf(createOrder), (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
});

export default createOrderReducer;
