import React from 'react';
import PrintingService from '../../pages/b2c/PrintingService';
import FDMPrintingService from '../../pages/b2c/FDMPrintingService';
import LaserPrintingService from '../../pages/b2c/LaserPrintingService';
import MaterialDetail from '../../pages/b2c/materialPages/MaterialDetail';
import ResourceList from '../../pages/b2c/resourcePages/ResourceList';
import ResourceTopic from '../../pages/b2c/resourcePages/ResourceTopic';
import AboutUs from '../../pages/aboutUs';
import OurPlatform from '../../pages/ourPlatform';
import ResourceDetail from '../../pages/b2c/resourcePages/Post1';
import Post1 from '../../pages/b2c/resourcePages/Post1';
import Post3 from '../../pages/b2c/resourcePages/Post3';
import Post2 from '../../pages/b2c/resourcePages/Post2';
import Post4 from '../../pages/b2c/resourcePages/Post4';
import Post5 from '../../pages/b2c/resourcePages/Post5';
import Post6 from '../../pages/b2c/resourcePages/Post6';
import Base1 from '../../pages/b2c/resourcePages/Base1';
import Base2 from '../../pages/b2c/resourcePages/Base2';
import Base3 from '../../pages/b2c/resourcePages/Base3';
import Base4 from '../../pages/b2c/resourcePages/Base4';
import Base5 from '../../pages/b2c/resourcePages/Base5';
import Base6 from '../../pages/b2c/resourcePages/Base6';
import SLAPrintingService from '../../pages/b2c/SLAPrintingService';
import MJFPrintingService from '../../pages/b2c/MJFPrintingService';
import TermAndCondition from '../../pages/termAndCondition';
import PaymentAndConversion from '../../pages/paymentAndConversion';
import PrintingIstructions from '../../pages/PrintingInstructions';
import privacyPolicy from 'src/pages/privacyPolicy';

const Login = React.lazy(() => import('../../pages/login/Login'));
const ForgotPassword = React.lazy(() => import('../../pages/forgotPassword/ForgotPassword'));
const CreateAccount = React.lazy(() => import('../../pages/createAccount/CreateAccount'));
const HomePage = React.lazy(() => import('../../pages/b2c/HomePage'));

const routes = [
  { path: '/', exact: true, name: 'Dashboard', element: HomePage },
  { path: '/login', exact: true, name: 'Login', element: Login },
  { path: '/forgot-password', exact: true, name: 'ForgotPassword', element: ForgotPassword },
  { path: '/sign-up', exact: true, name: 'ForgotPassword', element: CreateAccount },
  //printing service
  { path: '/printing-service', exact: true, name: 'PrintingService', element: PrintingService },
  {
    path: '/fdm-printing-service',
    exact: true,
    name: 'FDMPrintingService',
    element: FDMPrintingService,
  },
  {
    path: '/laser-printing-service',
    exact: true,
    name: 'LaserPrintingService',
    element: LaserPrintingService,
  },
  {
    path: '/sla-printing-service',
    exact: true,
    name: 'SLAPrintingService',
    element: SLAPrintingService,
  },
  {
    path: '/mjf-printing-service',
    exact: true,
    name: 'MJFPrintingService',
    element: MJFPrintingService,
  },
  {
    path: '/material/:materialCode',
    exact: true,
    name: 'Material Detail',
    element: MaterialDetail,
  },
  {
    path: '/material/:materialCode/:materialColor',
    exact: true,
    name: 'Material Detail',
    element: MaterialDetail,
  },
  {
    path: '/resources',
    exact: true,
    name: 'ResourceList',
    element: ResourceList,
  },
  {
    path: '/resources/topic',
    exact: true,
    name: 'ResourceTopic',
    element: ResourceTopic,
  },
  {
    path: '/resources/topic/knowledge-base/3d-printing',
    exact: true,
    name: 'Base1',
    element: Base1,
  },
  {
    path: '/resources/topic/knowledge-base/cnc-machining',
    exact: true,
    name: 'Base2',
    element: Base2,
  },
  {
    path: '/resources/topic/knowledge-base/injection-molding',
    exact: true,
    name: 'Base3',
    element: Base3,
  },
  {
    path: '/resources/topic/knowledge-base/design-guidelines',
    exact: true,
    name: 'Base4',
    element: Base4,
  },
  {
    path: '/resources/topic/knowledge-base/cad-&-file-preparation',
    exact: true,
    name: 'Base5',
    element: Base5,
  },
  {
    path: '/resources/topic/knowledge-base/educational-resources',
    exact: true,
    name: 'Base6',
    element: Base6,
  },
  {
    path: '/resources/topic/detail',
    exact: true,
    name: 'ResourceDetail',
    element: ResourceDetail,
  },
  {
    path: '/resources/topic/Summary-of-popular-3D-printing-materials-today',
    exact: true,
    name: 'Post1',
    element: Post1,
  },
  {
    path: '/resources/topic/What-is-PLA-plastic',
    exact: true,
    name: 'Post2',
    element: Post2,
  },
  {
    path: '/resources/topic/Things-to-know-about-ABS-in-3D-printing-technology',
    exact: true,
    name: 'Post3',
    element: Post3,
  },
  {
    path: '/resources/topic/3d-printing-technology-in-clouds-wearing-fashion',
    exact: true,
    name: 'Post4',
    element: Post4,
  },
  {
    path: '/resources/topic/application-of-3d-printing-in-construction---a-breakthrough-in-technology',
    exact: true,
    name: 'Post5',
    element: Post5,
  },
  {
    path: '/resources/topic/the-application-of-3d-printing-technology-is-becoming-a-hot-global-trend',
    exact: true,
    name: 'Post6',
    element: Post6,
  },
  //copmpany
  {
    path: '/aboutus',
    exact: true,
    name: 'AboutUs',
    element: AboutUs,
  },
  {
    path: '/ourplatform',
    exact: true,
    name: 'OurPlatform',
    element: OurPlatform,
  },
  {
    path: '/terms-condition',
    exact: true,
    name: 'terms-&-condition',
    element: TermAndCondition,
  },
  {
    path: '/privacy-policy',
    exact: true,
    name: 'privacy-&-policy',
    element: privacyPolicy,
  },
  {
    path: '/payment-shipping',
    exact: true,
    name: 'payment-&-shipping',
    element: PaymentAndConversion,
  },
  {
    path: '/printing-instructions',
    exact: true,
    name: 'payment-&-conversion',
    element: PrintingIstructions,
  },
];

export default routes;
