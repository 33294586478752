import React from 'react';
import CardStatistic from 'src/components/card/CardStatistic';
import Section from 'src/components/section/Section';
import world from 'src/assets/images/home/world.png';
import house from 'src/assets/images/home/house.png';
import handShake from 'src/assets/images/home/handShake.png';
import monitor from 'src/assets/images/home/monitor.png';
import process from 'src/assets/images/home/process.png';
import machine from 'src/assets/images/home/machine.png';
import { useTranslation } from 'react-i18next';

const B2CExperience = () => {
  const { t } = useTranslation();
  return (
    <Section
      className="home-v1hub-works v1hub-our-experience"
      title={t('homepage our experience') as string}
      style={{ background: '#F5F5F5' }}
    >
      <div className="b2c-container">
        <div className="row row-gap-5">
          <div className="col-md-12 col-lg-4">
            <CardStatistic
              src={world}
              title="145,000"
              description={t('engineers served') as string}
            />
          </div>
          <div className="col-md-12 col-lg-4">
            <CardStatistic src={house} title="35,000" description={t('businesses') as string} />
          </div>
          <div className="col-md-12 col-lg-4">
            <CardStatistic
              src={handShake}
              title="240"
              description={t('manufacturing partners') as string}
            />
          </div>
          <div className="col-md-12 col-lg-4">
            <CardStatistic
              src={monitor}
              title="6M+"
              description={t('parts & prototypes produced') as string}
            />
          </div>
          <div className="col-md-12 col-lg-4">
            <CardStatistic
              src={process}
              title="27%"
              description={t('160 Countries Shipped') as string}
            />
          </div>
          <div className="col-md-12 col-lg-4">
            <CardStatistic src={machine} title="4,200+" description={t('machines') as string} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(B2CExperience);
