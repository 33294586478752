import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';
import arrowRight from 'src/assets/images/arrow-right.png';

const Post4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        descriptionSmall={t('resourcestopic 3D Printing Technology banner content') as string}
        titleDark={t('resourcestopic 3D Printing Technology banner title') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <p>{t('resourcestopic 3D Printing Technology title desc')}</p>
          <h1>{t('resourcestopic 3D Printing Technology post title')}</h1>
          <br />
          <div className="post-content--section">
            <p>{t('resourcestopic 3D Printing Technology desc post 1')}</p>
            <h3>{t('resourcestopic 3D Printing Technology why section')}</h3>
            <p>{t('resourcestopic 3D Printing Technology why answer 1')}</p>
            <p>{t('resourcestopic 3D Printing Technology why answer 2')}</p>
            <p>{t('resourcestopic 3D Printing Technology why answer 3')}</p>
            <p>{t('resourcestopic 3D Printing Technology why answer 4')}</p>
            <p>{t('resourcestopic 3D Printing Technology why answer 5')}</p>
            <h3>{t('resourcestopic 3D Printing Technology what section')}</h3>
            <p>{t('resourcestopic 3D Printing Technology what answer 1')}</p>
            <p>{t('resourcestopic 3D Printing Technology what sub answer 1')}</p>
            <p>{t('resourcestopic 3D Printing Technology what answer 2')}</p>
            <p>{t('resourcestopic 3D Printing Technology what sub answer 2')}</p>
            <p>{t('resourcestopic 3D Printing Technology what answer 3')}</p>
            <p>{t('resourcestopic 3D Printing Technology what sub answer 3')}</p>
            <p>{t('resourcestopic 3D Printing Technology what answer 4')}</p>
            <p>{t('resourcestopic 3D Printing Technology what sub answer 4')}</p>
            <h3>{t('resourcestopic 3D Printing Technology post title 2')}</h3>
            <p>{t('resourcestopic 3D Printing Technology desc post 2')}</p>
            <p>{t('resourcestopic 3D Printing Technology desc post context 1')}</p>
            <p>{t('resourcestopic 3D Printing Technology desc post context 2')}</p>
            <h3>{t('resourcestopic 3D Printing Technology reason 1')}</h3>
            <p>{t('resourcestopic 3D Printing Technology sub reason 1 content 1')}</p>
            <p>{t('resourcestopic 3D Printing Technology sub reason 1 content 2')}</p>
            <h3>{t('resourcestopic 3D Printing Technology reason 2')}</h3>
            <p>{t('resourcestopic 3D Printing Technology sub reason 2 context')}</p>
            <p>{t('resourcestopic 3D Printing Technology conclude')}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Post4);
