import React from 'react';
import Button from 'src/components/button/Button';
import Section from 'src/components/section/Section';
import FDM from 'src/assets/images/3dPrinting/FDM.png';
import SLS from 'src/assets/images/3dPrinting/SLS.png';
import MJF from 'src/assets/images/3dPrinting/MJF.png';
import SLA from 'src/assets/images/3dPrinting/SLA.png';
import CardImage from 'src/components/card/CardImage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const B2CServices = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Section
      className="b2c-container home-product"
      title={t('our online 3d printing service') as string}
      extra={t('printingservice online 3d printing service context')}
      extraProps={{
        style: {
          maxWidth: '846px',
          margin: 'auto',
        },
      }}
    >
      <div className="b2c-container mt-5 row-gap-5">
        <div className="row b2c-row justify-content-center align-items-stretch row-gap-5">
          <div
            className="col-6 col-md-3 col-lg-3"
            onClick={() => {
              navigate('/fdm-printing-service');
            }}
          >
            <CardImage className="card-image-fw" size="lg" src={FDM} title="FDM" />
          </div>
          <div
            className="col-6 col-md-3 col-lg-3"
            onClick={() => {
              navigate('/laser-printing-service');
            }}
          >
            <CardImage className="card-image-fw" size="lg" src={SLS} title="SLS" />
          </div>
          <div
            className="col-6 col-md-3 col-lg-3"
            onClick={() => {
              navigate('/mjf-printing-service');
            }}
          >
            <CardImage className="card-image-fw" size="lg" src={MJF} title="MJF" />
          </div>
          <div
            className="col-6 col-md-3 col-lg-3"
            onClick={() => {
              navigate('/sla-printing-service');
            }}
          >
            <CardImage className="card-image-fw" size="lg" src={SLA} title="SLA" />
          </div>
        </div>
        <div className="mt-5 text-center d-flex justify-content-center">
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              navigate('/get-instant-quote');
            }}
          >
            {t('get an instant 3d printing quote')}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(B2CServices);
