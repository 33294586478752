/**
 * For process asynchronous
 * Handle the action and terminate
 */

import toast from 'react-hot-toast';
import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  forgotPasswordError,
  forgotPasswordSuccess,
  idmRegisterError,
  idmRegisterSuccess,
  idmVerifyEmailError,
  idmVerifyEmailSuccess,
} from './actions';
import { forgotUserAPI, registerUserAPI, verifyEmailAPI } from './apis';
import { CreateAccountForm, ForgotForm, VerifyEmail } from './types';
import { t } from 'i18next';

export function* registerUserWorker(
  action: ActionSaga<{ form: CreateAccountForm; callback: () => void }>
) {
  try {
    const { payload } = action || {};
    if (payload) {
      const { data }: SagaReturnType<typeof registerUserAPI> = yield call(
        registerUserAPI,
        payload.form
      );
      yield put(idmRegisterSuccess());

      yield call(payload.callback);
    }
  } catch (e) {
    yield put(idmRegisterError());
    console.error('Error', e);
    toast.error('Create account failed!');
  }
}

export function* forgotPasswordWorker(action: ActionSaga<ForgotForm>) {
  try {
    const { payload } = action || {};
    if (payload) {
      const { data }: SagaReturnType<typeof forgotUserAPI> = yield call(forgotUserAPI, payload);
      yield put(forgotPasswordSuccess());
      toast.success(t('Action Success'));
    }
  } catch (e) {
    yield put(forgotPasswordError());
    console.error('Error', e);
    toast.error(t('Action failed!'));
  }
}

export function* verifyEmailWorker(action: ActionSaga<VerifyEmail>) {
  try {
    const { payload } = action || {};
    if (payload) {
      const { data }: SagaReturnType<typeof verifyEmailAPI> = yield call(verifyEmailAPI, payload);
      yield put(idmVerifyEmailSuccess());
      toast.success(t('Action Success'));
    }
  } catch (e) {
    yield put(idmVerifyEmailError());
    console.error('Error', e);
    toast.error(t('Action failed!'));
  }
}
