import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { BillingAddressReducer } from './types';
import {
  billingAddressError,
  createBillingAddress,
  createBillingAddressSuccess,
  updateBillingAddress,
  updateBillingAddressSuccess,
} from './actions';

const initialState = {};

const billingAddressReducer = createReducer<BillingAddressReducer>(initialState, (builder) => {
  builder.addCase(billingAddressError, (state) => {
    state.ui = {};
  });
  builder.addCase(createBillingAddressSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addCase(updateBillingAddressSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addMatcher(isAnyOf(createBillingAddress, updateBillingAddress), (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
});

export default billingAddressReducer;
