import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'src/components/section/Section';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Section classContent="max-lg:px-[1rem] px-[3rem] pt-0">
      <div className="md:flex-1 w-full post-container">
        <h1 className="font-semibold mb-5 text-center text-5xl">{t('Terms and condition')}</h1>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 0.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 0.2')}</p>

        <h3 className="text-2xl font-semibold mb-1">{t('Terms and condition title 1')}</h3>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 1.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 1.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 1.3')}</p>

        <h3 className="text-2xl font-semibold mb-1">{t('Terms and condition title 2')}</h3>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.3')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.4')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.5')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.6')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.7')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.8')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.9')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.10')}</p>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 2.11')}</p>

        <h3 className="text-2xl font-semibold mb-1">{t('Terms and condition title 3')}</h3>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 3.1')}</p>

        <p className="text-lg font-normal mb-1 ms-3">{t('Terms and condition section 3.1.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Terms and condition section 3.1.2')}</p>

        <h3 className="text-2xl font-semibold mb-1">{t('Terms and condition title 4')}</h3>
        <p className="text-lg font-normal mb-2">{t('Terms and condition section 4.1')}</p>
      </div>
    </Section>
  );
};

export default React.memo(PrivacyPolicy);
