import { axiosInceptors } from 'src/services/axiosInceptors';
import { ShippingAddressBody } from './types';

const ROOT = 'Order/shippingaddress';

export const createShippingAddressAPI = (payload: ShippingAddressBody) => {
  return axiosInceptors.post(ROOT + '/create', { ...payload });
};

export const updateShippingAddressAPI = (payload: ShippingAddressBody) => {
  return axiosInceptors.put(ROOT + '/update', { ...payload });
};

export const getShippingAddressByMemberAPI = () => {
  return axiosInceptors.get(ROOT + '/get-by-member');
};
