import { SagaReturnType, call, put } from 'redux-saga/effects';
import { createBillingAddressAPI, updateBillingAddressAPI } from './apis';
import {
  billingAddressError,
  createBillingAddressSuccess,
  updateBillingAddressSuccess,
} from './actions';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helper';
import { ActionSaga } from 'src/types/saga.types';
import { BillingAddressBody } from './types';

export function* createBillingAddressWORKER({ payload }: ActionSaga<BillingAddressBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof createBillingAddressAPI> = yield call(
      createBillingAddressAPI,
      payload
    );
    yield put(createBillingAddressSuccess(data.data));

    if (payload?.callback) {
      payload.callback<typeof data.data>(data.data);
    }

    showSuccessToast('Create billing address successfully');
  } catch (err) {
    yield put(billingAddressError());
    showErrorToast('Create billing address failed');
    console.error(err);
  }
}

export function* updateBillingAddressWORKER({ payload }: ActionSaga<BillingAddressBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof updateBillingAddressAPI> = yield call(
      updateBillingAddressAPI,
      payload
    );
    yield put(updateBillingAddressSuccess(data.data));

    if (payload?.callback) {
      payload.callback<typeof data.data>(data.data);
    }

    showSuccessToast('Update billing address successfully');
  } catch (err) {
    yield put(billingAddressError());
    showErrorToast('Update billing address failed');
    console.error(err);
  }
}
