import React from 'react';
import Section from 'src/components/section/Section';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useListPost } from 'src/pages/components/resources/hooks/useListPost';

const ListPost = () => {
  const { data } = useListPost();
  return (
    <Section className="posts b2c-container">
      <div className="row row-gap-2 justify-content-start">
        {data.map((item, index) => (
          <div className="col-sm-4 col-md-4 col-lg-4" key={index}>
            <CardProduct src={item.img} name={item.name} position={item.position} uri={item.uri} />
          </div>
        ))}
      </div>
    </Section>
  );
};

type CardProductProps = {
  src?: string;
  name?: string;
  position?: string;
  uri?: string;
};
const CardProduct: React.FC<CardProductProps> = (props) => {
  const { src, name, position, uri = 'detail' } = props;
  const { t } = useTranslation();
  return (
    <div className="card-member has-border">
      <div className="card-member--image">
        <img alt="img-member" src={src} />
      </div>
      <div className="card-product--content">
        <div className="card-product--name">{name}</div>
        <div className="card-product--position">{position}</div>
        <NavLink
          style={{
            fontWeight: '500',
            color: '#0076E2',
            margin: '30px 0 0 0',
            textDecoration: 'none',
            display: 'flex',
          }}
          to={uri}
        >
          {t('read more') as string}
        </NavLink>
      </div>
    </div>
  );
};
export default React.memo(ListPost);
