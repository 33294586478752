import axios, { AxiosRequestConfig } from 'axios';
import { NewAuthentication } from 'src/features/authentication/types';
import { getAccessToken, v1hubManufacturerToken } from 'src/hooks/useAuth';
import { callbackOnError } from 'src/services/axiosInceptors';

const { REACT_APP_MANUFACTURER_API } = process.env;
// console.log('v1hubParse', tokenString);

// export const getAccessToken = () => {
//   const v1hub = localStorage.getItem(v1hubManufacturerToken);
//   const v1hubParse: NewAuthentication =
//     v1hub && v1hub !== 'undefined' ? JSON.parse(v1hub) : undefined;
//   const tokenString = v1hubParse ? v1hubParse?.accessToken : undefined;
//   console.log('v1hubParse', tokenString);

//   if (!tokenString) return null;
//   return tokenString;
// };

const configDownload: AxiosRequestConfig = {
  baseURL: REACT_APP_MANUFACTURER_API,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : undefined,
  },
};

export const axiosInceptorsDownload = axios.create(configDownload);
axiosInceptorsDownload.interceptors.response.use(
  (response) => response,
  (error) => callbackOnError(error)
);

const configBasic: AxiosRequestConfig = {
  baseURL: REACT_APP_MANUFACTURER_API,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  },
};

export const axiosInceptorsBasic = axios.create(configBasic);
axiosInceptorsBasic.interceptors.response.use(
  (response) => response,
  (error) => callbackOnError(error)
);
console.log(configBasic);
