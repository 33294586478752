import React from 'react';
import B2CBanner from '../components/company/B2CBanner';
import BaseIntroduce from 'src/pages/components/company/BaseIntroduce';

const AboutUs = () => {
  return (
    <>
      <B2CBanner />

      <BaseIntroduce />

      {/* <B2CCompanies /> */}
    </>
  );
};

export default React.memo(AboutUs);
