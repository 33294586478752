import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import arrowRight from 'src/assets/images/arrow-right.png';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Base1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        descriptionSmall={t('blog 3D printing banner content') as string}
        titleDark={t('3d printing') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <p>{t('blog 3D printing section 1')}</p>
          <div className="post-content--section">
            <h3>{t('blog 3D printing title 1')}</h3>
            <p>{t('blog 3D printing section 2')}</p>

            <h3>{t('blog 3D printing title 2')}</h3>
            <p>{t('blog 3D printing section 3')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 1')}</h4>
            <p>{t('blog 3D printing section 4')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 2')}</h4>
            <p>{t('blog 3D printing section 5')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 3')}</h4>
            <p>{t('blog 3D printing section 6')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 4')}</h4>
            <p>{t('blog 3D printing section 7')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 5')}</h4>
            <p>{t('blog 3D printing section 8')}</p>
            <p>{t('blog 3D printing section 9')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 6')}</h4>
            <p>{t('blog 3D printing section 10')}</p>

            <h3>{t('blog 3D printing title 3')}</h3>
            <p>{t('blog 3D printing section 11')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 7')}</h4>
            <p>{t('blog 3D printing section 12')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 8')}</h4>
            <p>{t('blog 3D printing section 13')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 9')}</h4>
            <p>{t('blog 3D printing section 14')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 10')}</h4>
            <p>{t('blog 3D printing section 15')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 11')}</h4>
            <p>{t('blog 3D printing section 16')}</p>
            <p>{t('blog 3D printing section 17')}</p>
            <p>{t('blog 3D printing section 18')}</p>
            <p>{t('blog 3D printing section 19')}</p>
            <p>{t('blog 3D printing section 20')}</p>
            <p>{t('blog 3D printing section 21')}</p>
            <h4 className="font-semibold">{t('blog 3D printing subtitle 12')}</h4>
            <p>{t('blog 3D printing section 22')}</p>
            <p>{t('blog 3D printing section 23')}</p>
            <p>{t('blog 3D printing section 24')}</p>
            <p>{t('blog 3D printing section 25')}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Base1);
