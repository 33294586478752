import React from 'react';
import B2CBanner from '../components/slaprintingservice/B2CBanner';
import DescriptionList from '../components/slaprintingservice/DescriptionList';
import IntroduceService from '../components/IntroduceService';
import img1 from 'src/assets/images/img1.png';
import { useTranslation } from 'react-i18next';

const SLAPrintingService = () => {
  const { t } = useTranslation();
  return (
    <>
      <B2CBanner />

      <DescriptionList />

      <IntroduceService
        dark
        image={img1}
        titleExtra={t('laserprintingservice upload designs') as string}
        buttonText={t('get an instant quote') as string}
      />
    </>
  );
};

export default React.memo(SLAPrintingService);
