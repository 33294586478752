import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { OrderReducer } from './types';
import {
  getCountByMember,
  getCountByMemberSuccess,
  getOrderByMember,
  getOrderByMemberSuccess,
  getOrderList,
  getOrderListMore,
  getOrderListMoreSuccess,
  orderError,
  setCanLoadMore,
} from './actions';

const initialState = {};

const orderReducer = createReducer<OrderReducer>(initialState, (builder) => {
  builder.addCase(getOrderByMemberSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.orderDetails = payload;
  });
  builder.addCase(getOrderListMoreSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.orderDetails = state.orderDetails ? [...state.orderDetails, ...payload] : payload;
  });
  builder.addCase(getCountByMemberSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.countByMember = payload;
  });

  builder.addCase(setCanLoadMore, (state, { payload }) => {
    state.canLoadMore = payload;
  });
  builder.addCase(orderError, (state) => {
    state.ui = {};
  });
  builder.addMatcher(
    isAnyOf(getOrderByMember, getCountByMember, getOrderList, getOrderListMore),
    (state) => {
      state.ui = { ...state.ui, loading: true };
    }
  );
});

export default orderReducer;
