import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { ShippingAddressReducer } from './types';
import {
  createShippingAddress,
  createShippingAddressSuccess,
  getByMemberShippingAddress,
  getByMemberShippingAddressSuccess,
  shippingAddressError,
  updateShippingAddress,
  updateShippingAddressSuccess,
} from './actions';

const initialState = {};

const shippingAddressReducer = createReducer<ShippingAddressReducer>(initialState, (builder) => {
  builder.addCase(shippingAddressError, (state) => {
    state.ui = {};
  });
  builder.addCase(createShippingAddressSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addCase(updateShippingAddressSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addCase(getByMemberShippingAddressSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.shippingAddresses = payload;
  });
  builder.addMatcher(isAnyOf(createShippingAddress, updateShippingAddress), (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addMatcher(isAnyOf(getByMemberShippingAddress), (state) => {
    state.ui = { ...state.ui, loading: true };
  });
});

export default shippingAddressReducer;
