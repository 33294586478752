import React from 'react';
import Banner from 'src/components/banner/Banner';
import platformbanner from 'src/assets/images/company/platformbanner.png';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

const OurPlatformBanner = () => {
  const { t } = useTranslation();
  return (
    <Banner
      style={{
        height: '466px',
        background: `url(${platformbanner})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="xl">
        <Banner.Text titleDark={t('ourplatform slogan')} />
      </Container>
    </Banner>
  );
};

export default React.memo(OurPlatformBanner);
