import React from 'react';
import img1 from 'src/assets/images/laserPrinting/img1.png';
import { Table } from 'react-bootstrap';
import Section from 'src/components/section/Section';
import ProductDescription from '../layout/ProductDescription';
import { useTranslation } from 'react-i18next';

export const IntroducePrinting = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="mt-5 auto pt-lg-5">
        <ProductDescription
          rtl
          image={img1}
          title={t('what is selective laser sintering?')}
          extra={
            <>
              <span className="d-block mt-3">{t('laserprintingservice section 1')}</span>
              <span className="d-block mt-3">{t('laserprintingservice section 2')}</span>
              <span className="d-block mt-3">{t('laserprintingservice section 3')}</span>
            </>
          }
        />
      </div>
      <Section
        align="left"
        title={t('our online 3d printing service') as string}
        extra={
          <>
            <p className="mb-2 mt-2">{t('our online 3d printing service sub context 1')}</p>
            <p>{t('our online 3d printing service sub context 2')}</p>
          </>
        }
        styleContent={{ padding: '0', marginBottom: 100 }}
      >
        <Table bordered size="xl" className="mb-1">
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th>{t('standard capabilities')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('maximum build size')} *</td>
              <td>395 mm x 500 mm x 395 mm (15.53&quot; x 19.68&quot; x 15.53&quot;)</td>
            </tr>
            <tr>
              <td>{t('lead time')} *</td>
              <td>3 {t('business days')}</td>
            </tr>
            <tr>
              <td>{t('dimensional accuracy')}</td>
              <td>
                {t('lower limit of', {
                  percent: '0.3%',
                  minimet: '0.3',
                  degree: '0.012&apos;',
                })}
              </td>
            </tr>
            <tr>
              <td>{t('layer thickness')}</td>
              <td>100 μm</td>
            </tr>
            <tr>
              <td>{t('minimum feature size')}</td>
              <td>0.5mm</td>
            </tr>
          </tbody>
        </Table>
        <p
          className="text-title__normal mb-0"
          style={{
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '17px',
            color: '#969696',
          }}
        >
          {t('our online 3d printing service sub context small')}
        </p>
      </Section>
    </div>
  );
};
