import { axiosInceptors } from 'src/services/axiosInceptors';
import { ResponseServerAPI } from 'src/types/server.types';
import { CreateAccountForm, ForgotForm, VerifyEmail } from './types';
import { City, Country, State } from 'src/features/country/types';

const NAME = process.env.REACT_APP_IDENTITY_API;

export const registerUserAPI = (data: CreateAccountForm) => {
  return axiosInceptors.post<ResponseServerAPI>(NAME + 'api/v1/idm/users/register', data);
};

export const forgotUserAPI = (data: ForgotForm) => {
  return axiosInceptors.put<ResponseServerAPI>(
    NAME + 'api/v1/idm/users/forgot-password/' + data.email
  );
};

export const verifyEmailAPI = (data: VerifyEmail) => {
  return axiosInceptors.put<ResponseServerAPI>(NAME + 'api/v1/idm/users/verify-email/', data);
};

export const getCountry = () => {
  return axiosInceptors.get<ResponseServerAPI<Country[]>>(`/Country/getall`);
};

export const getCity = (id: string) => {
  return axiosInceptors.get<ResponseServerAPI<City[]>>(`/City/get/${id}`);
};

export const getState = (id: string) => {
  return axiosInceptors.get<ResponseServerAPI<State[]>>(`/State/get/${id}`);
};
