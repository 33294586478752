import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import post1_1 from 'src/assets/images/resources/post1_1.jpg';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Post1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        titleDark={t('resourcestopic summary title') as string}
        descriptionSmall={t('resourcestopic summary context') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        <div className="post-content">
          <div className="post-content--section">
            <h3>{t('resourcestopic summary article title 1')}</h3>
            <b>{t('resourcestopic summary article subtitle 1')}</b>
            <p>{t('resourcestopic summary article content 1')}</p>

            <b>{t('resourcestopic summary article subtitle 2')}</b>
            <p>{t('resourcestopic summary article content 2')}</p>

            <b>{t('resourcestopic summary article subtitle 3')}</b>
            <p>{t('resourcestopic summary article content 3')}</p>

            {/* <div className="media">
              <img src={post1_1} alt="" />
              <article>{t('resourcestopic banner desc 1')}</article>
            </div> */}

            <h3>{t('resourcestopic summary article title 2')}</h3>
            <p>{t('resourcestopic summary article content 4')}</p>

            <b>{t('resourcestopic summary article subtitle 4')}</b>
            <p>{t('resourcestopic summary article content 5')}</p>
            <p>{t('resourcestopic summary article content 6')}</p>
            <b>{t('resourcestopic summary article subtitle 5')}</b>
            <p>{t('resourcestopic summary article content 7')}</p>

            <p>{t('resourcestopic summary article content 8')}</p>

            <b>{t('resourcestopic summary article subtitle 6')}</b>
            <p>{t('resourcestopic summary article content 9')}</p>
            <p>{t('resourcestopic summary article content 10')}</p>
            <p>{t('resourcestopic summary article content 11')}</p>
            <p>{t('resourcestopic summary article content 12')}</p>
            <p>{t('resourcestopic summary article content 13')}</p>
            <p>{t('resourcestopic summary article content 14')}</p>
            <p>{t('resourcestopic summary article content 15')}</p>
            <p>{t('resourcestopic summary article content 16')}</p>

            <b>{t('resourcestopic summary article subtitle 7')}</b>
            <p>{t('resourcestopic summary article content 17')}</p>

            <b>{t('resourcestopic summary article subtitle 8')}</b>

            <p>{t('resourcestopic summary article content 18')}</p>
            <p>{t('resourcestopic summary article content 19')}</p>
            <p>{t('resourcestopic summary article content 20')}</p>
            <p>{t('resourcestopic summary article content 21')}</p>
          </div>
          <p>{t('resourcestopic summary article content 22')}</p>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Post1);
