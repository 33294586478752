export type DesignGuideContent = {
  id: number;
  code: string;
  contents: { title: string; content: string[]; image?: string }[];
};

export const DesignGuideContents: DesignGuideContent[] = [
  {
    id: 1,
    code: 'Plastic',
    contents: [
      {
        title: 'Wall Thickness',
        image: '/images/design-guide/precious-metal-1.png',
        content: [
          'In 3D Printing, wall thickness refers to the distance between one surface of your model and the opposite sheer surface. For ABS, we recommend a minimum wall thickness of 1.2 mm.',
        ],
      },
      {
        title: 'Surface Quality and Orientation',
        image: '/images/design-guide/plastic/Surface-Quality-and-Orientation.png',
        content: [
          'Many of the characteristics of your 3D print will depend on the Fused Deposition Modeling process – the technology used to build your design in ABS. Because your model will be printed layer by layer, the printing orientation will influence the surface quality and strength. On the left, you can see two parts of the same shape but built with different orientations.',
          'The horizontally printed model clearly shows the “staircase” effect of the printing process. Its surface will be similar to that of a topographic map. If the model is printed vertically, the surface quality will be better.',
          'Think about which surface needs to have the best finish and choose the orientation accordingly when printing.',
        ],
      },
      {
        title: 'Anisotropy',
        image: '/images/design-guide/plastic/Anisotropy.png',
        content: [
          'Because your model is built up layer by layer, it will always have “weak points” caused by its printing orientation. These weak points can cause thin external elements of your design to easily break off. Therefore, avoid features on your model that are parallel to the base or bottom plane and that will require strength to support them.',
        ],
      },
      {
        title: 'Dimensional Accuracy',
        content: [
          'Fused Deposition Modeling is one of the plastic 3D printing processes with the highest dimensional accuracy. Dimensional accuracy doesn’t relate to the detail of your model but to the deviation from the nominal size. The general tolerance of the material we offer is 0.1% with a minimum of ± 200µ.',
        ],
      },
      {
        title: 'Support',
        image: '/images/design-guide/plastic/Support.png',
        content: [
          'Fused Deposition Modeling takes place on a building platform. Since models will be “built in the air”, they must be attached to the supporting platform to prevent them from collapsing. This attachment is referred to as the “support” and is required for any model built using this technology. In addition to keeping the model in place, it also enables the construction of elements that stick out. After the building process is complete, the support is manually removed. Please note that your model may display visible evidence of the removed support structures.',
        ],
      },
      {
        title: 'Base',
        image: '/images/design-guide/plastic/Base.png',
        content: [
          'In order to build your parts, they must be designed with a base on which to attach the support structure. With this in mind, you should take into account that the bottom surface will be rougher than the rest of your model.',
        ],
      },
      {
        title: 'External Support',
        image: '/images/design-guide/plastic/External-Support.png',
        content: [
          'To keep your model in place and prevent it from collapsing while being printed, models that have sections narrower than 45° must be supported. In the example on the left, the bottom of the vase must be supported because it’s narrower than 45°. The rest of the design doesn’t require extra support because it is wider than a 45° angle.',
        ],
      },
      {
        title: 'Internal Support',
        image: '/images/design-guide/plastic/Internal-Support.png',
        content: [
          'The 45° rule also applies to the interior of your model. Any model with an interior section below 45° must be supported. In the example on the left, the model on top must be supported to prevent it from collapsing during the printing process.',
        ],
      },
      {
        title: 'Rule of 45°',
        image: '/images/design-guide/plastic/45-Rule.png',
        content: [
          'The diagram on the left shows you when a model requires support. The “self-supporting” or “safe” zone does not require any support to print the model. For most models, this area ranges from 135° to 45°. When designing an object you would like to print in ABS, keep this safe zone in mind if you are concerned your model will require support to be constructed.',
        ],
      },
      {
        title: 'The Right Space between Your (Moving) Parts',
        image: '/images/design-guide/plastic/Moving-Parts.png',
        content: [
          'When you want to design something like a pearl or chainmail necklace, the spacing between your surfaces is crucial. Spacing will determine the flexibility/bendability of your design. We advise you to keep a minimum space of 0.4 mm between designed surfaces. The more space you can afford the better.',
          'In the example of a necklace, the space you create between your rings will purely depend on their size. With big rings, you can create a lot of space so you can print more of them in a confined area. With small rings, you will have to limit your space to keep a distance between the opposite horizontal or vertical ring in the chain.',
        ],
      },
      {
        title: 'Assembly',
        image: '/images/design-guide/plastic/Assembly.png',
        content: [
          'When designing models that need to be assembled, it’s important to provide enough distance between the parts that will be attached together. A perfect fit in your software package does not mean a perfect fit after printing because your software ignores the friction present in the real world. Therefore, always leave at least 0.3 mm between the different parts.',
        ],
      },
      {
        title: 'Embossed and Engraved Details',
        image: '/images/design-guide/plastic/Embossed-and-Engraved-Details.png',
        content: [
          'In general, engraved text or details are preferable to embossed text or details. For engraved text or surface details, we recommend letters with a minimum line thickness of 1 mm and a depth of 0.3 mm. For embossed text and surface details, we recommend letters that have a line thickness of at least 2.5 mm and a depth of at least 0.5 mm.',
        ],
      },
    ],
  },
  {
    id: 2,
    code: 'Full Color',
    contents: [
      {
        title: 'Wall Thickness',
        image: '/images/design-guide/precious-metal-1.png',
        content: [
          'In 3D printing, wall thickness refers to the distance between one surface of your model and the opposite sheer surface. For the minimum wall thickness you can use is 1mm. However, if you plan on making larger sections, the wall thickness should be increased to at least 1.5 mm.',
          'Please note that our production team will hollow out models with walls that are more than 15 mm thick by default to prevent deformation. The support material will stay trapped inside.',
        ],
      },
      {
        title: 'Hollow out Your Model',
        image: '/images/design-guide/multicolor/Hollow-Out-Your-Mode.png',
        content: [
          'A rule of thumb for hollowing out your models is to maintain a wall thickness of minimum 1.5 mm. We advise to keep the support material trapped in your model. If the trapped support material is not an option, you will need to make at least 2 escape holes with a diameter of 10 mm and increase the wall thickness to at least 5 mm.',
        ],
      },
      {
        title: 'Flat Planes Can Cause Warping',
        image: '/images/design-guide/multicolor/Big-Flat-Planes.png',
        content: [
          'Designing a flat plane is a bad idea. In most cases, your model will deform. This process is called "warping". The key here is to avoid flat planes or hollow out the plane to prevent warping.',
        ],
      },
      {
        title: 'The Right Space between Your (Moving) Parts',
        image: '/images/design-guide/plastic/Moving-Parts.png',
        content: [
          'When you want to design something like a pearl or chainmail necklace, the spacing between your surfaces is crucial. It will determine the flexibility/bendability of your design. We recommend keeping a minimum space of 0.5 mm between designed surfaces. The more space you can afford the better.',
          'The more complex your design is, the more complicated it becomes for the support material to exit the empty spaces. Try to visualize how the support gel will flow through the spaces of your 3D printed design.',
        ],
      },
      {
        title: 'Assembly',
        content: [
          'When designing models that need to be assembled, it’s important to provide enough distance between the parts that will be attached together. A perfect fit in your software package does not mean a perfect fit after printing because your software ignores the friction present in the real world. Therefore, always leave at least 0.6 mm between the different parts.',
        ],
      },
      {
        title: 'Textures',
        image: '/images/design-guide/multicolor/Textures.png',
        content: [
          'If your model has textures, make sure you upload all info to our site. How to check? Well, if you see your texture in the 3D print lab preview, then we have the info. However, if you see a mono-colored or un-textured model, it’s possible there is some info missing. If this happens, check your export settings and the file format used.',
          'Some file formats are able to include textures in the file (e.g. colored STL, VRML, SKP, X3D, and DAE), while others store the texture in a separate image file and map file (OBJ). In the latter case, group all files related to the model into a zip file and upload it to our 3D print lab. To show and handle the textures correctly the archive should not contain any additional folders, this way we will be able to process all necessary info to print it the way you want.',
        ],
      },
      {
        title: 'Improving Colors',
        image: '/images/design-guide/multicolor/Improving-Colors.png',
        content: [
          'A short physics lesson in colors will help you get the best possible hue on your print, because the color you see on your screen can differ slightly from the printer’s colors. A pixel on your screen is composed of Red, Green, and Blue (RGB), while multicolor printers use Cyan, Magenta, Yellow, and Key (Black). Since your screen and our printers do not use the same color system, the set of colors might shift slightly. In both CMYK and RGB, mixing these basic colors creates new color options. CMYK works pretty much like the box of paints you used back in school. If you don’t have the painting color you’re looking for, you need to mix two existing colors together. Adding colors together usually means that the new color will be slightly darker. That’s why this system is called a ‘subtractive color model’. It will lead to a somewhat darker color. Adding all colors together produces black. Screens, however, do not work like our box of paints. Instead of using paint or ink, they use light. The colors of light are red, blue, and green (RBG). When the colors are added together, the result gets brighter. When all three colors overlap, the color becomes white. That’s why this system is called an ‘additive color model’.',
          'When colors are converted from RGB to CMYK, the color intensity changes a bit. So, what you see on your screen and what comes out of the printer might look slightly different. Bright colors on a screen tend to look duller and darker in CMYK. Generally, the brighter, more vivid and vibrant the colors on your screen are, the bigger the difference between your 3D model and your 3D print will be. We advise you to make the contrast, gamma correction and saturation higher. This can be achieved in Photoshop, IrfanView, GIMP or other photo editing software.',
          'Another problem is that computer screens have adjustable settings – so factors like brightness, gamma and color temperature can make a huge difference. Monitors also can vary greatly in color accuracy, depending on the technology (TN, PVA, IPS, OLED) and the calibration setting. Besides, the color gamut or complete subset of colors on the printer is lower compared to the screen. Does this color difference make a huge difference? In most cases, it really doesn’t. But if you need a really precise color that must not change even slightly – let’s say for a 3D model that features skin color – you might want to order a smaller test print first and experiment a little to get the color exactly the way you want it.',
        ],
      },
      {
        title: 'Embossed and Engraved Details',
        image: '/images/design-guide/multicolor/Embossed-and-Engraved-Details.png',
        content: [
          'For engraved text or surface details, we recommend letters with a minimum line thickness of 0.5 mm and a depth of 0.5 mm. For embossed text and surface details, we recommend letters that have a line thickness of at least 0.5 mm and a depth of at least 0.5 mm.',
        ],
      },
    ],
  },
  {
    id: 3,
    code: 'Metal',
    contents: [
      {
        title: 'Wall Thickness',
        image: '/images/design-guide/precious-metal-1.png',
        content: [
          'In 3D printing, wall thickness refers to the distance between one surface of your model and the opposite sheer surface. For Aluminum, the minimum wall thickness you can use is 1 mm.',
        ],
      },
      {
        title: 'Detail Size',
        image: '/images/design-guide/metal/Detail-Size.png',
        content: [
          'Very small levels of detail (as small as 0.25 mm) are also possible when you print in Aluminum, using Direct Metal Laser Sintering (DMLS). Detail sizes refer to the distance between the surface of your model and the surface of your detail. Letters are also considered as details but they have different specifications (explained further below).',
        ],
      },
      {
        title: 'Geometry',
        image: '/images/design-guide/metal/Geometry.png',
        content: [
          'Organic or freeform shapes look more attractive in aluminum than angular shapes, right angles, and straight lines. Angles measuring less than 35° tend to lead to poorer surface quality, while steep angles of more than 35° are likely to have better, smoother surfaces. An overhanging structure (e.g. the underside of a table) will be likely to have poor surface quality.',
          'Your model in Aluminum is 3D-printed using DMLS. It is essential to avoid or minimize stress while printing your model, as it might eventually deform the model. We advise you to round off or fillet the edges in your design. Also, avoid sharp edges for the same reason.',
          'The ideal shape to make when using DMLS is that of a mesh. These shapes are easy to design for this process and deliver the best results.',
        ],
      },
      {
        title: 'Dimensional Accuracy',
        image: '/images/design-guide/metal/Dimensional-Accuracy.png',
        content: [
          'DMLS is the metal 3D printing process with the highest dimensional accuracy by far. Dimensional accuracy doesn’t relate to the detail of your model but to its deviation from the nominal size. The general tolerance of the material we offer is 2%, with a minimum of ±200µ. Normally, the general tolerance for 3D printed Aluminum is 0.2%. However, for typical use, we have chosen an optimal balance between affordability and accuracy.',
        ],
      },
      {
        title: 'Support',
        image: '/images/design-guide/metal/Support.png',
        content: [
          'Aluminum is 3D printed using DMLS. Direct Metal Laser Sintering begins with a bed of aluminum powder, which is then sintered by a laser. Support structures are also 3D printed in aluminum. The support structures keep your model rigid during printing and prevent internal stress and deformations. Walls or overhangs with angles measuring below 40° typically need to be supported as they would otherwise collapse during the printing process. Afterward, the support is removed manually and your part is sandblasted. Some evidence of the removed support structures may still be visible.',
        ],
      },
      {
        title: 'Powder Removal',
        image: '/images/design-guide/metal/Powder-Removal.png',
        content: [
          'Models can be hollowed out. Try to use a minimum wall thickness of 1 mm and foresee a minimum opening of 3 mm in diameter. This opening will serve as an exit for the extra, internal powder. Larger and complex cavities need multiple holes and an increased size. We recommend a minimum diameter of 7 mm. Holes in the center of your model are usually the best as they allow most of the powder to be removed.',
        ],
      },
      {
        title: 'Holes and Threaded Holes',
        image: '/images/design-guide/metal/Holes-Threaded-Holes.png',
        content: [
          'The minimum recommended diameter for a hole is 1 mm. This is the minimal diameter needed to remove internal powder; if the hole is smaller the powder will get stuck inside the geometry. Complex and irregularly shaped holes or interior spaces are impossible for us to inspect or remove the powder from completely. Therefore, we cannot guarantee that these designs will come out of the printer looking exactly like their design. If perfectly straight holes are required, expect the holes in your model to be slightly smaller than designed. You can drill out the hole to match your exact measurement once the part has been built.',
          'Although dimensional accuracy is high, threads cannot be made of this material. If your design requires threaded holes, we recommend making slightly smaller holes in your 3D design file and drilling and cutting out a thread into the holes of the 3D printed part. Small adjustments to the final printed model can be made using traditional mechanical methods.',
        ],
      },
      {
        title: 'Nested Objects, Hinged and Interlinking Parts',
        image: '/images/design-guide/metal/Nested-Objects.png',
        content: [
          'Nested objects (objects floating within another object), hinged parts, and interlinking parts such as chains cannot be made using DMLS because this technology requires the use of support structures.',
        ],
      },
      {
        title: 'Engraved Text and Surface Details',
        image: '/images/design-guide/metal/Engraved-Text-Surface-Details2.png',
        content: [
          'For engraved text or surface details, we recommend letters with a minimum line thickness of 1 mm, a minimum overall height of 5 mm, and a minimum depth of 0.5 mm. For embossed text or surface details, we recommend letters to have a line thickness of at least 1 mm, an overall height of at least 5 mm, and a depth of at least 0.5 mm.',
        ],
      },
    ],
  },
  {
    id: 4,
    code: 'Precious Metal',
    contents: [
      {
        title: 'Wall Thickness',
        image: '/images/design-guide/precious-metal-1.png',
        content: [
          'In 3D printing, wall thickness refers to the distance between one surface of your model and the opposite sheer surface. For most models in Brass, the minimum wall thickness you can use is 0.6 mm for the natural finish and 0.8mm for the gold and color plated finishes. However, when making something small such as the band of a ring, the wall thickness should be at least 1 mm. The fragility of the brass might cause the ring to break when you wear it if the model is too thin.',
        ],
      },
      {
        title: 'High Definition',
        image: '/images/design-guide/precious-metal-2.png',
        content: [
          'The distance or space between two or more parts in your model is called clearance. If there is too little clearance between the parts, they might become fused during the casting of your model. Your model is first printed in wax, placed in a container, and covered in a liquid plaster. Then the wax is burned out, creating a cavity in the now-hardened plaster, leaving a plaster mold ready for casting. If the gap between the different parts in your model is too small, the plaster may not fully flow in or may create overly-thin walls in the mold that break when liquid metal is poured in. Both of these will result in defects in your casted model. Therefore, we advise a minimum gap of 0.3mm. If it is impossible to increase the gap in your design, you can consider fusing the features if their separation is unnecessary.',
        ],
      },
      {
        title: 'Geometry',
        image: '/images/design-guide/precious-metal-3.png',
        content: [
          'Be careful when having large areas of your model connected supported by thin rods. Compare it to a big head resting on a thin neck. Ask yourself if the rods are strong enough to ‘carry’ the weight of the larger area. Also, internal structures are possible to print, but removing support structures, cleaning them and casting them can be difficult or even impossible. If you have doubts about whether your model is feasible, please contact us.',
        ],
      },
      {
        title: 'Hollow Models',
        image: '/images/design-guide/precious-metal-4.png',
        content: [
          'This material allows you to print hollow models. However, keep in mind that you need to incorporate multiple holes or slots so that we can get rid of any unprinted wax resin. In addition, you will need to make sure that the casting plaster is sufficiently supported or your model will not be able to be cast properly. Make as many holes or slots as you possible can. We recommend making two or more holes or slots. Make sure the holes are spread out equally; for example on opposite ends of your design. The holes should have a diameter larger than 1.5 mm.',
        ],
      },
      {
        title: 'Feature Size',
        image: '/images/design-guide/precious-metal-5.png',
        content: [
          'The smallest features in your design should be at least 0.35 mm in diameter and 0.4 mm in height and attached to a solid surface. Details this small are suitable for delicate projects such as micro pavé settings.',
          'Because Brass is not a very strong material, longer features such as rods or prongs (in mesh-like structures) should be at least 0.8 mm in diameter. Otherwise, they might break during casting or even when you wear the piece.',
        ],
      },
      {
        title: 'Clearance',
        image: '/images/design-guide/precious-metal-6.png',
        content: [
          'The distance or space between two or more parts in your model is called clearance. If there is too little clearance between the parts, they might become fused during the casting of your model. Your model is first printed in wax, placed in a container, and covered in a liquid plaster. Then the wax is burned out, creating a cavity in the now-hardened plaster, leaving a plaster mold ready for casting. If the gap between the different parts in your model is too small, the plaster may not fully flow in or may create overly-thin walls in the mold that break when liquid metal is poured in. Both of these will result in defects in your casted model. Therefore, we advise a minimum gap of 0.3mm. If it is impossible to increase the gap in your design, you can consider fusing the features if their separation is unnecessary.',
        ],
      },
      {
        title: 'Polishing',
        image: '/images/design-guide/precious-metal-7.png',
        content: [
          'In order to polish, we have to be able to reach the surfaces. As a rule, we can`t file, sand or polish cavities and small sharp edges will be rounded off. Also, as you can imagine, complex internal, mesh-like structures are also impossible to reach.',
        ],
      },
      {
        title: 'Nested Objects, Hinged and Interlinking Parts',
        image: '/images/design-guide/precious-metal-8.png',
        content: [
          'Nested objects (objects floating within another object), hinged parts, and interlinking parts, such as chains, cannot be made with lost wax casting. The reason is that support structures have to be removed before being cast in Brass.',
        ],
      },
      {
        title: 'Engraved Text and Surface Details',
        image: '/images/design-guide/precious-metal-9.png',
        content: [
          'For embossing or engraving details such as text, we advise you to keep a maximum height/depth-to-width ratio of 1:1. Also, keep in mind the minimum wall thickness of 0.35 mm.',
        ],
      },
    ],
  },
];
