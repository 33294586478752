import React from 'react';
import { Spinner } from 'react-bootstrap';
import { createPortal } from 'react-dom';

interface PageLoadingProps {
  loading?: boolean;
}

const PageLoading: React.FC<PageLoadingProps> = (props) => {
  const { loading } = props;
  const target = document.getElementById('root');
  return loading && target
    ? createPortal(
        <div className="loading-backdrop">
          <div className="spinner">
            <div className="boxes">
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>,
        target
      )
    : null;
};

export default React.memo(PageLoading);
