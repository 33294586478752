import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';
import arrowRight from 'src/assets/images/arrow-right.png';

const Post6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        titleDark={t('resourcestopic hot global trend banner tilte') as string}
        descriptionSmall={t('resourcestopic hot global trend banner sub title') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        <div className="post-content">
          <p>{t('resourcestopic hot global trend article 1')}</p>
          <div className="post-content--section">
            <h3>{t('resourcestopic hot global trend article 2 title')}</h3>
            <p>{t('resourcestopic hot global trend article 2 content 1')}</p>
            <p>{t('resourcestopic hot global trend article 2 content 2')}</p>
            <p>{t('resourcestopic hot global trend article 2 content 3')}</p>
            <h3>{t('resourcestopic hot global trend article 3 title')}</h3>
            <p>{t('resourcestopic hot global trend article 3 content')}</p>
            <h3>{t('resourcestopic hot global trend article 4 title')}</h3>
            <p>{t('resourcestopic hot global trend article 4 content 1')}</p>
            <p>{t('resourcestopic hot global trend article 4 content 2')}</p>
            <p>{t('resourcestopic hot global trend article 4 content 3')}</p>
            <h3>{t('resourcestopic hot global trend article 5 title')}</h3>
            <p>{t('resourcestopic hot global trend article 5 content')}</p>
            <h3>{t('resourcestopic hot global trend article 6 title')}</h3>
            <p>{t('resourcestopic hot global trend article 6 content')}</p>
            <p>{t('resourcestopic hot global trend article 7')}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Post6);
