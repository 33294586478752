import React from 'react';
const VerifySignUp = React.lazy(() => import('../../pages/verify/VerifySignUp'));
const VerifyCreateOrder = React.lazy(() => import('../../pages/order/VerifyCreateOrder'));
const PayPalRedirectUrl = React.lazy(() => import('../../pages/paypal/RedirectUrl'));
const PayPalCancelUrl = React.lazy(() => import('../../pages/paypal/CancelUrl'));
const BrainTreeUrl = React.lazy(() => import('../../pages/paybraintree/BrainTreeUrl'));
const PayosRedirectUrl = React.lazy(() => import('../../pages/payos/RedirectUrl'));

const withoutLayout = [
  {
    path: '/account/email-verify',
    exact: true,
    name: 'CreateOrderVerify',
    element: VerifySignUp,
  },
  {
    path: '/create-order/verify',
    exact: true,
    name: 'CreateOrderVerify',
    element: VerifyCreateOrder,
  },
  {
    path: '/paypal/redirect-url',
    exact: true,
    name: 'PayPalRedirectUrl',
    element: PayPalRedirectUrl,
  },
  {
    path: '/paypal/cancel-url',
    exact: true,
    name: 'PayPalCancelUrl',
    element: PayPalCancelUrl,
  },
  {
    path: '/braintree/new',
    exact: true,
    name: 'BrainTreeUrl',
    element: BrainTreeUrl,
  },
  {
    path: '/payos/redirect-url',
    exact: true,
    name: 'payos-redirect-url',
    element: PayosRedirectUrl,
  },
];

export default withoutLayout;
