import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Section from 'src/components/section/Section';
import craftingTop from 'src/assets/images/3dPrinting/craftingTop.png';
import CardPost from 'src/components/card/CardPost';
import { useTranslation } from 'react-i18next';

const B2CCustom3DProject = () => {
  const { t } = useTranslation();
  return (
    <Section
      align="left"
      className="app-container b2c-container projects"
      title={t('printingservice custom 3d') as string}
      extra={t('printingservice custom 3d context')}
    >
      <div className="cardPost-mobile">
        <Carousel interval={5000}>
          <Carousel.Item>
            <CardPost
              src={craftingTop}
              name={t('homepage crafting') as string}
              position={t('homepage crafting context') as string}
            />
          </Carousel.Item>
          <Carousel.Item>
            <CardPost
              src={craftingTop}
              name={t('printingservice application of 3d') as string}
              position={t('printingservice application of 3d context') as string}
            />
          </Carousel.Item>
          <Carousel.Item>
            <CardPost
              src={craftingTop}
              name={t('printingservice build prototypes of aircraft') as string}
              position={t('printingservice build prototypes of aircraft context') as string}
            />
          </Carousel.Item>
        </Carousel>
        {/* </div> */}
      </div>
      <div className="cardPost-desktop">
        <div className="row row-slide pb-5" style={{ overflow: 'auto' }}>
          <CardPost
            src={craftingTop}
            name={t('homepage crafting') as string}
            position={t('homepage crafting context') as string}
          />

          <CardPost
            src={craftingTop}
            name={t('printingservice application of 3d') as string}
            position={t('printingservice application of 3d context') as string}
          />

          <CardPost
            src={craftingTop}
            name={t('printingservice build prototypes of aircraft') as string}
            position={t('printingservice build prototypes of aircraft context') as string}
          />
        </div>
      </div>
    </Section>
  );
};

export default React.memo(B2CCustom3DProject);
