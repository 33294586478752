import React from 'react';
const AccountPage = React.lazy(() => import('../../pages/account'));
const ShippingPage = React.lazy(() => import('../../pages/shipping'));
const PaymentPage = React.lazy(() => import('../../pages/payment'));
const SettingsPage = React.lazy(() => import('../../pages/accountSettings'));

const accountRoutes = [
  { path: '/account/information', exact: true, name: 'Account', element: AccountPage },
  { path: '/account/addresses', exact: true, name: 'Shipping', element: ShippingPage },
  { path: '/account/payment-method', exact: true, name: 'Payment', element: PaymentPage },
  { path: '/account/settings', exact: true, name: 'Billing', element: SettingsPage },
];

export default accountRoutes;
