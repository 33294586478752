export type Material = {
  id: number;
  code: string;
  name: string;
  materialType: string;
  subtitle?: string;
  define: string[];
  imagesSlide?: string[];
  contents?: { title: string; content: string[]; images?: string[] }[];
  characteristics?: {
    list?: string[];
    price: string;
    leadTime: number;
    wallThickness: string;
    tolerances: string;
    maxPartSize: string;
    layerHeight: string;
  };
  designSpecifications?: { title: string; content: string }[];
};

export const MaterialContent: Material[] = [
  {
    id: 1,
    code: 'PLA(FDM)',
    name: 'PLA (FDM)',
    materialType: 'Plastic',
    subtitle: '3D printing in PLA context',
    define: ['material PLA section 1', 'material PLA section 2'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-3.jpg',
    ],
    contents: [
      {
        title: 'advantages',
        content: [
          'material PLA section 3',
          'material PLA section 4',
          'material PLA section 5',
          'material PLA section 6',
        ],
      },
      {
        title: 'disadvantages',
        content: ['material PLA section 7', 'material PLA section 8', 'material PLA section 9'],
      },
    ],
    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 2,
      wallThickness: '0.8mm',
      tolerances: '± 0.5% with a lower limit of ± 0.5 mm (0.020″)',
      maxPartSize: '50 x 50 x 50 cm',
      layerHeight: '200 - 100',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '1 mm',
      },
      {
        title: 'minimum details',
        content: '0.8 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications PLA 1',
      },
      {
        title: 'maximum size',
        content: 'n/a',
      },
      {
        title: 'clearance',
        content: '0.5 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 2,
    code: 'ABS(FDM)',
    name: 'ABS (FDM)',
    materialType: 'Plastic',
    define: ['material ABS section 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-3.jpg',
    ],
    contents: [
      {
        title: 'advantages',
        content: [
          'material ABS title 4',
          'material ABS section 2',
          'material ABS title 5',
          'material ABS section 3',
          'material ABS title 6',
          'material ABS section 4',
          'material ABS title 7',
          'material ABS section 5',
          'material ABS title 8',
          'material ABS section 6',
          'material ABS title 9',
          'material ABS section 7',
          'material ABS title 10',
          'material ABS section 8',
          'material ABS title 11',
          'material ABS section 9',
        ],
      },
      {
        title: 'disadvantages',
        content: [
          'material ABS section 10',
          'material ABS section 11',
          'material ABS section 12',
          'material ABS section 13',
        ],
      },
    ],
    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 2,
      wallThickness: '0.8mm',
      tolerances: '± 0.25% with a lower limit of ± 0.5 mm (0.010″)',
      maxPartSize: '--',
      layerHeight: '200 - 100',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '1 mm',
      },
      {
        title: 'minimum details',
        content: '0.8 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications ABS 1',
      },
      {
        title: 'maximum size',
        content: 'n/a',
      },
      {
        title: 'clearance',
        content: 'n/a',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 3,
    code: 'Gray-Resin',
    name: 'Gray Resin',
    materialType: 'Plastic',

    define: ['material Resin section 1', 'material Resin section 2', 'material Resin section 3'],

    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Gray Resin content 1', 'material Gray Resin content 2'],
      },
      {
        title: 'typical use',
        content: ['material Gray Resin content 3'],
      },
    ],
    characteristics: {
      list: ['material Resin section 4', 'material Resin section 5', 'material Resin section 6'],
      price: '$ $ $ $ $',
      leadTime: 2,
      wallThickness: '0.8mm',
      tolerances: '± 0.5% with a lower limit of ± 0.15 mm (0.006″)',
      maxPartSize: '14.5 x 14.5 x 17.5 cm',
      layerHeight: '100 - 50',
    },
    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: 'specifications Gray Resin 1',
      },
      {
        title: 'minimum details',
        content: '0.5 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Gray Resin 2',
      },
      {
        title: 'maximum size',
        content: '250 x 250 x 235 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 4,
    code: 'High-detail-Resin',
    name: 'High-detail Resin',
    materialType: 'Plastic',
    define: ['material High-detail Resin content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material High-detail Resin content 2',
          'material High-detail Resin content 3',
          'material High-detail Resin content 4',
          'material High-detail Resin content 5',
          'material High-detail Resin content 6',
        ],
      },
      {
        title: 'typical use',
        content: ['material High-detail Resin content 7'],
      },
    ],
    characteristics: {
      list: ['material Resin section 4', 'material Resin section 5', 'material Resin section 6'],
      price: '$ $ $ $ $',
      leadTime: 2,
      wallThickness: '0.8mm',
      tolerances: '± 0.5% with a lower limit of ± 0.15 mm (0.006″)',
      maxPartSize: '14.5 x 14.5 x 17.5 cm',
      layerHeight: '100 - 50',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '1 mm',
      },
      {
        title: 'minimum details',
        content: '0.2 to 0.3 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications High-detail Resin 1',
      },
      {
        title: 'maximum size',
        content: '100 x 100 x 100 mm',
      },
      {
        title: 'clearance',
        content: '0.4 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'yes',
      },
    ],
  },
  // {
  //   id: 4,
  //   code: 'Nylon',
  //   name: 'Nylon',
  //   materialType: 'Plastic',

  //   define: ['material Nylon section 1'],

  //   characteristics: {
  //     list: [
  //       'material Nylon section 2',
  //       'material Nylon section 3',
  //       'material Nylon section 4',
  //       'material Nylon section 5',
  //     ],
  //     price: '$ $ $ $ $',
  //     leadTime: 5,
  //     wallThickness: '0.7mm',
  //     tolerances: '± 0.3% with a lower limit of ± 0.3 mm (0.012″)',
  //     maxPartSize: '42 x 50 x 42 cm',
  //     layerHeight: '100',
  //   },
  // },
  // {
  //   id: 5,
  //   code: 'PETG',
  //   name: 'PETG',
  //   materialType: 'Plastic',

  //   define: ['material PETG section 1'],

  //   characteristics: {
  //     list: [
  //       'material PETG section 2',
  //       'material PETG section 3',
  //       'material PETG section 4',
  //       'material PETG section 5',
  //       'material PETG section 6',
  //       'material PETG section 7',
  //       'material PETG section 8',
  //     ],
  //     price: '$ $ $ $ $',
  //     leadTime: 2,
  //     wallThickness: '0.8mm',
  //     tolerances: '± 0.5% with a lower limit of ± 0.5 mm (0.020″)',
  //     maxPartSize: '50 x 50 x 50 cm',
  //     layerHeight: '200 - 100',
  //   },
  // },
  // {
  //   id: 6,
  //   code: 'TPU',
  //   name: 'TPU',
  //   materialType: 'Plastic',

  //   define: ['material TPU section 1', 'material TPU section 2'],
  // imagesSlide: [
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/TomyTones-by-Tommy-Rombauts.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Sophie-(3D-scan).jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Secret-Message-Ring-by-Roberto-Trentin.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Aislin-by-Yarrid-Henrard.jpg',
  //   ],
  // contents: [
  //     {
  //       title: 'advantages',
  //       content: [
  //         'material TPU section 7',
  //         'material TPU section 8',
  //         'material TPU section 9',
  //         'material TPU section 10',
  //         'material TPU section 11',
  //         'material TPU section 12',
  //         'material TPU section 13',
  //       ],
  //     },
  //     {
  //       title: 'disadvantages',
  //       content: ['material TPU section 14', 'material TPU section 15', 'material TPU section 16'],
  //     },
  //   ],
  //   characteristics: {
  //     list: [
  //       'material TPU title 3',
  //       'material TPU section 2',
  //       'material TPU title 4',
  //       'material TPU section 3',
  //       'material TPU title 5',
  //       'material TPU section 4',
  //       'material TPU title 6',
  //       'material TPU section 5',
  //       'material TPU title 7',
  //       'material TPU section 6',
  //     ],
  //     price: '$ $ $ $ $',
  //     leadTime: 2,
  //     wallThickness: '0.8mm',
  //     tolerances: '± 0.5% with a lower limit of ± 0.5 mm (0.020″)',
  //     maxPartSize: '50 x 50 x 50 cm',
  //     layerHeight: '200 - 100',
  //   },
  // },
  // {
  //   id: 7,
  //   code: 'ASA',
  //   name: 'ASA',
  //   materialType: 'Plastic',

  //   define: ['material ASA section 1'],

  //   characteristics: {
  //     list: [
  //       'material ASA section 2',
  //       'material ASA section 3',
  //       'material ASA section 4',
  //       'material ASA section 5',
  //       'material ASA section 6',
  //       'material ASA section 7',
  //       'material ASA section 8',
  //       'material ASA section 9',
  //       'material ASA section 10',
  //     ],
  //     price: '$ $ $ $ $',
  //     leadTime: 2,
  //     wallThickness: '0.8mm',
  //     tolerances: '± 0.25% with a lower limit of ± 0.25 mm (0.010″)',
  //     maxPartSize: '--',
  //     layerHeight: '200 - 100',
  //   },
  // },
  // {
  //   id: 8,
  //   code: 'PEI',
  //   name: 'PEI',
  //   materialType: 'Plastic',

  //   define: ['material PEI section 1'],

  //   characteristics: {
  //     list: ['material PEI section 2', 'material PEI section 3'],
  //     price: '$ $ $ $ $',
  //     leadTime: 2,
  //     wallThickness: '0.8mm',
  //     tolerances: '± 0.5% with a lower limit of ± 0.5 mm (0.020″)',
  //     maxPartSize: '50 x 50 x 50 cm',
  //     layerHeight: '200 - 100',
  //   },
  // },
  // {
  //   id: 9,
  //   code: 'PC',
  //   name: 'PC',
  //   materialType: 'Plastic',
  //   subtitle: '3D printing in PC context',
  //   define: ['material PC section 1'],
  // imagesSlide: [
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/TomyTones-by-Tommy-Rombauts.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Sophie-(3D-scan).jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Secret-Message-Ring-by-Roberto-Trentin.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Aislin-by-Yarrid-Henrard.jpg',
  //   ],
  // contents: [
  //     {
  //       title: 'advantages',
  //       content: [
  //         'material PC section 2',
  //         'material PC section 3',
  //         'material PC section 4',
  //         'material PC section 5',
  //         'material PC section 6',
  //         'material PC section 7',
  //       ],
  //     },
  //     {
  //       title: 'disadvantages',
  //       content: [
  //         'material PC section 8',
  //         'material PC section 9',
  //         'material PC section 10',
  //         'material PC section 11',
  //         'material PC section 12',
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 10,
  //   code: 'Standard-Resin',
  //   name: 'Standard Resin',
  //   materialType: 'Plastic',
  //   define: [
  //     'Standard resin is a translucent material with a yellowish color, which is constructed from liquid resin hardened by a laser. It’s a low-budget resin that offers great surface quality and a high level of detail. Because of the structure required to support your model during printing, freedom of design is limited.',
  //   ],
  // imagesSlide: [
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/TomyTones-by-Tommy-Rombauts.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Sophie-(3D-scan).jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Secret-Message-Ring-by-Roberto-Trentin.jpg',
  //     'https://i.materialise.com/imghttps://v1hubstorage.blob.core.windows.net/materials-web/HighDetailStainlessSteel/SamplesGallery/FullSizeImages/Aislin-by-Yarrid-Henrard.jpg',
  //   ],
  // contents: [
  //     {
  //       title: 'typical use',
  //       content: [
  //         'Standard resin is suitable for visual models with limited functionality, but with a high level of detail. Applications range from visual models to model trains and figurines.',
  //       ],
  //     },
  //     {
  //       title: 'Technology',
  //       content: [
  //         'Stereolithography is used to build your design with this material. Starting from a 3D model, a model is built by cutting it into thin layers with specialized software. A support structure is created, where needed, in order to deal with overhangs and cavities.',
  //         'The process takes place in a large tank and begins when a layer of liquid polymer is spread over a platform. This machine then uses a computer-controlled laser to draw the first layer onto the surface of a liquid polymer, which hardens when struck by the laser. The model is then lowered and the next layer is then drawn directly on top of the previous one. This is repeated until the model is finished. In this way, layer by layer, an object is “drawn” in the liquid by the beam, with the layers being consolidated throughout the process.',
  //         'When the object is complete, it is raised out of the tank with the supporting platform – much like a submarine rising to the surface of the water – with the excess liquid flowing away. The supports are removed manually after the model is taken from the machine.',
  //       ],
  //     },
  //   ],

  // },
  {
    id: 11,
    code: 'Rubber-like(MJF)',
    name: 'Rubber-like (MJF)',
    materialType: 'Plastic',

    define: ['material Rubber-like content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Rubber-like content 2',
          'material Rubber-like content 3',
          'material Rubber-like content 4',
        ],
      },
      {
        title: 'typical use',
        content: ['material Rubber-like content 5'],
      },
    ],

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: 'specifications Rubber-like 1',
      },
      {
        title: 'minimum details',
        content: '0.25 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Rubber-like 2',
      },
      {
        title: 'maximum size',
        content: 'specifications Rubber-like 3',
      },
      {
        title: 'clearance',
        content: '0.5 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'yes',
      },
      {
        title: 'interlocking parts?',
        content: 'yes',
      },
    ],
  },
  {
    id: 12,
    code: 'Transparent-Resin',
    name: 'Transparent Resin',
    materialType: 'Plastic',

    define: ['material Transparent Resin content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-3.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-4.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-2.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Transparent Resin content 2',
          'material Transparent Resin content 3',
          'material Transparent Resin content 4',
          'material Transparent Resin content 5',
          'material Transparent Resin content 6',
        ],
      },
      {
        title: 'typical use',
        content: ['material Transparent Resin content 7'],
      },
    ],

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: 'specifications Transparent Resin 1',
      },
      {
        title: 'minimum details',
        content: '0.5 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Transparent Resin 2',
      },
      {
        title: 'maximum size',
        content: '2100 x 700 x 800 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 13,
    code: 'Multicolor+',
    name: 'Multicolor+',
    materialType: 'Full Color',

    define: ['material Multicolor content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Multicolor content 2',
          'material Multicolor content 3',
          'material Multicolor content 4',
          'material Multicolor content 5',
          'material Multicolor content 6',
        ],
      },
      {
        title: 'typical use',
        content: ['material Multicolor content 7'],
      },
    ],

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '1mm',
      },
      {
        title: 'minimum details',
        content: '0.25 mm',
      },
      {
        title: 'accuracy',
        content: 'n/a',
      },
      {
        title: 'maximum size',
        content: '490 x 390 x 200 mm',
      },
      {
        title: 'clearance',
        content: 'n/a',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'yes',
      },
    ],
  },
  {
    id: 14,
    code: 'Aluminum',
    name: 'Aluminum',
    materialType: 'Metal',

    define: ['material Aluminum content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Aluminum content 2',
          'material Aluminum content 3',
          'material Aluminum content 4',
          'material Aluminum content 5',
          'material Aluminum content 6',
        ],
      },
      {
        title: 'typical use',
        content: ['material Aluminum content 7'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 10,
      wallThickness: '1mm',
      tolerances: '±0.2% (with a lower limit of ± 200µ) ',
      maxPartSize: '440 x 220 x 320 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '1mm',
      },
      {
        title: 'minimum details',
        content: '0.25 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Aluminum 1',
      },
      {
        title: 'maximum size',
        content: '440 x 220 x 320 mm',
      },
      {
        title: 'clearance',
        content: 'n/a',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 15,
    code: 'High-Detail-Stainless-Steel',
    name: 'High-Detail Stainless Steel',
    materialType: 'Metal',

    define: ['material High-Detail Stainless Steel content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material High-Detail Stainless Steel content 2',
          'material High-Detail Stainless Steel content 3',
        ],
      },
      {
        title: 'typical use',
        content: ['material High-Detail Stainless Steel content 4'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 25,
      wallThickness: '0.3mm',
      tolerances: '--',
      maxPartSize: '45 x 45 x 45 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.3 mm',
      },
      {
        title: 'minimum details',
        content: '0.1 mm',
      },
      {
        title: 'accuracy',
        content: 'n/a',
      },
      {
        title: 'maximum size',
        content: '45 x 45 x 45 mm',
      },
      {
        title: 'clearance',
        content: 'n/a',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 16,
    code: 'Steel',
    name: 'Steel',
    materialType: 'Metal',

    define: ['material Steel content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-16.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-12.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-5.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Steel content 2',
          'material Steel content 3',
          'material Steel content 4',
          'material Steel content 5',
        ],
      },
      {
        title: 'typical use',
        content: ['material Steel content 6'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 12,
      wallThickness: '1 to 3 mm (depending on dimensions)',
      tolerances: '± 1% (after 2-3% shrinkage compensation)',
      maxPartSize:
        '200 x 350 x 200 mm (unpolished, unpolished black), 177 x 177 x 177 mm (unpolished gold-plated), 152 x 152 x 152 mm (polished, polished gold-plated, polished black, polished brown)',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: 'specifications Steel 1',
      },
      {
        title: 'minimum details',
        content: 'specifications Steel 2',
      },
      {
        title: 'accuracy',
        content: 'specifications Steel 3',
      },
      {
        title: 'maximum size',
        content: `specifications Steel 4`,
      },
      {
        title: 'clearance',
        content: '0.8 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 17,
    code: 'Titanium',
    name: 'Titanium',
    materialType: 'Metal',

    define: ['material Titanium content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-6.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: [
          'material Titanium content 2',
          'material Titanium content 3',
          'material Titanium content 4',
          'material Titanium content 5',
          'material Titanium content 6',
          'material Titanium content 7',
        ],
      },
      {
        title: 'typical use',
        content: ['material Titanium content 8'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 11,
      wallThickness: '0.5 to 1 mm (depending on dimensions)',
      tolerances: '±0.2% (with a lower limit of ±200µ)',
      maxPartSize: '220 x 220 x 250 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: 'specifications Titanium 1',
      },
      {
        title: 'minimum details',
        content: '0.25 mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Titanium 2',
      },
      {
        title: 'maximum size',
        content: '220 x 220 x 250 mm',
      },
      {
        title: 'clearance',
        content: 'n/a',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 18,
    code: 'Brass',
    name: 'Brass',
    materialType: 'Precious Metal',

    define: ['material Brass content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Brass content 2', 'material Brass content 3'],
      },
      {
        title: 'typical use',
        content: ['material Brass content 4'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 15,
      wallThickness: '0.6mm',
      tolerances: '±5% (with a lower limit of ±0.35 mm)',
      maxPartSize: '88 x 88 x 125 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.6mm',
      },
      {
        title: 'minimum details',
        content: '0.35mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Brass 1',
      },
      {
        title: 'maximum size',
        content: '88 x 88 x 125 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 19,
    code: 'Bronze',
    name: 'Bronze',
    materialType: 'Precious Metal',

    define: ['material Bronze content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-4.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Bronze content 2', 'material Bronze content 3'],
      },
      {
        title: 'typical use',
        content: ['material Bronze content 4'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 15,
      wallThickness: '0.6mm',
      tolerances: '±5% (with a lower limit of ±0.15 mm)',
      maxPartSize: '88 x 88 x 125 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.6mm',
      },
      {
        title: 'minimum details',
        content: '0.35mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Bronze 1',
      },
      {
        title: 'maximum size',
        content: '88 x 88 x 125 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 20,
    code: 'Copper',
    name: 'Copper',
    materialType: 'Precious Metal',
    define: ['material Copper content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/copper/copper-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/copper/copper-3.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/copper/copper-1.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Copper content 2', 'material Copper content 3'],
      },
      {
        title: 'typical use',
        content: ['material Copper content 4'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 15,
      wallThickness: '0.8mm',
      tolerances: '±5% (with a lower limit of ±0.15 mm)',
      maxPartSize: '88 x 88 x 125 mm',
      layerHeight: '--',
    },

    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.8mm',
      },
      {
        title: 'minimum details',
        content: '0.35mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Copper 1',
      },
      {
        title: 'maximum size',
        content: '88 x 88 x 125 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 21,
    code: 'Gold',
    name: 'Gold',
    materialType: 'Precious Metal',

    define: ['material Gold content 1'],
    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-1.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-2.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-3.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Gold content 2', 'material Gold content 3'],
      },
      {
        title: 'hallmarking',
        content: [
          'material Gold content 4',
          'material Gold content 5',
          'material Gold content 6',
          'material Gold content 7',
          'material Gold content 8',
        ],
      },
      {
        title: 'typical use',
        content: ['material Gold content 9'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 15,
      wallThickness: '0.8mm',
      tolerances: '±5% (with a lower limit of ±0.35 mm)',
      maxPartSize: '88 x 88 x 125 mm',
      layerHeight: '--',
    },
    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.8mm',
      },
      {
        title: 'minimum details',
        content: '0.35mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Gold 1',
      },
      {
        title: 'maximum size',
        content: '88 x 88 x 125 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
  {
    id: 22,
    code: 'Silver',
    name: 'Silver',
    materialType: 'Precious Metal',
    define: ['material Silver content 1'],

    imagesSlide: [
      'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-9.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-5.jpg',
      'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-10.jpg',
    ],
    contents: [
      {
        title: 'pricing info',
        content: ['material Silver content 2', 'material Silver content 3'],
      },
      {
        title: 'hallmarking',
        content: [
          'material Silver content 4',
          'material Silver content 5',
          'material Silver content 6',
          'material Silver content 7',
          'material Silver content 8',
        ],
      },
      {
        title: 'typical use',
        content: ['material Silver content 9'],
        images: ['https://live.staticflickr.com/496/19555956220_df3fced63a_c.jpg'],
      },
    ],

    characteristics: {
      price: '$ $ $ $ $',
      leadTime: 15,
      wallThickness: '0.6mm',
      tolerances: '±5% (with a lower limit of ±0.35 mm)',
      maxPartSize: '88 x 88 x 125 mm',
      layerHeight: '--',
    },
    designSpecifications: [
      {
        title: 'minimum wall thickness',
        content: '0.6mm',
      },
      {
        title: 'minimum details',
        content: '0.35mm',
      },
      {
        title: 'accuracy',
        content: 'specifications Silver 1',
      },
      {
        title: 'maximum size',
        content: '88 x 88 x 125 mm',
      },
      {
        title: 'clearance',
        content: '0.3 mm',
      },
      {
        title: 'enclosed parts?',
        content: 'no',
      },
      {
        title: 'interlocking parts?',
        content: 'no',
      },
    ],
  },
];
