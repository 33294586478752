import React from 'react';
import B2CBanner from '../components/printingservice/B2CBanner';
import B2CServices from '../components/printingservice/B2CNewServices';
import B2CSourcing from '../components/printingservice/B2CSourcing';
import B2CCustom3DProject from '../components/printingservice/B2CCustom3DProject';
import B2CCompare from '../components/printingservice/B2CCompare';
import B2CChooseProduct from '../components/printingservice/B2CChooseProduct';
import B2CNetworkManufactured from '../components/printingservice/B2CNetworkManufactured';
import B2CPrintingResource from '../components/printingservice/B2CPrintingResource';
import B2CExperience from '../components/homepage/B2CExperience';

const PrintingService = () => {
  return (
    <>
      <B2CBanner />

      <B2CServices />

      <B2CCustom3DProject />

      <B2CExperience />

      <B2CSourcing />

      <B2CCompare />

      <B2CChooseProduct />

      <B2CNetworkManufactured />

      <B2CPrintingResource />
    </>
  );
};

export default React.memo(PrintingService);
