import React, { ReactNode } from 'react';
import img1 from 'src/assets/images/fdmPrinting/img1.png';
import img2 from 'src/assets/images/fdmPrinting/img2.png';
import img3 from 'src/assets/images/fdmPrinting/img3.png';
import img4 from 'src/assets/images/fdmPrinting/img4.png';
import img5 from 'src/assets/images/fdmPrinting/img5.png';
import IntroduceService from '../IntroduceService';
import { useTranslation } from 'react-i18next';
import { Container, useMediaQuery } from '@mui/material';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  extra?: string | ReactNode;
  table?: ReactNode;
  tableExtra?: string | ReactNode;
  rtl?: boolean;
};
const ProductDescription: React.FC<ProductDescriptionProps> = (props) => {
  const { image, title, extra, table, tableExtra, rtl } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Container maxWidth="xl" sx={{ margin: '132px auto' }}>
      <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
        <div className="col-md-6 col-lg-6">
          <div className={`${isMobile ? 'm-auto' : rtl ? 'ml-[15%]' : 'mr-[15%]'}`}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 m-auto">
          <section className="section ">
            <h1 className="text-left">{title}</h1>
            <div
              className="extra mb-5"
              style={{ width: '100%', textAlign: 'left', margin: 'auto' }}
            >
              {extra}
            </div>
            <div className="section-content p-0">
              {table}
              <p className="table-extra">{tableExtra}</p>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
};

const DescriptionList = () => {
  const { t } = useTranslation();
  return (
    <>
      <ProductDescription
        image={img1}
        title={t('hubs manufacturing standards')}
        extra={t('fdmprintingservice hubs manufacturing standards context')}
        table={
          <table className="table table-xl table-bordered">
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('requirement')}</th>
                <th>{t('specification')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('maximum build size')}</td>
                <td>
                  500 x 500 x 500 mm (19.68&apos;&apos; x 19.68&apos;&apos; x 19.68&apos;&apos;)*
                </td>
              </tr>
              <tr>
                <td>{t('standard lead time')}</td>
                <td>2 {t('business days')}</td>
              </tr>
              <tr>
                <td>{t('dimensional accuracy')}</td>
                <td>
                  {t('lower limit on', {
                    percent: '0.5%',
                    minimet: '0.5',
                    degree: '0.0196"',
                  })}
                </td>
              </tr>
              <tr>
                <td>{t('layer height')}</td>
                <td>100-300 μm</td>
              </tr>
              <tr>
                <td>{t('infill')}</td>
                <td>20-100%</td>
              </tr>
            </tbody>
          </table>
        }
        tableExtra={t('fdmprintingservice hubs manufacturing standards sub context')}
      />
      <ProductDescription
        rtl
        image={img2}
        title={t('available materials for fdm')}
        extra={t('fdmprintingservice available materials for fdm context')}
        table={
          <table
            className="table table-xl table-bordered"
            style={{
              borderCollapse: 'collapse',
              borderRadius: '8px',
              overflow: 'hidden',
              fontFamily: 'Barlow',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '140%',
            }}
          >
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('group')}</th>
                <th>{t('type')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('prototyping', { value: 'PLA' })}</td>
                <td>{t('standard')}</td>
              </tr>
              <tr>
                <td>Nylon</td>
                <td>Nylon 11, Nylon 12, Markforged Onyx, PA 6 Stratasys</td>
              </tr>
              <tr>
                <td>PETG</td>
                <td>{t('standard petg')}</td>
              </tr>
              <tr>
                <td>PEI</td>
                <td>ULTEM 9085, ULTEM 1010</td>
              </tr>
              <tr>
                <td>ASA</td>
                <td>{t('prototyping', { value: 'ASA' })}</td>
              </tr>

              <tr>
                <td>ABS</td>
                <td>{t('prototyping', { value: 'ABS, ABS M30, ABSplus' })}</td>
              </tr>
              <tr>
                <td>TPU</td>
                <td>{t('prototyping TPU')}</td>
              </tr>
              <tr>
                <td>PC</td>
                <td>{t('pc stratasys')}</td>
              </tr>
            </tbody>
          </table>
        }
      />

      <IntroduceService
        image={img5}
        title={t('laserprintingservice upload parts')}
        buttonText={t('get an instant quote') as string}
      />

      <ProductDescription
        rtl
        image={img3}
        title={t('our fdm manufacturing standards')}
        extra={
          <>
            {t('slaprintingservice our sla manufacturing standard context')}
            <br />
            <ul className="mt-4">
              <li>{t('fdmprintingservice standards 1')}</li>
              <li>{t('fdmprintingservice standards 2')}</li>
              <li>{t('fdmprintingservice standards 3')}</li>
              <li>{t('fdmprintingservice standards 4')}</li>
            </ul>
          </>
        }
      />
      <ProductDescription
        image={img4}
        title={t('design guidelines for fdm')}
        extra={t('fdmprintingservice design guidelines for fdm context')}
        table={
          <table
            className="table table-xl table-bordered"
            style={{
              borderCollapse: 'collapse',
              borderRadius: '8px',
              overflow: 'hidden',
              fontFamily: 'Barlow',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '140%',
            }}
          >
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('feature')}</th>
                <th>{t('recommended size')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('unsupported walls')}</td>
                <td>0.8 mm (0.0315&apos;&apos;) </td>
              </tr>
              <tr>
                <td>{t('supported walls')}</td>
                <td>0.8 mm (0.0315&apos;&apos;) </td>
              </tr>
              <tr>
                <td>{t('minimum feature size')}</td>
                <td>2.0 mm (0.00787&apos;&apos;) </td>
              </tr>
              <tr>
                <td>{t('minimum hole diameter')}</td>
                <td>2.0 mm (0.00787&apos;&apos;) </td>
              </tr>
            </tbody>
          </table>
        }
        tableExtra={
          <>
            {t('learn more about the process in our')}{' '}
            <a className="text-decoration-none" href="/">
              {t('knowledge base article')}
            </a>
          </>
        }
      />
    </>
  );
};

export default React.memo(DescriptionList);
