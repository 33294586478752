import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface QueryParamsProviderProps {
  children: ReactNode;
}

interface QueryParams {
  [key: string]: string;
}

const QueryParamsContext = createContext<QueryParams | undefined>(undefined);

export const QueryParamsProvider: React.FC<QueryParamsProviderProps> = ({ children }) => {
  const location = useLocation();
  const [initialQueryParams, setInitialQueryParams] = useState<QueryParams>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = Object.fromEntries(searchParams.entries());
    setInitialQueryParams(params);
  }, [location.search]);

  return (
    <QueryParamsContext.Provider value={initialQueryParams}>{children}</QueryParamsContext.Provider>
  );
};

export const useQueryParams = (): QueryParams | undefined => {
  return useContext(QueryParamsContext);
};
