import React from 'react';
import List from 'src/components/list/List';
import Section from 'src/components/section/Section';
import video from 'src/assets/images/home/video.png';
import { useTranslation } from 'react-i18next';

const B2CSourcing = () => {
  const { t } = useTranslation();
  return (
    <Section
      title={t('homepage powerful automation') as string}
      style={{ background: '#222222', color: '#fff' }}
    >
      <div className="b2c-container">
        <div className="row row-gap-2">
          <div className="col-md-12 col-lg-7">
            <img alt="img" src={video} />
          </div>
          <div className="col-md-12 col-lg-5">
            <List>
              <List.ListDescription
                title={t('instant quoting') as string}
                information={t('homepage instant quoting context') as string}
              />
              <List.ListDescription
                title={t('automated dfm feedback') as string}
                information={t('homepage automated dfm feedback context') as string}
              />
              <List.ListDescription
                title={t('homepage online order') as string}
                information={t('homepage online order context') as string}
              />
            </List>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(B2CSourcing);
