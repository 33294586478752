import { createAction } from '@reduxjs/toolkit';
import { CountByMember, GetListOrderParams, MyOrderDetail, OrderUI } from './types';
import { AppUI } from 'src/types/common.types';

export const getOrderByMember = createAction<any>('order/getOrderByMember');
export const getOrderByMemberSuccess = createAction<MyOrderDetail[]>(
  'order/getOrderByMember/success'
);

export const getOrderList = createAction<GetListOrderParams>('order/getOrderList');
export const getOrderListSuccess = createAction('order/getOrderList/success');

export const getOrderListMore = createAction<GetListOrderParams>('order/getOrderListMore');
export const getOrderListMoreSuccess = createAction<MyOrderDetail[]>(
  'order/getOrderListMore/success'
);

export const getCountByMember = createAction('order/getCountByMember');
export const getCountByMemberSuccess = createAction<CountByMember>(
  'order/getCountByMember/success'
);

export const setCanLoadMore = createAction<boolean>('order/setCanLoadMore');

export const orderError = createAction('order/orderError');
