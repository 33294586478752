import { takeLatest } from 'redux-saga/effects';
import {
  changeQuantityWORKER,
  createFromOrderWORKER,
  createOrUpdateWORKER,
  deleteCartWORKER,
  emptyCartWORKER,
  getUseCartWORKER,
  getViewCartWORKER,
  createPaymentWORKER,
} from './workers';
import {
  changeQuantityCart,
  createFromOrder,
  createOrUpdateCart,
  deleteCart,
  emptyCart,
  getUseCart,
  getViewCart,
  createPayment,
} from './actions';

export function* cartSaga() {
  yield takeLatest(createOrUpdateCart, createOrUpdateWORKER);
  yield takeLatest(changeQuantityCart, changeQuantityWORKER);
  yield takeLatest(deleteCart, deleteCartWORKER);
  yield takeLatest(emptyCart, emptyCartWORKER);
  yield takeLatest(getUseCart, getUseCartWORKER);
  yield takeLatest(getViewCart, getViewCartWORKER);
  yield takeLatest(createFromOrder, createFromOrderWORKER);
  yield takeLatest(createPayment, createPaymentWORKER);
}
