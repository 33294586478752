import { SagaReturnType, call, put } from 'redux-saga/effects';
import { getShippingAddressByMemberAPI } from '../shippingAddress/apis';
import {
  createOrderError,
  createOrderSuccess,
  getBillingAddressByMemberInsideCreateOrderSuccess,
  getCartInsideCreateOrderSuccess,
  getListLeadTimeSuccess,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getShippingAddressByMemberInsideCreateOrderSuccess,
  getShippingOptionCreateOrderSuccess,
} from './actions';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helper';
import { getBillingAddressByMemberAPI } from '../billingAddress/apis';
import { getViewCartAPI } from '../cart/apis';
import { createOrderAPI, getListLeadTimeAPI, getPaymentMethodByMemberAPI } from './apis';
import { ActionSaga } from 'src/types/saga.types';
import { CreateOrderBody } from './types';
import { CartBody } from '../cart/types';
import { getShippingOptionAPI } from '../shapeway/apis';
import { ShippingOptionBody } from '../shapeway/types';

export function* getShippingAddressByMemberInsideCreateOrderWORKER() {
  try {
    const { data }: SagaReturnType<typeof getShippingAddressByMemberAPI> = yield call(
      getShippingAddressByMemberAPI
    );
    yield put(getShippingAddressByMemberInsideCreateOrderSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Get By member shipping address failed');
    console.error(err);
  }
}

export function* getBillingAddressByMemberInsideCreateOrderWORKER() {
  try {
    const { data }: SagaReturnType<typeof getBillingAddressByMemberAPI> = yield call(
      getBillingAddressByMemberAPI
    );
    yield put(getBillingAddressByMemberInsideCreateOrderSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Get By member billing address failed');
    console.error(err);
  }
}
export function* getPaymentMethodsWORKER() {
  try {
    const { data }: SagaReturnType<typeof getPaymentMethodByMemberAPI> = yield call(
      getPaymentMethodByMemberAPI
    );
    yield put(getPaymentMethodsSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Get Payment method failed');
    console.error(err);
  }
}

export function* getCartInsideCreateOrderWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is required');
    }
    const { data }: SagaReturnType<typeof getViewCartAPI> = yield call(getViewCartAPI, payload);
    yield put(getCartInsideCreateOrderSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Get Cart failed');
    console.error(err);
  }
}

export function* createOrderWORKER({ payload }: ActionSaga<CreateOrderBody>) {
  try {
    if (!payload) {
      throw new Error('Payload is required');
    }
    const { data }: SagaReturnType<typeof createOrderAPI> = yield call(createOrderAPI, payload);
    if (!data.isSuccess) {
      throw new Error('Error');
    }

    yield put(createOrderSuccess());

    if (payload.callback) {
      payload.callback();
    }
    showSuccessToast('Create order successful');
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Create order failed');
    console.error(err);
  }
}

export function* getListLeadTimeWORKER({
  payload,
}: {
  payload: {
    technologyMaterialIds: Array<string>;
    vendorId: number | string;
  };
}) {
  try {
    if (!payload) {
      throw new Error('Invalid payload');
    }
    const { data }: SagaReturnType<typeof getListLeadTimeAPI> = yield call(
      getListLeadTimeAPI,
      payload
    );
    if (!data.isSuccess) {
      throw new Error('Err');
    }
    yield put(getListLeadTimeSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    showErrorToast('Error get lead time');
    console.error(err);
  }
}

export function* getShippingOptionCreateOrderWORKER({ payload }: ActionSaga<ShippingOptionBody>) {
  try {
    if (!payload) {
      throw new Error('Payload is required');
    }
    const { data }: SagaReturnType<typeof getShippingOptionAPI> = yield call(
      getShippingOptionAPI,
      payload
    );
    if (!data.isSuccess) {
      throw new Error('Err');
    }
    yield put(getShippingOptionCreateOrderSuccess(data.data));
  } catch (err) {
    yield put(createOrderError());
    console.error(err);
  }
}
