import React from 'react';
import Banner from 'src/components/banner/Banner';
import banner3d from 'src/assets/images/resources/banner3d.png';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

const B2CBanner = () => {
  const { t } = useTranslation();
  return (
    <Banner
      className="page-banner"
      style={{
        background: `url(${banner3d})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="xl">
        <Banner.Text titleDark={t('knowledge base')} descriptionSmall={t('resources slogan')} />
      </Container>
    </Banner>
  );
};

export default React.memo(B2CBanner);
