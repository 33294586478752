import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  createPayPal,
  createPayPalSuccess,
  executePayPal,
  executePayPalSuccess,
  paypalError,
} from './actions';
import { PayPalReducer } from './types';

const initialState = {};

const paypalReducer = createReducer<PayPalReducer>(initialState, (builder) => {
  builder.addCase(paypalError, (state) => {
    state.ui = {};
  });
  builder.addCase(createPayPalSuccess, (state, { payload }) => {
    state.paypalLink = payload;
  });
  builder.addMatcher(isAnyOf(createPayPal, executePayPal), (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addMatcher(isAnyOf(createPayPalSuccess, executePayPalSuccess), (state) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
});

export default paypalReducer;
