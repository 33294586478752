import { createReducer } from '@reduxjs/toolkit';
import {
  authenticationError,
  setLanguage,
  getMemberProfile,
  getMemberProfileSuccess,
  getManufacturerProfile,
  getManufacturerProfileSuccess,
  jwtInfoSuccess,
  login,
  loginError,
  loginSuccess,
  refreshToken,
  refreshTokenSuccess,
  setUI,
  setCountryGlobal,
  manufacturerLogin,
  manufacturerLoginSuccess,
  manufacturerLoginError,
} from './actions';
import { AuthenticationReducer } from './types';
import { defaultCountry, defaultLanguage } from 'src/locales/i18n';

const initialState = {
  lang: defaultLanguage,
  country: defaultCountry,
};

const authenticationReducer = createReducer<AuthenticationReducer>(initialState, (builder) => {
  builder.addCase(jwtInfoSuccess, (state, action) => {
    state.authentication = action.payload;
  });
  builder.addCase(getMemberProfile, (state, action) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(getMemberProfileSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.member = action.payload;
  });
  builder.addCase(getManufacturerProfile, (state, action) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(getManufacturerProfileSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.manufacturer = action.payload;
  });
  builder.addCase(setLanguage, (state, action) => {
    state.lang = action.payload;
  });
  builder.addCase(setCountryGlobal, (state, action) => {
    state.country = action.payload;
  });
  builder.addCase(login, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(loginSuccess, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: false, triggerLogin: false };
    state.newAuth = action.payload;
  });
  builder.addCase(loginError, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  // tuanhh
  builder.addCase(manufacturerLogin, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(manufacturerLoginSuccess, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: false, triggerLogin: false };
    state.newAuth = action.payload;
  });
  builder.addCase(manufacturerLoginError, (state, action) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
  builder.addCase(refreshToken, (state, action) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(refreshTokenSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.newAuth = action.payload;
  });
  builder.addCase(authenticationError, (state) => {
    state.ui = {};
  });
  builder.addCase(setUI, (state, action) => {
    state.ui = { ...state.ui, ...action.payload };
  });
  builder.addDefaultCase((state, action) => {});
});

export default authenticationReducer;
