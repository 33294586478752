import dayjs from 'dayjs';
import { AppJWT } from 'src/features/authentication/types';
import { AutocompleteOption } from 'src/types/common.types';
// require('dayjs/locale/vn');
// require('dayjs/locale/en');
// require('dayjs/locale/kr');

export const filterByField = <D extends any[]>(data: D, field: string, value?: string) => {
  if (value) {
    return data.filter((e) =>
      e?.[field] && e?.[field]?.toLowerCase()?.indexOf(value.toLowerCase()) >= 0 ? true : false
    );
  }

  return data ? [...data] : [];
};

export const convertToAutoCompleteOptions = <T = Object>(
  array: T[],
  fieldLabel: string
): AutocompleteOption[] => {
  const options: AutocompleteOption[] = [];
  if (array && array.length > 0) {
    for (let i in array) {
      const item: any = array[i];
      const label: string = item?.[fieldLabel] ? item[fieldLabel] : '';
      options.push({ ...item, label });
    }
  }
  return [...options];
};

export const convertToCurrency = (value?: number | null, type: string = 'USD') => {
  return value && +value
    ? (+value).toLocaleString('en-US', {
        style: 'currency',
        currency: type,
      })
    : '$0';
};

export const parseJwt = (token: string): AppJWT => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const groupBy = <T extends Record<K, string>, K extends keyof T>(array: T[], key: K) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {} as Record<string, T[]>);

export const showDateTime = (
  str?: string | null,
  format: string = 'MM/DD/YYYY',
  locale: string = 'en'
) => {
  return str ? dayjs(str).locale(locale).format(format) : '';
};

export const numberToRound = (num?: number, roundTo: number = 2) => {
  return num && num !== undefined ? +num.toFixed(roundTo) : 0;
};

export const base64toBlob = async (base64Data: string, fileName: string) => {
  const res = await fetch(base64Data);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};
