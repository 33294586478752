import { createReducer } from '@reduxjs/toolkit';
import { SystemReducer } from './types';
import { getEnumSystem, getEnumSystemSuccess, systemError } from './actions';

const initialState = {};

const systemReducer = createReducer<SystemReducer>(initialState, (builder) => {
  builder.addCase(getEnumSystem, (state) => {
    state.ui = { loading: true };
  });
  builder.addCase(getEnumSystemSuccess, (state, { payload }) => {
    state.ui = { loading: false };
    state.systemEnum = payload;
  });
  builder.addCase(systemError, (state) => {
    state.ui = {};
  });
});

export default systemReducer;
