import axios, { AxiosRequestConfig } from 'axios';
import { refreshTokenAPI } from 'src/features/authentication/apis';
import { NewAuthentication, RefreshTokenBody } from 'src/features/authentication/types';
import { Member } from 'src/features/member/types';
import { saveAppLocalStorage } from 'src/helpers/localStorage.helpers';
import { v1hubCustomerToken } from 'src/hooks/useAuth';

const { REACT_APP_WEB_APP } = process.env;
const v1hub = localStorage.getItem(v1hubCustomerToken);
const v1hubParse: NewAuthentication =
  v1hub && v1hub !== 'undefined' ? JSON.parse(v1hub) : undefined;
const tokenString = v1hubParse ? v1hubParse?.accessToken : undefined;

const config: AxiosRequestConfig = {
  baseURL: REACT_APP_WEB_APP,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: tokenString ? `Bearer ${tokenString}` : undefined,
  },
};

export const axiosInceptors = axios.create(config);

axiosInceptors.interceptors.response.use(
  (response) => response,
  (error) => callbackOnError(error)
);

export const callbackOnError = (error: any) => {
  if (error.response.data?.detail === 'token is expired') {
    const localStorageItem = localStorage.getItem(v1hubCustomerToken);
    const parse = localStorageItem ? JSON.parse(localStorageItem) : '';
    if (parse) {
      const { member, refreshToken }: { member?: Member; refreshToken?: string } = parse;
      if (member?.userId && refreshToken) {
        refreshTokenAPI({ useId: member.userId, value: refreshToken } as RefreshTokenBody)
          .then(({ data }) => {
            saveAppLocalStorage(data.data);
            window.location.reload();
          })
          .catch((error) => {
            localStorage.removeItem(v1hubCustomerToken);
            window.location.reload();
            console.error('Refresh token failed: ', error);
          });
      } else {
        localStorage.removeItem(v1hubCustomerToken);
        window.location.reload();
      }
    }
  }
  return error;
};
