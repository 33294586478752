import React from 'react';
import Section from 'src/components/section/Section';
import img4 from 'src/assets/images/company/img4.png';
import { useTranslation } from 'react-i18next';

const BaseIntroduce = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section className="BaseIntroduce">
        <div className="b2c-container">
          <div className="introduce container">
            {/* <p className="font-medium">{t('aboutus section 1')}</p> */}
            <div className="section">
              <h3>{t('a broad range of capabilities')}</h3>
              <p>{t('aboutus section 2')}</p>
              <p className="mb-0">{t('aboutus section 3')}</p>
            </div>
            <div className="section">
              <h3>{t('fast & affordable')}</h3>
              <p>{t('aboutus section 4')}</p>
              <p>{t('aboutus section 5')}</p>
              <p className="mb-0">{t('aboutus section 6')}</p>
            </div>
          </div>
        </div>
      </Section>
      <div className="bg-[#222222] py-5 ">
        <Section>
          <div className="introduce container">
            <div className="section">
              <h1 className=" text-white">{t('our mission')}</h1>
              <p className="text-white mt-3">{t('aboutus section 7')}</p>
            </div>
          </div>
        </Section>
      </div>
      <Section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <h1 className="text-left m-auto max-md:mb-3">{t('Contact us')}</h1>
              <p className="text-title__normal">
                {t('v1hub is one of products from 3ds cloud company')}
              </p>
              <img src={img4} alt="" className="my-4" />
              <p className="text-para__normal__grey m-0">{t('aboutus section 8')}</p>
            </div>
          </div>
        </div>
      </Section>
      <Section
        className="b2c-container"
        extra=""
        styleContent={{
          textAlign: 'left',
          margin: 'auto',
          paddingTop: '0.5rem',
        }}
        spaceTop
      >
        <div className="introduce container">
          <div className="section">
            <h1 className="mb-3">{t('our office')}</h1>
            <p style={{ color: '#6B6B6B' }}>{t('aboutus addressLLC')}</p>
            {/* <p style={{ color: '#6B6B6B' }}>{t('aboutus address')}</p> */}
            <p style={{ color: '#6B6B6B' }}>
              <a href="mailto:support@v1hub.com">support@v1hub.com</a>
            </p>
          </div>
        </div>
      </Section>
      {/* <Section
        title="Powerful automation that makes sourcing easy"
        style={{ background: '#222222', color: '#fff' }}
      >
        <div className="b2c-container">
          <div className="row row-gap-2">
            <div className="col-md-12 col-lg-7">
              <img alt="img" src={video} />
            </div>
            <div className="col-md-12 col-lg-5"></div>
          </div>
        </div>
      </Section> */}
      {/* <Section>
        <div className="col-md-12 col-lg-12">
          <img
            alt="img"
            src={video}
            onClick={() => {
              navigate('/ourplatform');
            }}
          />
        </div>
        <div className="col-md-12 col-lg-12" style={{ padding: '140px' }}>
          <div className="row">
            <div className="col-md-5 col-lg-5 p-0">
              <img alt="img" src={bussinessMan} />
            </div>
            <div className="col-md-7 col-lg-7 p-0">
              <div
                style={{
                  background: '#EBEBEB',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '5rem',
                }}
              >
                <p>
                  “V1 would have been an essential resource when we were building the iPod, the
                  iPhone, and Nest. Instead of waiting weeks to get back models & prototypes, now
                  with Hubs&apos; crucial online prototyping & manufacturing platform, engineers and
                  designers can turn ideas into objects in only a few days, with precision 3D
                  printed, CNC&apos;d, or injection molded parts, and even move straight into volume
                  manufacturing.”
                </p>
                <div style={{ textAlign: 'right' }}>
                  <p style={{ margin: '0', fontWeight: '700', color: '#312F30' }}>Employee Name</p>
                  <p
                    style={{
                      margin: '0',
                      fontSize: '16px',
                      lineHeight: '19px',
                      color: '#939393',
                    }}
                  >
                    Position
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </>
  );
};

export default React.memo(BaseIntroduce);
