import { Container } from '@mui/material';
import React from 'react';
import Banner from 'src/components/banner/Banner';

interface IB2CBanner {
  background: string;
  titleDark: string;
  descriptionSmall: string;
}
const B2CBanner = ({ background, titleDark, descriptionSmall }: IB2CBanner) => {
  return (
    <Banner
      style={{
        height: '466px',
        background: `url(${background})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="xl">
        <Banner.Text titleDark={titleDark} descriptionSmall={descriptionSmall} />
      </Container>
    </Banner>
  );
};

export default React.memo(B2CBanner);
