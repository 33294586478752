import { takeLatest } from 'redux-saga/effects';
import { getCountByMember, getOrderByMember, getOrderList, getOrderListMore } from './actions';
import {
  getCountByMemberWORKER,
  getOrderByMemberWORKER,
  getOrderListMoreWORKER,
  getOrderListWORKER,
} from './workers';

export function* orderSaga() {
  yield takeLatest(getOrderByMember, getOrderByMemberWORKER);
  yield takeLatest(getOrderList, getOrderListWORKER);
  yield takeLatest(getOrderListMore, getOrderListMoreWORKER);
  yield takeLatest(getCountByMember, getCountByMemberWORKER);
}
