import { ActionSaga } from 'src/types/saga.types';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helper';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import { ShippingAddressBody } from './types';
import {
  createShippingAddressAPI,
  getShippingAddressByMemberAPI,
  updateShippingAddressAPI,
} from './apis';
import {
  createShippingAddressSuccess,
  getByMemberShippingAddressSuccess,
  shippingAddressError,
  updateShippingAddressSuccess,
} from './actions';

export function* createShippingAddressWORKER({ payload }: ActionSaga<ShippingAddressBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof createShippingAddressAPI> = yield call(
      createShippingAddressAPI,
      payload
    );
    yield put(createShippingAddressSuccess(data.data));

    if (payload?.callback) {
      payload.callback<typeof data.data>(data.data);
    }

    showSuccessToast('Create shipping address successfully');
  } catch (err) {
    yield put(shippingAddressError());
    showErrorToast('Create shipping address failed');
    console.error(err);
  }
}

export function* updateShippingAddressWORKER({ payload }: ActionSaga<ShippingAddressBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof updateShippingAddressAPI> = yield call(
      updateShippingAddressAPI,
      payload
    );
    yield put(updateShippingAddressSuccess(data.data));

    if (payload?.callback) {
      payload.callback<typeof data.data>(data.data);
    }

    showSuccessToast('Update shipping address successfully');
  } catch (err) {
    yield put(shippingAddressError());
    showErrorToast('Update shipping address failed');
    console.error(err);
  }
}

export function* getByMemberShippingAddressWORKER() {
  try {
    const { data }: SagaReturnType<typeof getShippingAddressByMemberAPI> = yield call(
      getShippingAddressByMemberAPI
    );
    yield put(getByMemberShippingAddressSuccess(data.data));
  } catch (err) {
    yield put(shippingAddressError());
    showErrorToast('Get By member shipping address failed');
    console.error(err);
  }
}
