import { createAction } from '@reduxjs/toolkit';
import { BillingAddress, BillingAddressBody } from './types';

export const createBillingAddress = createAction<BillingAddressBody>(
  'billingAddress/createBillingAddress'
);
export const createBillingAddressSuccess = createAction<BillingAddress>(
  'billingAddress/createBillingAddress/success'
);

export const updateBillingAddress = createAction<BillingAddressBody>(
  'billingAddress/updateBillingAddress'
);
export const updateBillingAddressSuccess = createAction<BillingAddress>(
  'billingAddress/updateBillingAddress/success'
);

export const billingAddressError = createAction('billingAddress/error');
