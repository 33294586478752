import { SagaReturnType, call, put } from 'redux-saga/effects';
import { getEnumSystemAPI } from './apis';
import { getEnumSystemSuccess, systemError } from './actions';
import { v1hubCustomerToken } from 'src/hooks/useAuth';

export function* getEnumSystemWORKER() {
  try {
    const { data }: SagaReturnType<typeof getEnumSystemAPI> = yield call(getEnumSystemAPI);
    yield put(getEnumSystemSuccess(data));
    // save in storage
    const v1hub = localStorage.getItem(v1hubCustomerToken);
    if (v1hub) {
      const v1hubParse = JSON.parse(v1hub);
      localStorage.setItem(v1hubCustomerToken, JSON.stringify({ ...v1hubParse, systemEnum: data }));
    } else {
      localStorage.setItem(v1hubCustomerToken, JSON.stringify({ systemEnum: data }));
    }
  } catch (err) {
    yield put(systemError());
    console.error(err);
  }
}
