import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import arrowRight from 'src/assets/images/arrow-right.png';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Base6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        descriptionSmall=""
        titleDark={t('resources educational resources') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <h1>{t('blog Educational resources title 1')}</h1>
          <h3>{t('blog Educational resources subtitle 1')}</h3>
          <p>{t('blog Educational resources section 1')}</p>
          <p>{t('blog Educational resources section 2')}</p>
          <p>{t('blog Educational resources section 3')}</p>
          <p>{t('blog Educational resources section 4')}</p>

          <h3>{t('blog Educational resources subtitle 2')}</h3>
          <p>{t('blog Educational resources section 5')}</p>
          <p>{t('blog Educational resources section 6')}</p>
          <p>{t('blog Educational resources section 7')}</p>
          <p>{t('blog Educational resources section 8')}</p>

          <h3>{t('blog Educational resources subtitle 3')}</h3>
          <p>{t('blog Educational resources section 9')}</p>
          <p>{t('blog Educational resources section 10')}</p>

          <h3>{t('blog Educational resources title 2')}</h3>
          <p>{t('blog Educational resources section 11')}</p>
          <p>{t('blog Educational resources section 12')}</p>
          <p>{t('blog Educational resources section 13')}</p>
          <p>{t('blog Educational resources section 14')}</p>
          <p>{t('blog Educational resources section 15')}</p>
          <p>{t('blog Educational resources section 16')}</p>
          <p>{t('blog Educational resources section 17')}</p>
          <p>{t('blog Educational resources section 18')}</p>
          <p>{t('blog Educational resources section 19')}</p>

          <h3>{t('blog Educational resources subtitle 4')}</h3>
          <p>{t('blog Educational resources section 20')}</p>
          <p>{t('blog Educational resources section 21')}</p>
          <p>{t('blog Educational resources section 22')}</p>

          <h3>{t('blog Educational resources title 3')}</h3>
          <p>{t('blog Educational resources section 23')}</p>
          <p>{t('blog Educational resources section 24')}</p>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Base6);
