import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'src/components/section/Section';

const TermAndCondition = () => {
  const { t } = useTranslation();

  return (
    <Section classContent="max-lg:px-[1rem] px-[3rem] pt-0">
      <div className="md:flex-1 w-full post-container">
        <h1 className="font-semibold mb-5 text-center text-5xl">{t('Privacy Policy')}</h1>
        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 1')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 1.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 1.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 1.2.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 1.2.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 1.3')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 2')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 2.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 2.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 2.3')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 3')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.3')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.4')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.5')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.6')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.7')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.8')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.1.9')}</p>

        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.2.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.2.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.2.3')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.2.4')}</p>

        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.3')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.4')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.4.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.4.2')}</p>

        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.5')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.6')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.7')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.7.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.7.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.7.3')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Privacy policy section 3.7.4')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 3.8')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 4')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 4.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 4.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 4.3')}</p>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 4.4')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 5')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 5.1')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Privacy policy title 6')}</h3>
        <p className="text-lg font-normal mb-2">{t('Privacy policy section 6.1')}</p>
      </div>
    </Section>
  );
};

export default React.memo(TermAndCondition);
