import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguageCode } from 'src/locales/type';

import vnLanguage from './translations/vn.json';
import enLanguage from './translations/en.json';
import koLanguage from './translations/ko.json';

export const defaultLanguage = LanguageCode.en;
export const defaultCountry = 'EN';

i18n.use(initReactI18next).init({
  resources: {
    [LanguageCode.en]: {
      translation: enLanguage,
    },
    [LanguageCode.vi]: {
      translation: vnLanguage,
    },
    [LanguageCode.ko]: {
      translation: koLanguage,
    },
  },
  debug: false,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
