import React from 'react';
const DashboardPage = React.lazy(() => import('../../partnerPage/dashboard'));
const LoginPage = React.lazy(() => import('../../partnerPage/login'));
const PartnerOrder = React.lazy(() => import('../../partnerPage/order'));
const PartnerOrderDetail = React.lazy(() => import('../../partnerPage/orderDetail'));

const prefix = 'partner';
const partnerRoutes = [
  {
    path: `${prefix}/login`,
    exact: true,
    name: 'Login',
    element: LoginPage,
    notHasLayout: true,
  },
  {
    path: `${prefix}/dashboard`,
    exact: true,
    name: 'Dashboard',
    element: DashboardPage,
  },
  {
    path: `${prefix}/order`,
    exact: true,
    name: 'order',
    element: PartnerOrder,
  },
  {
    path: `${prefix}/order-detail/:id`,
    exact: true,
    name: 'order detail',
    element: PartnerOrderDetail,
  },
  {
    path: `${prefix}/shipment`,
    exact: true,
    name: 'shipment',
    element: DashboardPage,
  },
  {
    path: `${prefix}/printer`,
    exact: true,
    name: 'printer',
    element: DashboardPage,
  },
];

export default partnerRoutes;
