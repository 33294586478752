import { createAction } from '@reduxjs/toolkit';
import { ShippingAddress, ShippingAddressBody } from './types';

export const createShippingAddress = createAction<ShippingAddressBody>(
  'shippingAddress/createShippingAddress'
);
export const createShippingAddressSuccess = createAction<ShippingAddress>(
  'shippingAddress/createShippingAddress/success'
);

export const updateShippingAddress = createAction<ShippingAddressBody>(
  'shippingAddress/updateShippingAddress'
);
export const updateShippingAddressSuccess = createAction<ShippingAddress>(
  'shippingAddress/updateShippingAddressSuccess/success'
);

export const getByMemberShippingAddress = createAction(
  'shippingAddress/getByMemberShippingAddress'
);
export const getByMemberShippingAddressSuccess = createAction<ShippingAddress[]>(
  'shippingAddress/getByMemberShippingAddress/success'
);

export const shippingAddressError = createAction('shippingAddress/error');
