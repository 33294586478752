import { ActionSaga } from 'src/types/saga.types';
import { CartBody, PaymentBody } from './types';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helper';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import {
  changeQuantityCartAPI,
  createFromOrderAPI,
  createOrUpdateCartAPI,
  deleteCartAPI,
  emptyCartAPI,
  getViewCartAPI,
  useCartAPI,
  createPayment,
} from './apis';
import {
  cartError,
  createFromOrderSuccess,
  createOrUpdateCartSuccess,
  deleteCartSuccess,
  emptyCartSuccess,
  getUseCartSuccess,
  getViewCart,
  getViewCartSuccess,
} from './actions';
import { t } from 'i18next';

export function* getUseCartWORKER() {
  try {
    const { data }: SagaReturnType<typeof useCartAPI> = yield call(useCartAPI);
    yield put(getUseCartSuccess(data));
  } catch (err) {
    yield put(cartError());
    console.error(err);
  }
}

export function* getViewCartWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof getViewCartAPI> = yield call(getViewCartAPI, payload);
    yield put(getViewCartSuccess(data.data));
  } catch (err) {
    yield put(cartError());
    console.error(err);
  }
}

export function* createOrUpdateWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof createOrUpdateCartAPI> = yield call(
      createOrUpdateCartAPI,
      payload
    );
    if (!data.isSuccess) {
      throw new Error('Error');
    }

    yield put(createOrUpdateCartSuccess(data.data));
    if (data.data?.memberId) yield put(getViewCart({ memberId: data.data.memberId }));
    showSuccessToast(`${t('add to cart successfully')}`);
    if (payload?.callback) {
      payload.callback(data);
    }
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('add to cart failed')}`);
    console.error(err);
  }
}

export function* createPaymentWORKER({ payload }: ActionSaga<PaymentBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof createPayment> = yield call(createPayment, payload);
    if (!data.isSuccess) {
      throw new Error('Error');
    }

    yield put(createOrUpdateCartSuccess(data.data));
    if (data.data?.memberId) yield put(getViewCart({ memberId: data.data.memberId }));
    showSuccessToast(`${t('add to cart successfully')}`);
    if (payload?.callback) {
      payload.callback(data);
    }
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('add to cart failed')}`);
    console.error(err);
  }
}

export function* changeQuantityWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof changeQuantityCartAPI> = yield call(
      changeQuantityCartAPI,
      payload
    );
    yield put(createOrUpdateCartSuccess(data.data));
    showSuccessToast(`${t('change quantity successfully')}`);
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('change quantity failed')}`);
    console.error(err);
  }
}

export function* deleteCartWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof deleteCartAPI> = yield call(deleteCartAPI, payload);
    yield put(deleteCartSuccess(data.data));
    if (payload?.callback) {
      payload.callback();
    }
    showSuccessToast(`${t('delete cart item successfully')}`);
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('delete cart item failed')}`);
    console.error(err);
  }
}

export function* emptyCartWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    yield call(emptyCartAPI);
    yield put(emptyCartSuccess());
    if (payload?.callback) {
      payload.callback();
    }
    showSuccessToast(`${t('empty cart successfully')}`);
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('empty cart failed')}`);
    console.error(err);
  }
}

export function* createFromOrderWORKER({ payload }: ActionSaga<CartBody>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }
    const { data }: SagaReturnType<typeof createFromOrderAPI> = yield call(
      createFromOrderAPI,
      payload
    );

    if (!data.isSuccess) {
      throw new Error('Err');
    }

    yield put(createFromOrderSuccess());

    showSuccessToast(`${t('add to cart successfully')}`);

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    yield put(cartError());
    showErrorToast(`${t('add to cart failed')}`);
    console.error(err);
  }
}
