import React from 'react';
import CardNews from 'src/components/card/CardNews';
import Section from 'src/components/section/Section';
import composites from 'src/assets/images/home/composites.png';
import crafting from 'src/assets/images/home/crafting.png';
import img13 from 'src/assets/images/company/img13.png';
import img3 from 'src/assets/images/company/img3.png';
import card1 from 'src/assets/images/3dPrinting/card1.png';
import factory1 from 'src/assets/images/3dPrinting/factory-1.jpg';
import factory2 from 'src/assets/images/3dPrinting/factory-2.jpg';
import factory3 from 'src/assets/images/3dPrinting/factory-3.jpg';
import factory4 from 'src/assets/images/3dPrinting/factory-4.jpg';
import CollapseCard from 'src/components/collapse/CollapseCard';
import { useTranslation } from 'react-i18next';

const B2CNetworkManufactured = () => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('printingservice our network') as string}
      extra={t('printingservice our network context') as string}
      extraProps={{
        style: {
          width: '755px',
          margin: 'auto',
        },
      }}
    >
      <div className="b2c-container">
        <div className="row row-gap-2">
          <div className="col-md-12 col-lg-4">
            <CardNews
              src={card1}
              title={t('printingservice banner desc', { data: 'Chicago' }) as string}
            />
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row row-gap-1">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <CardNews
                  src={factory1}
                  title={t('printingservice banner desc', { data: 'Americas' }) as string}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <CardNews
                  src={factory2}
                  title={t('printingservice banner desc', { data: 'Korean' }) as string}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <CardNews
                  src={factory3}
                  title={t('printingservice banner desc', { data: 'China' }) as string}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <CardNews
                  src={factory4}
                  title={t('printingservice banner desc', { data: 'VietNam' }) as string}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="text-left">{t('FAQ')}</h1>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <CollapseCard title={t('printingservice how much') as string} />
            <CollapseCard title={t('printingservice how to reduce the cost') as string} />
            <CollapseCard title={t('printingservice how do you select') as string} />
            <CollapseCard title={t('printingservice how do you guarantee') as string} />
            <CollapseCard title={t('printingservice how do i select the right') as string} />
            <CollapseCard title={t('printingservice where can i learn more') as string} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(B2CNetworkManufactured);
