/**
 * For process asynchronous
 * Handle the action and terminate
 */

import toast from 'react-hot-toast';
import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  authenticationError,
  getManufacturerProfileSuccess,
  getMemberProfileSuccess,
  getUserInfoSuccess,
  jwtInfoError,
  jwtInfoSuccess,
  loginError,
  loginSuccess,
  manufacturerLoginError,
  manufacturerLoginSuccess,
  refreshTokenSuccess,
} from './actions';
import {
  getJwtInfoAPI,
  getManufacturerProfileAPI,
  getMemberProfileAPI,
  getUserInfoAPI,
  loginAPI,
  manufacturerLoginAPI,
  refreshTokenAPI,
  revokeTokenAPI,
} from './apis';
import { LoginForm, RefreshTokenBody } from './types';
import { saveAppLocalStorage } from 'src/helpers/localStorage.helpers';
import { showSuccessToast } from 'src/helpers/toast.helper';
import { v1hubCustomerToken, v1hubManufacturerToken } from 'src/hooks/useAuth';
import { t } from 'i18next';

export function* getJwtInfoWorker() {
  try {
    const { data }: SagaReturnType<typeof getJwtInfoAPI> = yield call(getJwtInfoAPI);
    yield put(jwtInfoSuccess(data.data));
  } catch (e) {
    yield put(jwtInfoError());
    console.error('Error', e);
  }
}

export function* loginWorker(action: ActionSaga<LoginForm>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof loginAPI> = yield call(loginAPI, payload);
      yield put(loginSuccess(data.data));
      toast.success(t('Login success!'));
      localStorage.setItem(v1hubCustomerToken, JSON.stringify(data.data));
      window.location.reload();
    }
  } catch (error: any) {
    yield put(loginError());
    const mess = error?.response?.data?.message;
    if (mess && mess.includes('User is not verify email')) {
      toast.error(mess);
    } else {
      toast.error(t('Login failed!'));
    }
  }
}

export function* manufacturerLoginWorker(action: ActionSaga<LoginForm>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof manufacturerLoginAPI> = yield call(
        manufacturerLoginAPI,
        {
          username: payload.username,
          password: payload.password,
        }
      );
      localStorage.setItem(v1hubManufacturerToken, JSON.stringify(data.data));
      yield put(manufacturerLoginSuccess(data.data));
      toast.success(t('Login success!'));
      yield payload.callback && payload.callback();
    }
  } catch (error: any) {
    yield put(manufacturerLoginError());
    const mess = error?.response?.data?.message;
    if (mess && mess.includes('User is not verify email')) {
      toast.error(mess);
    } else {
      toast.error(t('Login failed!'));
    }
  }
}

export function* getUserInfoWORKER() {
  try {
    const { data }: SagaReturnType<typeof getUserInfoAPI> = yield call(getUserInfoAPI);
    yield put(getUserInfoSuccess(data));
  } catch (e) {
    yield put(authenticationError());
    console.error('Error', e);
  }
}

export function* getMemberProfileWORKER() {
  try {
    const { data }: SagaReturnType<typeof getMemberProfileAPI> = yield call(getMemberProfileAPI);
    yield put(getMemberProfileSuccess(data.data));
    saveAppLocalStorage({ member: data.data });
  } catch (e) {
    yield put(authenticationError());
    console.error('Error', e);
  }
}

export function* getManufacturerProfileWORKER() {
  try {
    const { data }: SagaReturnType<typeof getManufacturerProfileAPI> = yield call(
      getManufacturerProfileAPI
    );
    yield put(getManufacturerProfileSuccess(data.data));
    saveAppLocalStorage({ manufacturer: data.data });
  } catch (e) {
    yield put(authenticationError());
    console.error('Error', e);
  }
}

export function* refreshTokenWorker(action: ActionSaga<RefreshTokenBody>) {
  try {
    const { payload } = action;

    if (payload) {
      const { data }: SagaReturnType<typeof refreshTokenAPI> = yield call(refreshTokenAPI, payload);
      yield put(refreshTokenSuccess(data.data));
      saveAppLocalStorage(data.data);
      window.location.reload();
    }
  } catch (e) {
    yield put(loginError());
    console.error('Error', e);
    localStorage.removeItem(v1hubCustomerToken);
    window.location.reload();
    // toast.error('Refresh token failed!');
  }
}

export function* logoutWorker(action: ActionSaga<Partial<Omit<RefreshTokenBody, 'useId'>>>) {
  try {
    const { payload } = action;

    if (payload) {
      // revoke token first
      if (payload?.value) {
        yield call(revokeTokenAPI, payload);
      }
      // remove localStorage
      if (payload?.type === v1hubManufacturerToken) {
        localStorage.removeItem(v1hubManufacturerToken);
      } else {
        localStorage.removeItem(v1hubCustomerToken);
      }
      window.location.reload();
      showSuccessToast('Logged out successfully');
    }
  } catch (e) {
    if (action.payload?.callbackError) {
      action.payload.callbackError();
    }
    yield put(authenticationError());
    console.error('Error', e);
    toast.error(t('Logout failed!'));
  }
}
