import { createReducer } from '@reduxjs/toolkit';
import {
  forgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
  idmRegister,
  idmRegisterError,
  idmRegisterSuccess,
  idmVerifyEmail,
  idmVerifyEmailError,
  idmVerifyEmailSuccess,
} from './actions';
import { AdminUserReducer } from './types';

const initialState = { ui: {} };

const adminUserReducer = createReducer<AdminUserReducer>(initialState, (builder) => {
  builder.addCase(idmRegister, (state, action) => {
    state.ui = { loading: true };
  });
  builder.addCase(idmRegisterSuccess, (state, action) => {
    state.ui = { loading: false };
    state.isCreateAccountDone = true;
  });
  builder.addCase(idmRegisterError, (state, action) => {
    state.ui = { loading: false };
  });
  builder.addCase(forgotPassword, (state, action) => {
    state.ui = { loading: true };
  });
  builder.addCase(forgotPasswordSuccess, (state, action) => {
    state.ui = { loading: false };
  });
  builder.addCase(forgotPasswordError, (state, action) => {
    state.ui = { loading: false };
  });
  builder.addCase(idmVerifyEmail, (state, action) => {
    state.ui = { loading: true };
  });
  builder.addCase(idmVerifyEmailSuccess, (state, action) => {
    state.ui = { loading: false };
    state.isVerifyEmailError = false;
  });
  builder.addCase(idmVerifyEmailError, (state, action) => {
    state.ui = { loading: false };
    state.isVerifyEmailError = true;
  });
  builder.addDefaultCase((state, action) => {});
});

export default adminUserReducer;
