import { Box, styled } from '@mui/material';
import React from 'react';
import imgdetaii from 'src/assets/images/materials/imgdetaii.png';
import { Material } from './MaterialsContent';
import Slider from 'react-slick';
import { t } from 'i18next';

const imgSliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  autoplay: true,
  arrows: false,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const SliderCustom = styled(Slider)(({ theme }) => ({
  '& .slick-slide': {
    padding: '12px',
  },
  [theme.breakpoints.down('sm')]: {},
}));

const Overview = ({ materialDetail }: { materialDetail: Material }) => {
  if (!materialDetail) return null;
  return (
    <Box>
      {materialDetail.define?.map((item, index) => (
        <p key={index} className="w-full text-lg font-normal ml-auto mr-auto mb-2.5 text-[#312F30]">
          {t(item)}
        </p>
      ))}
      {materialDetail.imagesSlide ? (
        materialDetail.imagesSlide.length > 4 ? (
          <SliderCustom {...imgSliderSettings}>
            {materialDetail.imagesSlide.map((item, index) => (
              <Box key={index}>
                <img src={item} alt="" className="block" />
              </Box>
            ))}
          </SliderCustom>
        ) : (
          <div className={`flex my-4 max-[1024px]:gap-2 gap-4 w-full`}>
            {materialDetail.imagesSlide.map((item, index) => (
              <div className="w-1/3" key={index}>
                <img src={item} alt="" className="block" />
              </div>
            ))}
          </div>
        )
      ) : (
        <SliderCustom {...imgSliderSettings}>
          {[...Array(6)].map((item, index) => (
            <Box key={index}>
              <img src={imgdetaii} alt="" className="block" />
            </Box>
          ))}
        </SliderCustom>
      )}

      <div className="w-full">
        {materialDetail.contents &&
          materialDetail.contents?.map((item, i) => (
            <div key={i}>
              <h3 className="text-2xl font-semibold mb-2 mt-4">{t(item.title)}</h3>
              {item.content.map((item, index) => (
                <p className="text-lg font-normal mb-2" key={index}>
                  {t(item)}
                </p>
              ))}
            </div>
          ))}
        {/* {materialDetail.characteristics && (
          <>
            <h3 className="text-2xl font-semibold mt-4 mb-2">{t('characteristics')}</h3>
            {materialDetail.characteristics?.list?.map((item, index) => (
              <p className="text-lg font-normal mb-2" key={index}>
                {t(item)}
              </p>
            ))}
            <table className="table mt-2 mb-0 material-detail-table">
              <thead>
                <tr>
                  <th className="text-lg font-semibold">{t('Standard capabilities')}</th>
                  <th className="text-lg font-semibold">{t('Description')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('price')}</b>
                  </td>
                  <td>{materialDetail.characteristics?.price}</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('lead time')}</b>
                  </td>
                  <td>
                    &lt; {materialDetail.characteristics?.leadTime} {t('days')}
                  </td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('wall thickness')}</b>
                  </td>
                  <td>{materialDetail.characteristics?.wallThickness}</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('tolerances')}</b>
                  </td>
                  <td>{t(materialDetail.characteristics?.tolerances)}</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('max part size')}</b>
                  </td>
                  <td>{materialDetail.characteristics?.maxPartSize}</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap text-lg">
                    <b>{t('layer height')}</b>
                  </td>
                  <td>{materialDetail.characteristics?.layerHeight}</td>
                </tr>
              </tbody>
            </table>
          </>
        )} */}
      </div>
    </Box>
  );
};

export default React.memo(Overview);
