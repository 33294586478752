import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import arrowRight from 'src/assets/images/arrow-right.png';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Base5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        descriptionSmall={t('blog CAD & file preparation banner content') as string}
        titleDark={t('blog CAD & file preparation banner title') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <h3>{t('blog CAD & file preparation title 1')}</h3>
          <p>{t('blog CAD & file preparation content 1')}</p>

          <h3>{t('blog CAD & file preparation title 2')}</h3>
          <p>{t('blog CAD & file preparation content 2')}</p>
          <p>{t('blog CAD & file preparation content 3')}</p>
          <p>{t('blog CAD & file preparation content 4')}</p>
          <p>{t('blog CAD & file preparation content 5')}</p>
          <p>{t('blog CAD & file preparation content 6')}</p>
          <p>{t('blog CAD & file preparation content 7')}</p>
          <p>{t('blog CAD & file preparation content 8')}</p>

          <h3>{t('blog CAD & file preparation title 3')}</h3>
          <p className="mb-2">{t('blog CAD & file preparation content 9')}</p>
          <ul className="list-disc pl-8">
            <li>{t('blog CAD & file preparation content 10')}</li>
            <li>{t('blog CAD & file preparation content 11')}</li>
            <li>{t('blog CAD & file preparation content 12')}</li>
            <li>{t('blog CAD & file preparation content 13')}</li>
            <li>{t('blog CAD & file preparation content 14')}</li>
            <li>{t('blog CAD & file preparation content 15')}</li>
            <li>{t('blog CAD & file preparation content 16')}</li>
            <li>{t('blog CAD & file preparation content 17')}</li>
            <li>{t('blog CAD & file preparation content 18')}</li>
            <li>{t('blog CAD & file preparation content 19')}</li>
            <li>{t('blog CAD & file preparation content 20')}</li>
            <li>{t('blog CAD & file preparation content 21')}</li>
            <li>{t('blog CAD & file preparation content 22')}</li>
          </ul>

          <h3>{t('blog CAD & file preparation title 4')}</h3>
          <p>{t('blog CAD & file preparation content 23')}</p>
          <p>{t('blog CAD & file preparation content 24')}</p>
          <p>{t('blog CAD & file preparation content 25')}</p>
          <p>{t('blog CAD & file preparation content 26')}</p>
          <p>{t('blog CAD & file preparation content 27')}</p>
          <p>{t('blog CAD & file preparation content 28')}</p>
          <p>{t('blog CAD & file preparation content 29')}</p>
          <p>{t('blog CAD & file preparation content 30')}</p>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Base5);
