import { ResponseServerAPI } from 'src/types/server.types';
import { ShippingOption, ShippingOptionBody } from './types';
import { axiosInceptors } from 'src/services/axiosInceptors';

type paramsSearchColor = {
  materialId?: number;
  colorName?: string;
};

export const getListMaterialAPI = (data?: string) => {
  return axiosInceptors.get<ResponseServerAPI>(`/Material/getall?materialName=${data || ''}`);
};

export const getListColorAPI = (data?: paramsSearchColor) => {
  return axiosInceptors.get<ResponseServerAPI>(
    `/Color/getColorByMaterialId?materialId=${data?.materialId || 0}&colorName=${
      data?.colorName || ''
    }`
  );
};

export const uploadBlobAPI = (data: FormData) => {
  return axiosInceptors.post<ResponseServerAPI>('api/BlobClient/upload-file', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const uploadImageBlobAPI = async (formData: FormData) => {
  const { data } = await axiosInceptors.post('api/BlobClient/upload-image', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

export const getModelDetail = async (body: {
  vendorModelId?: string;
  modelId?: string;
  technologyMaterialId?: number;
  colorId?: number;
  scale?: number;
  blobFileName?: string;
  fileId?: number;
}) => {
  return await axiosInceptors.post('api/BlobClient/model/detail', body);
};

export const getModelAPI = (modelId?: number) => {
  return axiosInceptors.get<ResponseServerAPI>('/Shapeway/model/' + modelId);
};

export const getShippingOptionAPI = (payload: ShippingOptionBody) => {
  return axiosInceptors.post<ResponseServerAPI<ShippingOption>>(
    '/Order/shipping-time/get-by-vendor',
    payload
  );
};
