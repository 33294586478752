export type TColorContent = {
  id: number;
  code: string;
  contents?: { title: string; content?: string[]; images?: string[] }[];
};

export const ColorContents: TColorContent[] = [
  {
    id: 1,
    code: 'PLA(FDM)',
    contents: [
      {
        title: 'material color-finishes PLA title 1',
        content: ['material color-finishes PLA content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/pla/pla-3.jpg',
        ],
      },
    ],
  },
  {
    id: 2,
    code: 'ABS(FDM)',
    contents: [
      {
        title: 'material color-finishes ABS title 1',
        content: ['material color-finishes ABS content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/abs/abs-3.jpg',
        ],
      },
    ],
  },
  {
    id: 3,
    code: 'Gray-Resin',
    contents: [
      {
        title: 'material color-finishes Gray Resin title 1',
        content: ['material color-finishes Gray Resin content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-4.jpg',
        ],
      },
      {
        title: 'material color-finishes Gray Resin title 2',
        content: ['material color-finishes Gray Resin content 2'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-6.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-7.jpg',
        ],
      },
      {
        title: 'material color-finishes Gray Resin title 3',
        content: [
          'material color-finishes Gray Resin content 3',
          'material color-finishes Gray Resin content 4',
          'material color-finishes Gray Resin content 5',
          'material color-finishes Gray Resin content 6',
          'material color-finishes Gray Resin content 7',
          'material color-finishes Gray Resin content 8',
          'material color-finishes Gray Resin content 9',
          'material color-finishes Gray Resin content 10',
          'material color-finishes Gray Resin content 11',
          'material color-finishes Gray Resin content 12',
          'material color-finishes Gray Resin content 13',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/gray-resin/gray-resin-8.jpg',
        ],
      },
    ],
  },
  {
    id: 4,
    code: 'High-detail-Resin',
    contents: [
      {
        title: 'material color-finishes High-detail Resin title 1',
        content: ['material color-finishes High-detail Resin content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-resin/high-detail-resin-3.jpg',
        ],
      },
    ],
  },
  // {
  //   id: 4.1,
  //   code: 'Nylon',
  // },
  // {
  //   id: 5,
  //   code: 'PETG',
  // },
  // {
  //   id: 6,
  //   code: 'TPU',
  // },
  // {
  //   id: 7,
  //   code: 'ASA',
  // },
  // {
  //   id: 8,
  //   code: 'PEI',
  // },
  // {
  //   id: 9,
  //   code: 'PC',
  // },
  // {
  //   id: 10,
  //   code: 'Standard-Resin',
  // },
  {
    id: 11,
    code: 'Rubber-like(MJF)',
    contents: [
      {
        title: 'material color-finishes Rubber-like title 1',
        content: ['material color-finishes Rubber-like content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Rubber-like title 2',
        content: [
          'material color-finishes Rubber-like content 2',
          'material color-finishes Rubber-like content 3',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-6.jpg',
        ],
      },
      {
        title: 'material color-finishes Rubber-like title 3',
        content: [
          'material color-finishes Rubber-like content 4',
          'material color-finishes Rubber-like content 5',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-7.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-8.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/rubber-like/rubber-like-9.jpg',
        ],
      },
    ],
  },
  {
    id: 12,
    code: 'Transparent-Resin',
    contents: [
      {
        title: 'material color-finishes Transparent Resin title 1',
        content: [
          'material color-finishes Transparent Resin content 1',
          'material color-finishes Transparent Resin content 2',
          'material color-finishes Transparent Resin content 3',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-2.jpg',
        ],
      },
      {
        title: 'material color-finishes Transparent Resin title 2',
        content: [
          'material color-finishes Transparent Resin content 4',
          'material color-finishes Transparent Resin content 5',
          'material color-finishes Transparent Resin content 6',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-3.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/transparent-resin/transparent-resin-5.jpg',
        ],
      },
    ],
  },
  {
    id: 13,
    code: 'Multicolor+',
    contents: [
      {
        title: 'material color-finishes Multicolor title 1',
        content: ['material color-finishes Multicolor content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/multicolor/multicolor-3.jpg',
        ],
      },
    ],
  },
  {
    id: 14,
    code: 'Aluminum',
    contents: [
      {
        title: 'material color-finishes Aluminum title 1',
        content: ['material color-finishes Aluminum content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/aluminum/alunium-3.jpg',
        ],
      },
    ],
  },
  {
    id: 15,
    code: 'High-Detail-Stainless-Steel',
    contents: [
      {
        title: 'material color-finishes High-Detail Stainless Steel title 1',
        content: ['material color-finishes High-Detail Stainless Steel content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-3.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-5.jpg',
        ],
      },
      {
        title: 'material color-finishes High-Detail Stainless Steel title 2',
        content: ['material color-finishes High-Detail Stainless Steel content 2'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-6.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-7.jpg',
        ],
      },
      {
        title: 'material color-finishes High-Detail Stainless Steel title 3',
        content: [
          'material color-finishes High-Detail Stainless Steel content 3',
          'material color-finishes High-Detail Stainless Steel content 4',
          'material color-finishes High-Detail Stainless Steel content 5',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-8.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/high-detail-stainless-steel/high-detail-stainless-steel-9.jpg',
        ],
      },
    ],
  },
  {
    id: 16,
    code: 'Steel',
    contents: [
      {
        title: 'material color-finishes Steel title 1',
        content: [
          'material color-finishes Steel content 1',
          'material color-finishes Steel content 2',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Steel title 2',
        images: ['https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-4.jpg'],
      },
      {
        title: 'material color-finishes Steel title 3',
        content: [
          'material color-finishes Steel content 3',
          'material color-finishes Steel content 4',
          'material color-finishes Steel content 5',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-6.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-7.jpg',
        ],
      },
      {
        title: 'material color-finishes Steel title 4',
        content: [
          'material color-finishes Steel content 6',
          'material color-finishes Steel content 7',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-8.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-9.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-10.jpg',
        ],
      },
      {
        title: 'Steel title 5',
        content: [
          'material color-finishes Steel content 8',
          'material color-finishes Steel content 9',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-11.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-12.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-13.jpg',
        ],
      },
      {
        title: 'material color-finishes Steel title 6',
        content: ['material color-finishes Steel content 10'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-14.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-15.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-16.jpg',
        ],
      },
      {
        title: 'material color-finishes Steel title 7',
        content: ['material color-finishes Steel content 11'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-17.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/steel/steel-18.jpg',
        ],
      },
    ],
  },
  {
    id: 17,
    code: 'Titanium',
    contents: [
      {
        title: 'material color-finishes Titanium title 1',
        content: ['material color-finishes Titanium content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Titanium title 2',
        content: ['material color-finishes Titanium content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/titanium/titanium-5.jpg',
        ],
      },
    ],
  },
  {
    id: 18,
    code: 'Brass',
    contents: [
      {
        title: 'material color-finishes Brass title 1',
        content: ['material color-finishes Brass content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-6.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 2',
        content: ['material color-finishes Brass content 2'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-7.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-8.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-9.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 3',
        content: [
          'material color-finishes Brass content 3',
          'material color-finishes Brass content 4',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-10.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-11.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 4',
        content: [
          'material color-finishes Brass content 5',
          'material color-finishes Brass content 6',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-12.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-13.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 5',
        content: [
          'material color-finishes Brass content 7',
          'material color-finishes Brass content 8',
          'material color-finishes Brass content 9',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-14.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-15.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-16.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 6',
        content: [
          'material color-finishes Brass content 10',
          'material color-finishes Brass content 11',
          'material color-finishes Brass content 12',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-17.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-18.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-19.jpg',
        ],
      },
      {
        title: 'material color-finishes Brass title 7',
        content: [
          'material color-finishes Brass content 13',
          'material color-finishes Brass content 14',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-20.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/brass/brass-21.jpg',
        ],
      },
    ],
  },
  {
    id: 19,
    code: 'Bronze',
    contents: [
      {
        title: 'material color-finishes Bronze title 1',
        content: ['material color-finishes Bronze content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Bronze title 2',
        content: ['material color-finishes Bronze content 2'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-6.jpg',
        ],
      },
      {
        title: 'material color-finishes Bronze title 3',
        content: ['material color-finishes Bronze content 3'],
        images: ['https://v1hubstorage.blob.core.windows.net/materials-web/bronze/bronze-5.jpg'],
      },
    ],
  },
  {
    id: 20,
    code: 'Copper',
    contents: [
      {
        title: 'material color-finishes Copper title 1',
        content: ['material color-finishes Copper content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/copper/copper-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/copper/copper-5.jpg',
        ],
      },
    ],
  },
  {
    id: 21,
    code: 'Gold',
    contents: [
      {
        title: 'material color-finishes Gold title 1',
        content: [
          'material color-finishes Gold content 1',
          'material color-finishes Gold content 2',
          'material color-finishes Gold content 3',
          'material color-finishes Gold content 4',
          'material color-finishes Gold content 5',
          'material color-finishes Gold content 6',
          'material color-finishes Gold content 7',
          'material color-finishes Gold content 8',
          'material color-finishes Gold content 9',
          'material color-finishes Gold content 10',
          'material color-finishes Gold content 11',
          'material color-finishes Gold content 12',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Gold title 2',
        content: [
          'material color-finishes Gold content 13',
          'material color-finishes Gold content 14',
          'material color-finishes Gold content 15',
          'material color-finishes Gold content 16',
          'material color-finishes Gold content 17',
          'material color-finishes Gold content 18',
          'material color-finishes Gold content 19',
          'material color-finishes Gold content 20',
          'material color-finishes Gold content 21',
          'material color-finishes Gold content 22',
          'material color-finishes Gold content 23',
          'material color-finishes Gold content 24',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/gold/gold-6.jpg',
        ],
      },
    ],
  },
  {
    id: 22,
    code: 'Silver',
    contents: [
      {
        title: 'material color-finishes Silver title 1',
        content: ['material color-finishes Silver content 1'],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-1.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-2.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-3.jpg',
        ],
      },
      {
        title: 'material color-finishes Silver title 2',
        content: [
          'material color-finishes Silver content 2',
          'material color-finishes Silver content 3',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-4.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-5.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-6.jpg',
        ],
      },
      {
        title: 'material color-finishes Silver title 3',
        content: [
          'material color-finishes Silver content 4',
          'material color-finishes Silver content 5',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-7.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-8.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-9.jpg',
        ],
      },
      {
        title: 'material color-finishes Silver title 4',
        content: [
          'material color-finishes Silver content 6',
          'material color-finishes Silver content 7',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-10.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-11.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-12.jpg',
        ],
      },
      {
        title: 'material color-finishes Silver title 5',
        content: [
          'material color-finishes Silver content 8',

          'material color-finishes Silver content 9',
        ],
        images: [
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-13.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-14.jpg',
          'https://v1hubstorage.blob.core.windows.net/materials-web/silver/silver-15.jpg',
        ],
      },
    ],
  },
];
