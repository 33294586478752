import React from 'react';
import Section from 'src/components/section/Section';
import rs from 'src/assets/images/3dPrinting/rs.png';
import img6 from 'src/assets/images/company/img6.png';
import img9 from 'src/assets/images/company/img9.png';
import img10 from 'src/assets/images/company/img10.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const B2CPrintingResource = () => {
  const { t } = useTranslation();
  return (
    <Section className="b2c-container posts">
      <div className="row row-gap-2 justify-content-center align-items-stretch">
        <div className="col-sm-3 col-md-3 col-lg-3">
          <CardProduct
            className="h-100"
            src={rs}
            name={t('3d printing guide') as string}
            position={t('resources 3d printing guide context') as string}
          />
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3">
          <CardProduct
            className="h-100"
            src={img6}
            name={t('metal 3D printing') as string}
            position={t('printingservice metal 3d printing context') as string}
          />
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3">
          <CardProduct
            className="h-100"
            src={img9}
            name={t('what is a cnc machine') as string}
            position={t('printingservice what is a cnc machine context') as string}
          />
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3">
          <CardProduct
            className="h-100"
            src={img10}
            name={t('sheet metal fabrication') as string}
            position={t('printingservice sheet metal fabrication context') as string}
          />
        </div>
      </div>
    </Section>
  );
};

type CardProductProps = {
  src?: string;
  name?: string;
  position?: string;
  className?: string;
};
const CardProduct: React.FC<CardProductProps> = (props) => {
  const { src, name, position, className } = props;
  const { t } = useTranslation();
  return (
    <div className={`card-member has-border ${className || ''}`}>
      <div className="card-member--image">
        <img alt="img-member" src={src} />
      </div>
      <div className="card-product--content">
        <div className="card-product--name">{name}</div>
        <div className="card-product--position">{position}</div>
        <NavLink
          style={{
            fontWeight: '500',
            color: '#0076E2',
            margin: '30px 0 0 0',
            textDecoration: 'none',
            display: 'flex',
          }}
          to="#"
        >
          {t('read more') as string}
        </NavLink>
      </div>
    </div>
  );
};

export default React.memo(B2CPrintingResource);
