import React, { ButtonHTMLAttributes } from 'react';
import { Spinner } from 'react-bootstrap';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  shape?: 'circle' | 'normal';
  color?: 'primary' | 'link' | 'white' | 'grey' | 'white-1' | 'outline-dark';
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { children, shape, color, size, loading, ...buttonProps } = props;
  return (
    <button
      {...buttonProps}
      disabled={loading ? true : buttonProps?.disabled}
      className={`d-flex align-items-center justify-content-center flex-row button link ${
        shape === 'circle' ? 'circle' : ''
      } ${color || ''} ${size || ''} ${buttonProps?.className}`}
    >
      {loading && <Spinner animation="border" className="me-1" size="sm" />}
      {children}
    </button>
  );
};

export default React.memo(Button);
