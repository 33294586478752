import React from 'react';

const CartPage = React.lazy(() => import('../../pages/b2c/CartPage'));
const GetInstantQuote = React.lazy(() => import('../../pages/b2c/GetInstantQuote'));
const MyOrder = React.lazy(() => import('../../pages/order/MyOrder'));
const OrderDetail = React.lazy(() => import('../../pages/order/OrderDetail'));

const protectedRoutes = [
  // new page
  {
    path: '/cart-page',
    exact: true,
    name: 'CartPage',
    element: CartPage,
  },
  {
    path: '/get-instant-quote',
    exact: true,
    name: 'GetInstantQuote',
    element: GetInstantQuote,
  },
  {
    path: '/my-order',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/waiting-list',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/in-production',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/in-shipping',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/completed',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/cancelled',
    exact: true,
    name: 'MyOrder',
    element: MyOrder,
  },
  {
    path: '/my-order/order/:orderId',
    exact: true,
    name: 'Order Detail',
    element: OrderDetail,
  },
];

export default protectedRoutes;
