import React from 'react';
import img1 from 'src/assets/images/laserPrinting/img1.png';
import { Table } from 'react-bootstrap';
import Section from 'src/components/section/Section';
import ProductDescription from '../layout/ProductDescription';
import { useTranslation } from 'react-i18next';

export const IntroducePrinting = () => {
  const { t } = useTranslation();
  return (
    <div className="container introducePrinting" style={{ margin: '100px auto' }}>
      <ProductDescription
        rtl
        image={img1}
        title={t('what is multi jet fusion?') as string}
        extra={
          <>
            <span className="d-block">{t('mjfprintingservice section 1')}</span>
            <br />
            <span className="d-block">{t('mjfprintingservice section 2')}</span>
            <br />
            <span className="d-block">{t('mjfprintingservice section 3')}</span>
          </>
        }
      />
      <Section
        align="left"
        title={t('our mjf printing services') as string}
        extra={
          <>
            <p>{t('mjfprintingservice our mjf printing services context 1')}</p>
            <p>{t('mjfprintingservice our mjf printing services context 2')}</p>
          </>
        }
        extraProps={{
          style: {
            padding: '0 3.5rem',
          },
        }}
        titleProps={{
          style: {
            padding: '5rem 3.5rem 1rem 3.5rem',
          },
        }}
      >
        <Table bordered size="xl">
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th>{t('standard capabilities')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('maximum build size')}</td>
              <td>395 x 500 x 395 mm (15.53&quot; x 19.68&quot; x 15.53&quot;)</td>
            </tr>
            <tr>
              <td>{t('lead time')}</td>
              <td>3 {t('business days')}</td>
            </tr>
            <tr>
              <td>{t('dimensional accuracy')}</td>
              <td>
                {t('lower limit on', {
                  percent: '0.5%',
                  minimet: '0.15',
                  degree: '0.006 in',
                })}
              </td>
            </tr>
            <tr>
              <td>{t('layer thickness')}</td>
              <td>50-100 μm</td>
            </tr>
            <tr>
              <td>{t('minimum feature size')}</td>
              <td>0.2 mm </td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </div>
  );
};
