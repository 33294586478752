import { createAction } from '@reduxjs/toolkit';
import { Cart, CartBody, PaymentBody } from './types';

export const createOrUpdateCart = createAction<CartBody>('Cart/createOrUpdate');
export const changeQuantityCart = createAction<CartBody>('Cart/changeQuantity');
export const createOrUpdateCartSuccess = createAction<Cart>('Cart/createOrUpdate/success');

export const createPayment = createAction<PaymentBody>('Cart/create');

export const deleteCart = createAction<CartBody>('Cart/deleteCart');
export const deleteCartSuccess = createAction<Cart>('Cart/deleteCart/success');

export const emptyCart = createAction<CartBody | undefined>('Cart/emptyCart');
export const emptyCartSuccess = createAction('Cart/emptyCart/success');

export const getUseCart = createAction('Cart/useCart');
export const getUseCartSuccess = createAction<Cart[]>('Cart/useCart/success');

export const getViewCart = createAction<CartBody>('Cart/getViewCart');
export const getViewCartSuccess = createAction<Cart[]>('Cart/getViewCart/success');

export const createFromOrder = createAction<CartBody>('Cart/createFromOrder');
export const createFromOrderSuccess = createAction('Cart/createFromOrder/success');

export const cartError = createAction('Cart/error');
