import { t } from 'i18next';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { setUI } from 'src/features/authentication/actions';
import { useAppDispatch, useAppSelector } from 'src/services/redux';

type ProtectedRouteProps = {
  children: any;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { member } = useAppSelector((s) => s.authentication);

  const handleLoginTrigger = () => {
    dispatch(setUI({ triggerLogin: true }));
  };

  if (!member) {
    // user is not authenticated
    return (
      <div
        className="row text-center justify-content-center align-items-center"
        style={{ height: ' calc(100vh - 350px)' }}
      >
        <div className="col-12 col-md-6">
          <i className="icofont-warning" style={{ fontSize: '3rem' }}></i>
          <Alert.Heading className="mt-2">{t('Need Login')}</Alert.Heading>
          <hr />
          <p className="mb-0">
            {t('Please click to icon in header or')}{' '}
            <span className="link" onClick={handleLoginTrigger}>
              {t('click in here to login')}
            </span>{' '}
          </p>
        </div>
      </div>
    );
  }

  return children;
};

export default React.memo(ProtectedRoute);
