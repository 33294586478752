import { axiosInceptors } from 'src/services/axiosInceptors';
import { ResponseServerAPI } from 'src/types/server.types';
import { CountByMember, GetListOrderParams, MyOrderDetail } from './types';

const ROOT = 'Order';

export const getOrderByMemberAPI = (payload: any) => {
  return axiosInceptors.post<
    ResponseServerAPI<{ content: MyOrderDetail[]; page: number; totalPages: number }>
  >(ROOT + '/get-list', {
    ...payload,
    fields: '',
    FullTextSearch: '',
    ListId: [],
    Name: '',
  });
};

export const getOrderListAPI = (payload: GetListOrderParams) => {
  return axiosInceptors.post(ROOT + '/get-list', {
    fields: '',
    page: 1,
    size: 10,
    filter: '',
    fullTextSearch: '',
    name: '',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    listId: [],
    applicationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    searchAllApp: true,
    memberId: 0,
    orderStatuses: [0],
    sort: '-Id',
    ...payload,
  });
};

export const getCountByMemberAPI = () => {
  return axiosInceptors.get<ResponseServerAPI<CountByMember>>(ROOT + '/count-by-member');
};
