import React from 'react';
import OurPlatformBanner from 'src/pages/components/company/OurPlatformBanner';
import PlatformSpecify from 'src/pages/components/company/PlatformSpecify';

const OurPlatform = () => {
  return (
    <>
      <OurPlatformBanner />

      <PlatformSpecify />
    </>
  );
};

export default React.memo(OurPlatform);
