import React, { ReactNode } from 'react';
import Section from 'src/components/section/Section';
import img1 from 'src/assets/images/fdmPrinting/img1.png';
import img2 from 'src/assets/images/fdmPrinting/img2.png';
import img3 from 'src/assets/images/fdmPrinting/img3.png';
import img4 from 'src/assets/images/fdmPrinting/img4.png';
import img5 from 'src/assets/images/fdmPrinting/img5.png';
import { Table } from 'react-bootstrap';
import Button from 'src/components/button/Button';
import { useMediaQuery } from '@mui/material';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  titleExtra?: string | ReactNode;
  extra?: string | ReactNode;
  table?: ReactNode;
  tableExtra?: string | ReactNode;
  rtl?: boolean;
};
const ProductDescription: React.FC<ProductDescriptionProps> = (props) => {
  const { image, title, titleExtra, extra, rtl } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div>
      <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
        <div className="col-md-5 col-lg-5">
          <div>
            <img src={image} alt="" />
          </div>
        </div>
        <div
          className={`col-md-7 col-lg-7 m-auto ${isMobile ? '' : rtl ? 'pr-[15%]' : 'pl-[15%]'}`}
        >
          <section className={`section`}>
            <h1 className="text-left">{title}</h1>
            <h2 className="text-left">{titleExtra}</h2>
            <div
              className="extra mb-5"
              style={{ width: '100%', maxWidth: '100%', textAlign: 'left', margin: 'auto' }}
            >
              {extra}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductDescription);
