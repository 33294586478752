import React, { ReactNode } from 'react';
import Section from 'src/components/section/Section';
import img6 from 'src/assets/images/company/img6.png';
import img7 from 'src/assets/images/company/img7.png';
import img10 from 'src/assets/images/company/img10.png';
// import img11 from 'src/assets/images/company/img11.png';
import img12 from 'src/assets/images/company/img12.png';
import img13 from 'src/assets/images/company/img13.png';
import img14 from 'src/assets/images/company/img14.png';
import { useTranslation } from 'react-i18next';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  extra?: string | ReactNode;
  table?: ReactNode;
  tableExtra?: string | ReactNode;
  rtl?: boolean;
  spaceBottok?: boolean;
};
const ProductDescription: React.FC<ProductDescriptionProps> = (props) => {
  const { image, title, extra, table, tableExtra, rtl, spaceBottok } = props;
  return (
    <Section classContent={spaceBottok ? 'pb-0' : ''}>
      <div className="b2c-container">
        <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
          <div className="col-md-6 col-lg-6">
            <div>
              <img src={image} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 m-auto">
            <section className="section ">
              <h1 className="text-left">{title}</h1>
              <div
                className="extra mb-5"
                style={{ width: '100%', textAlign: 'left', margin: 'auto' }}
              >
                {extra}
              </div>
              <div className="section-content p-0">
                {table}
                <p className="table-extra">{tableExtra}</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Section>
  );
};

const PlatformSpecify = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ProductDescription
        rtl
        spaceBottok
        image={img6}
        title={t('ourplatform instantaneous')}
        extra={
          <>
            <p>{t('ourplatform instantaneous context')}</p>
            <a className="text-decoration-none" href="/">
              {t('ourplatform learn more link')}
            </a>
          </>
        }
      />
      <ProductDescription
        spaceBottok
        image={img7}
        title={t('ourplatform optimize')}
        extra={<p>{t('ourplatform optimize context')}</p>}
      />
      <ProductDescription
        rtl
        spaceBottok
        image={img10}
        title={t('ourplatform real-time')}
        extra={<p>{t('ourplatform real-time context')}</p>}
      />
      {/* <ProductDescription
        image={img11}
        title={t('ourplatform commitment-free')}
        extra={<p>{t('ourplatform commitment-free context')}</p>}
      /> */}
      <ProductDescription
        spaceBottok
        image={img12}
        title={t('ourplatform payment method')}
        extra={<p>{t('ourplatform payment method context')}</p>}
      />
      <ProductDescription
        rtl
        spaceBottok
        image={img13}
        title={t('ourplatform tracking order')}
        extra={<p>{t('ourplatform tracking order context')}</p>}
      />
      <ProductDescription
        image={img14}
        title={t('ourplatform keep order')}
        extra={<p>{t('ourplatform keep order context')}</p>}
      />
    </div>
  );
};

export default React.memo(PlatformSpecify);
