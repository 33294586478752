import { axiosInceptors } from 'src/services/axiosInceptors';
import { CreateOrderBody, LeadTime } from './types';
import { ResponseServerAPI } from 'src/types/server.types';

const ROOT = 'Order';

export const createOrderAPI = (payload: CreateOrderBody) => {
  return axiosInceptors.post(ROOT + '/create', payload);
};

export const getListLeadTimeAPI = (payload: {
  technologyMaterialIds: Array<string>;
  vendorId: number | string;
}) => {
  return axiosInceptors.post<ResponseServerAPI<LeadTime>>(ROOT + '/leadtime/get', payload);
};
export const getPaymentMethodByMemberAPI = () => {
  return axiosInceptors.get('/payment-methods');
};
