import React from 'react';
import Section from 'src/components/section/Section';
import img2 from 'src/assets/images/resources/img2.png';
import img9 from 'src/assets/images/company/img9.png';
import img10 from 'src/assets/images/company/img10.png';
import img6 from 'src/assets/images/company/img6.png';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const FeatureArticle = () => {
  const { t } = useTranslation();
  const data = [
    {
      img: img2,
      name: t('resourcestopic summary title') as string,
      position: t('resourcestopic summary context') as string,
      uri: 'Summary-of-popular-3D-printing-materials-today',
    },
    {
      img: img9,
      name: t('resourcestopic PLA application title') as string,
      position: t('resourcestopic PLA application article content 1') as string,
      uri: 'What-is-PLA-plastic',
    },
    {
      img: img10,
      name: t('resourcestopic ABS things to know title') as string,
      position: t('resourcestopic ABS things to know article content 1') as string,
      uri: 'Things-to-know-about-ABS-in-3D-printing-technology',
    },
    {
      img: img6,
      name: t('resourcestopic 3D Printing Technology') as string,
      position: t('resourcestopic 3D Printing Technology context') as string,
      uri: '3d-printing-technology-in-clouds-wearing-fashion',
    },
  ];

  return (
    <Section className="article" title={`${t('featured article')}`} spaceTop>
      <div className="b2c-container">
        <div className="row row-gap-2 justify-content-center">
          {data.map((item, index) => (
            <div key={index} className="col-sm-12 col-md-4 col-lg-3">
              <CardProduct
                uri={item.uri}
                src={item.img}
                name={item.name}
                position={item.position}
              />
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

type CardProductProps = {
  src: string;
  name: string;
  position: string;
  uri: string;
};
const CardProduct: React.FC<CardProductProps> = (props) => {
  const { uri, src, name, position } = props;
  return (
    <NavLink className="card-member has-border block no-underline" to={`/resources/topic/${uri}`}>
      <div className="card-member--image">
        <img alt="img-member" src={src} />
      </div>
      <div className="card-product--content">
        <div className="card-product--name">{name}</div>
        <div className="card-product--position">{position}</div>
      </div>
    </NavLink>
  );
};

export default React.memo(FeatureArticle);
