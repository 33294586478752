import { createAction } from '@reduxjs/toolkit';
import { ShippingAddress } from '../shippingAddress/types';
import { BillingAddress } from '../billingAddress/types';
import { Cart, CartBody } from '../cart/types';
import { CreateOrderBody, LeadTime } from './types';
import { ShippingOption, ShippingOptionBody } from '../shapeway/types';
import { TPaymentMethod } from '../order/types';

export const getShippingAddressByMemberInsideCreateOrder = createAction(
  'createOrder/getShippingAddressByMemberInsideCreateOrder'
);
export const getShippingAddressByMemberInsideCreateOrderSuccess = createAction<ShippingAddress[]>(
  'createOrder/getShippingAddressByMemberInsideCreateOrder/success'
);

export const getBillingAddressByMemberInsideCreateOrder = createAction(
  'createOrder/getBillingAddressByMemberInsideCreateOrder'
);
export const getBillingAddressByMemberInsideCreateOrderSuccess = createAction<BillingAddress[]>(
  'createOrder/getBillingAddressByMemberInsideCreateOrder/success'
);

export const getPaymentMethods = createAction('createOrder/getPaymentMethods');
export const getPaymentMethodsSuccess = createAction<TPaymentMethod[]>(
  'createOrder/getPaymentMethods/success'
);

export const getCartInsideCreateOrder = createAction<CartBody>(
  'createOrder/getCartInsideCreateOrder'
);
export const getCartInsideCreateOrderSuccess = createAction<Cart[]>(
  'createOrder/getCartInsideCreateOrder/success'
);

export const getListLeadTime = createAction<{
  technologyMaterialIds: Array<string>;
  vendorId: number | string;
}>('order/getListLeadTime');
export const getListLeadTimeSuccess = createAction<LeadTime>('order/getListLeadTime/success');

export const setFormCreateOrder = createAction<CreateOrderBody>('createOrder/setFormCreateOrder');
export const setPaymentMethodId = createAction<number>('createOrder/setPaymentMethodId');

export const createOrder = createAction<CreateOrderBody>('createOrder/createOrder');
export const createOrderSuccess = createAction('createOrder/createOrder/success');

export const getShippingOptionCreateOrder = createAction<ShippingOptionBody>(
  'createOrder/getShippingOptionCreateOrder'
);
export const getShippingOptionCreateOrderSuccess = createAction<ShippingOption>(
  'createOrder/getShippingOptionCreateOrder/success'
);

export const createOrderError = createAction('createOrder/error');
