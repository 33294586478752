import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'src/components/section/Section';
import instructions1 from 'src/assets/images/instructions1.png';
import instructions2 from 'src/assets/images/instructions2.png';
import instructions3 from 'src/assets/images/instructions3.png';
import instructions4 from 'src/assets/images/instructions4.png';
import instructions5 from 'src/assets/images/instructions5.png';
import instructions6 from 'src/assets/images/instructions6.png';
import instructions7 from 'src/assets/images/instructions7.png';
import instructions8 from 'src/assets/images/instructions8.png';
import instructions9 from 'src/assets/images/instructions9.png';
import instructions10 from 'src/assets/images/instructions10.png';
import instructions11 from 'src/assets/images/instructions11.png';
import instructions12 from 'src/assets/images/instructions12.png';
import instructions13 from 'src/assets/images/instructions13.png';
import { Image } from 'antd';

const PrintingIstructions = () => {
  const { t } = useTranslation();

  return (
    <Section classContent="max-lg:px-[1rem] px-[4.5rem]">
      <div className="md:flex-1 w-full post-container">
        <h1 className="font-semibold mb-1 text-center text-4xl">{t('Payment & Conversion')}</h1>

        <p className="text-lg font-medium mb-2">{t('printing instructions section 1')}</p>
        <p className="text-lg font-medium mb-2 flex items-center">
          {t('printing instructions section 2-1')}{' '}
          <img className="block mb-2 w-6 mx-1" src={instructions13} alt="" />{' '}
          {t('printing instructions section 2-2')}
        </p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions1}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 3')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions2}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 4')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions3}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 5')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions4}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 6')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions5}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 7')}</p>
        <p className="text-lg font-medium mb-2">{t('printing instructions section 8')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions6}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 9')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions7}
          alt=""
        />
        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions8}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 10')}</p>
        <p className="text-lg font-medium mb-2">{t('printing instructions section 11')}</p>
        <p className="text-lg font-bold mb-2">{t('printing instructions section 13')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions9}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 14')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions10}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 15')}</p>
        <p className="text-lg font-medium mb-2">{t('printing instructions section 16')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions11}
          alt=""
        />

        <p className="text-lg font-medium mb-2">{t('printing instructions section 17')}</p>
        <p className="text-lg font-medium mb-2">{t('printing instructions section 18')}</p>

        <Image
          className="block mb-2 w-full"
          wrapperClassName="block m-auto"
          width={'80%'}
          src={instructions12}
          alt=""
        />
      </div>
    </Section>
  );
};

export default React.memo(PrintingIstructions);
