import { useTranslation } from 'react-i18next';
import img1 from 'src/assets/images/resources/img1.png';
import img3 from 'src/assets/images/resources/img3.png';
import img6 from 'src/assets/images/resources/img6.png';

export const useListPost = () => {
  const { t } = useTranslation();
  const data = [
    {
      img: img1,
      name: t('resourcestopic summary title') as string,
      position: t('resourcestopic summary context') as string,
      uri: 'Summary-of-popular-3D-printing-materials-today',
    },
    {
      img: img3,
      name: t('resourcestopic PLA application title') as string,
      position: t('resourcestopic PLA application article content 1') as string,
      uri: 'What-is-PLA-plastic',
    },
    {
      img: img6,
      name: t('resourcestopic ABS things to know title') as string,
      position: t('resourcestopic ABS things to know article content 1') as string,
      uri: 'Things-to-know-about-ABS-in-3D-printing-technology',
    },
    {
      img: img1,
      name: t('resourcestopic 3D Printing Technology') as string,
      position: t('resourcestopic 3D Printing Technology context') as string,
      uri: '3d-printing-technology-in-clouds-wearing-fashion',
    },
    {
      img: img1,
      name: t('resourcestopic application of 3D printing in construction') as string,
      position: t('resourcestopic application of 3D printing in construction context') as string,
      uri: 'application-of-3d-printing-in-construction---a-breakthrough-in-technology',
    },
    {
      img: img1,
      name: t('resourcestopic hot global trend') as string,
      position: t('resourcestopic hot global trend context') as string,
      uri: 'the-application-of-3d-printing-technology-is-becoming-a-hot-global-trend',
    },
  ];

  return { data };
};
