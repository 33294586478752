import React from 'react';
import Section from 'src/components/section/Section';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const B2CCompare = () => {
  const { t } = useTranslation();
  const header = ['', 'FDM', 'SLA', 'SLS', 'MJF'];
  return (
    <Section
      title={t('our online 3d printing service') as string}
      extra={
        <>
          {t('need help choosing? see our')} <a href="/">{t('printingservice guide link')}</a>
        </>
      }
    >
      <div className="b2c-container overflow-auto">
        <Table bordered size="xl">
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              {header.map((e, i) => (
                <th key={'header' + i}>{e}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('maximum build size')}</td>
              <td>500 x 500 x 500 mm</td>
              <td>500 x 500 x 500 mm</td>
              <td>500 x 500 x 500 mm</td>
              <td>500 x 500 x 500 mm</td>
            </tr>
            <tr>
              <td>{t('lead time in days')}</td>
              <td>2 {t('business days')}</td>
              <td>4 {t('business days')}</td>
              <td>3 {t('business days')}</td>
              <td>6 {t('business days')}</td>
            </tr>
            <tr>
              <td>{t('dimensional accuracy')}</td>
              <td>
                {t('lower limit on', {
                  percent: '0.5%',
                  minimet: '0.5',
                  degree: '0.0196 in',
                })}
              </td>
              <td>
                {t('lower limit of', {
                  percent: '0.2%',
                  minimet: '0.127',
                  degree: '±0.005 in',
                })}
              </td>
              <td>
                {t('lower limit of', {
                  percent: '0.5%',
                  minimet: '0.15',
                  degree: '0.006 in ',
                })}
              </td>
              <td>
                {t('lower limit on', {
                  percent: '0.3%',
                  minimet: '0.3',
                  degree: '0.012"',
                })}
              </td>
            </tr>
            <tr>
              <td>{t('layer thickness')}</td>
              <td> 100-300 μm</td>
              <td> 50-100 μm</td>
              <td> 100 μm</td>
              <td> 80 μm</td>
            </tr>
            <tr>
              <td>{t('available materials')}</td>
              <td>10</td>
              <td>11</td>
              <td>2</td>
              <td>2</td>
            </tr>
            <tr>
              <td>{t('prototyping applications')}</td>
              <td>{t('printingservice prototyping applications fdm')}</td>
              <td>{t('printingservice prototyping applications sla')}</td>
              <td>{t('printingservice prototyping applications sls')}</td>
              <td>{t('printingservice prototyping applications mjf')}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Section>
  );
};

export default React.memo(B2CCompare);
