import { v1hubCustomerToken } from 'src/hooks/useAuth';

export const saveAppLocalStorage = (data: any) => {
  const localStorageItem = localStorage.getItem(v1hubCustomerToken);
  const localStorageItemParse = localStorageItem ? JSON.parse(localStorageItem) : '';
  let itemToSave = {};

  if (localStorageItemParse) {
    const { systemEnum, member, ...authentication } = localStorageItemParse;
    itemToSave = {
      systemEnum,
      member,
      ...authentication,
      ...data,
    };
  } else {
    itemToSave = { ...data };
  }

  localStorage.setItem(v1hubCustomerToken, JSON.stringify(itemToSave));
};
