import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TColorContent } from './ColorContents';

const ColorFinishes = ({ materialDetail }: { materialDetail: TColorContent }) => {
  const { t } = useTranslation();
  if (!materialDetail) return null;
  return (
    <Box>
      <div className="w-full">
        {materialDetail.contents &&
          materialDetail.contents?.map((item, i) => (
            <div key={i}>
              <h3 className="text-2xl font-semibold mb-2 mt-4">{t(item.title)}</h3>
              {item.content?.map((item, index) => (
                <p className="text-lg font-normal mb-2" key={index}>
                  {t(item)}
                </p>
              ))}
              {item.images && (
                <div className={`flex my-4 max-[1024px]:gap-2 gap-4 w-full`}>
                  {item.images.map((item, index) => (
                    <div className="w-1/3" key={index}>
                      <img src={item} alt="" className="block" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </Box>
  );
};

export default React.memo(ColorFinishes);
