import React from 'react';
import { Table } from 'react-bootstrap';
import Section from 'src/components/section/Section';
import { useTranslation } from 'react-i18next';

export const AvailableResource = () => {
  const { t } = useTranslation();
  return (
    <div className="container availableResource" style={{ margin: '100px auto' }}>
      <Section
        align="left"
        title={t('available materials for mjf 3d printing') as string}
        extra={t('mjfprintingservice available materials for mjf 3d printing context')}
        extraProps={{
          style: {
            padding: '0 3.5rem',
          },
        }}
        titleProps={{
          style: {
            padding: '5rem 3.5rem 1rem 3.5rem',
          },
        }}
      >
        <Table bordered size="xl">
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th>{t('material')}</th>
              <th>{t('description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>HP PA 12 (Nylon 12)</td>
              <td>{t('mjfprintingservice HP PA 12 (Nylon 12) context')}</td>
            </tr>
            <tr>
              <td>{t('Glass-filled HP PA 12')}</td>
              <td>{t('mjfprintingservice Glass-filled HP PA 12 context')}</td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </div>
  );
};
