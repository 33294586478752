import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import arrowRight from 'src/assets/images/arrow-right.png';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Base4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        descriptionSmall={t('blog Design guidelines banner content') as string}
        titleDark={t('blog Design guidelines banner title') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <h4 className="font-semibold text-xl">{t('blog Design guidelines title 1')}</h4>
          <p>{t('blog Design guidelines section 1')}</p>
          <p>{t('blog Design guidelines section 2')}</p>
          <p>{t('blog Design guidelines section 3')}</p>
          <p>{t('blog Design guidelines section 4')}</p>
          <p>{t('blog Design guidelines section 5')}</p>
          <p>{t('blog Design guidelines section 6')}</p>
          <p>{t('blog Design guidelines section 7')}</p>
          <p>{t('blog Design guidelines section 8')}</p>
          <p>{t('blog Design guidelines section 9')}</p>
          <p>{t('blog Design guidelines section 10')}</p>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Base4);
