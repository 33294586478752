import React from 'react';
import B2CBanner from '../components/laserprinting/B2CBanner';
import IntroduceService from '../components/IntroduceService';
import { IntroducePrinting } from '../components/laserprinting/IntroducePrinting';
import { AvailableResource } from '../components/laserprinting/AvailableResource';
import { Guidelines } from '../components/laserprinting/Guidelines';
import table3 from 'src/assets/images/laserPrinting/table3.png';
import img1 from 'src/assets/images/img1.png';
import { useTranslation } from 'react-i18next';

const LaserPrintingService = () => {
  const { t } = useTranslation();
  return (
    <>
      <B2CBanner />
      <IntroducePrinting />
      <IntroduceService
        rtl
        image={table3}
        title={t('laserprintingservice upload parts')}
        buttonText={t('get an instant quote') as string}
      />
      <AvailableResource />
      <IntroduceService
        rtl
        image={table3}
        title={t('get your 3d printed parts shipped in 4 business days')}
        buttonText={t('get an instant quote') as string}
      />
      <Guidelines />
      <IntroduceService
        dark
        image={img1}
        titleExtra={t('laserprintingservice upload designs') as string}
        buttonText={t('get an instant quote') as string}
      />
    </>
  );
};

export default React.memo(LaserPrintingService);
