import { takeLatest } from 'redux-saga/effects';
import {
  createShippingAddress,
  getByMemberShippingAddress,
  updateShippingAddress,
} from './actions';
import {
  createShippingAddressWORKER,
  getByMemberShippingAddressWORKER,
  updateShippingAddressWORKER,
} from './workers';

export function* shippingAddressSaga() {
  yield takeLatest(createShippingAddress, createShippingAddressWORKER);
  yield takeLatest(updateShippingAddress, updateShippingAddressWORKER);
  yield takeLatest(getByMemberShippingAddress, getByMemberShippingAddressWORKER);
}
