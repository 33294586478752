import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'src/components/section/Section';

const PaymentAndConversion = () => {
  const { t } = useTranslation();

  return (
    <Section classContent="max-lg:px-[1rem] px-[3rem] pt-0">
      <div className="md:flex-1 w-full post-container">
        <h1 className="font-semibold mb-5 text-center text-5xl">{t('Payment and shipping')}</h1>
        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Payment and shipping title 1')}</h3>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.1')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.1.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.2')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.2.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.3')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.3.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.4')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.4.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.4.2')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.5')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.5.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.6')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.6.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.7')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.7.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.8')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.8.1')}</p>

        <h6 className="text-lg font-semibold mb-1 pt-2">
          {t('Payment and shipping subtitle 1.9')}
        </h6>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 1.9.1')}</p>

        <h3 className="text-2xl font-semibold mb-1 pt-2">{t('Payment and shipping title 2')}</h3>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 2.1')}</p>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 2.2')}</p>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 2.3')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Payment and shipping section 2.3.1')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Payment and shipping section 2.3.2')}</p>
        <p className="text-lg font-normal mb-1 ms-3">{t('Payment and shipping section 2.3.3')}</p>
        <p className="text-lg font-normal mb-2">{t('Payment and shipping section 2.4')}</p>
      </div>
    </Section>
  );
};

export default React.memo(PaymentAndConversion);
