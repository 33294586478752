import { ThemeProvider, createTheme } from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { v1hubCustomerToken } from 'src/hooks/useAuth';
import partnerRoutes from 'src/router/routes/partnerRoutes';
import withoutLayout from 'src/router/routes/withoutLayout';
import PageLoading from './components/loading/PageLoading';
import { getMemberProfile, refreshToken, setLanguage } from './features/authentication/actions';
import { NewAuthentication } from './features/authentication/types';
import { Member } from './features/member/types';
import { getEnumSystem, getEnumSystemSuccess } from './features/system/actions';
import { SystemEnum } from './features/system/types';
import { parseJwt } from './helpers/common.helpers';
import { useLocalStorage } from './hooks/useLocalStorage';
import { QueryParamsProvider } from './hooks/useQueryParams';
import { defaultLanguage } from './locales/i18n';
import { LanguageCode } from './locales/type';
import { useChangeLanguage } from './locales/useChangeLanguage';
import ProtectedRoute from './router/ProtectedRoute';
import ScrollToTop from './router/ScrollToTop';
import routes from './router/routes';
import accountRoutes from './router/routes/accountRoutes';
import protectedRoutes from './router/routes/protectedRoutes';
import { useAppDispatch, useAppSelector } from './services/redux';

// Containers
const MainLayout = React.lazy(() => import('./layout/MainLayout'));
const AccountLayout = React.lazy(() => import('./layout/AccountLayout'));
const PartnerMainLayout = React.lazy(() => import('./partnerLayout/PartnerMainLayout'));

function App() {
  const dispatch = useAppDispatch();
  const { member } = useAppSelector((s) => s.authentication);
  const { setLang, getLang } = useLocalStorage();
  const { changeLanguage } = useChangeLanguage();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: { main: '#0076E2' },
          secondary: { main: '#312F30' },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const checkToken = (accessToken: string) => {
      // get expired access tokens
      // if true -> continue process
      // else -> clear tokens and login again
      const parseToken = accessToken ? parseJwt(accessToken) : {};
      return parseToken?.exp ? Date.now() < +parseToken.exp * 1000 : false;
    };

    const checkEnumSystem = (systemEnum: SystemEnum) => {
      return systemEnum ? true : false;
    };

    const v1hub = localStorage.getItem(v1hubCustomerToken);
    if (v1hub) {
      // get expired access tokens
      // if true -> continue process
      // else -> clear tokens and login again
      const v1HubParse: NewAuthentication & { systemEnum: SystemEnum; member?: Member } =
        JSON.parse(v1hub);
      const isExpired = checkToken(v1HubParse.accessToken || '');
      const isSystemEnum = checkEnumSystem(v1HubParse.systemEnum);

      if (!isSystemEnum) {
        dispatch(getEnumSystem());
      } else {
        dispatch(getEnumSystemSuccess(v1HubParse.systemEnum));
      }

      if (isExpired) {
        dispatch(getMemberProfile());
        return;
      }

      if (v1HubParse.refreshToken && v1HubParse?.member?.userId) {
        dispatch(refreshToken({ value: v1HubParse.refreshToken, useId: v1HubParse.member.userId }));
      }
    }
  }, [dispatch, member?.userId]);

  useEffect(() => {
    const currentLang = getLang();
    if (member?.languageSetting) {
      dispatch(setLanguage(member.languageSetting as LanguageCode));
      setLang(member.languageSetting);
      changeLanguage(member.languageSetting as LanguageCode);
    } else {
      if (currentLang) {
        dispatch(setLanguage(currentLang as LanguageCode));
        setLang(currentLang as LanguageCode);
        changeLanguage(currentLang as LanguageCode);
      } else {
        dispatch(setLanguage(defaultLanguage));
        setLang(defaultLanguage);
        changeLanguage(defaultLanguage);
      }
    }
  }, [member?.languageSetting, setLang, changeLanguage, dispatch, getLang]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<PageLoading loading />}>
          <ScrollToTop />
          <QueryParamsProvider>
            <Routes>
              <Route path={'/'} element={<MainLayout />}>
                {routes.map((item, idx) => {
                  return (
                    item.element && <Route key={idx} path={item.path} element={<item.element />} />
                  );
                })}
                {protectedRoutes.map((item, idx) => {
                  return (
                    item.element && (
                      <Route
                        key={idx}
                        path={item.path}
                        element={
                          <ProtectedRoute>
                            <item.element />
                          </ProtectedRoute>
                        }
                      />
                    )
                  );
                })}
              </Route>
              <Route path="/" element={<AccountLayout />}>
                {accountRoutes.map((item, idx) => {
                  return (
                    item.element && (
                      <Route
                        key={idx}
                        path={item.path}
                        element={
                          <ProtectedRoute>
                            <item.element />
                          </ProtectedRoute>
                        }
                      />
                    )
                  );
                })}
              </Route>
              <Route path="/" element={<PartnerMainLayout />}>
                {partnerRoutes
                  .filter((item) => !item.notHasLayout)
                  .map((item, idx) => {
                    return (
                      item.element && (
                        <Route key={idx} path={item.path} element={<item.element />} />
                      )
                    );
                  })}
              </Route>
              {partnerRoutes
                .filter((item) => item.notHasLayout)
                .map((item, idx) => {
                  return (
                    item.element && <Route key={idx} path={item.path} element={<item.element />} />
                  );
                })}
              {withoutLayout.map((item, idx) => {
                return (
                  item.element && <Route key={idx} path={item.path} element={<item.element />} />
                );
              })}
            </Routes>
          </QueryParamsProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
