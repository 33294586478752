import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux-saga/store';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import './css/app.css';
import './css/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './locales/i18n';
// import FacebookChatPlugin from 'src/components/FacebookChatPlugin';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    {/* <FacebookChatPlugin /> */}
    <QueryClientProvider client={queryClient}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <Provider store={store}>
          <Suspense fallback="loading">
            <App />
            <Toaster position="top-left" />
          </Suspense>
        </Provider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
