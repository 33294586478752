import { axiosInceptors } from 'src/services/axiosInceptors';
import { ResponseServerAPI } from 'src/types/server.types';
import {
  Authentication,
  ChangePasswordBody,
  LoginForm,
  NewAuthentication,
  RefreshTokenBody,
} from './types';
import axios from 'axios';
import { Member } from '../member/types';
import { axiosInceptorsBasic } from 'src/services/axiosInceptorsDownload';
import { v1hubCustomerToken } from 'src/hooks/useAuth';

const NAME = process.env.REACT_APP_IDENTITY_API;

export const getJwtInfoAPI = () => {
  return axios.post<ResponseServerAPI<Authentication>>(NAME + '/jwt/info');
};

export const refreshTokenAPI = ({ value, useId }: RefreshTokenBody) => {
  return axios.get(NAME + 'api/v1/authentication/jwt/refresh-token', {
    params: { value, useId },
  });
};

export const revokeTokenAPI = ({ value }: Partial<Pick<RefreshTokenBody, 'value'>>) => {
  if (!value) {
    throw new Error('Value must be required');
  }
  return axios.get(NAME + 'api/v1/authentication/jwt/refresh-token/revoke', {
    params: { value },
  });
};

export const loginAPI = (data: LoginForm) => {
  return axios.post<ResponseServerAPI<NewAuthentication>>(
    NAME + 'api/v1/authentication/jwt/login',
    { ...data }
  );
};

export const manufacturerLoginAPI = (data: LoginForm) => {
  return axios.post<ResponseServerAPI<NewAuthentication>>(
    NAME + 'api/v1/authentication/jwt/manufacturer/login',
    { ...data }
  );
};

export const changePasswordAPI = (data: ChangePasswordBody) => {
  return axios.put<ResponseServerAPI<string>>(
    NAME + `api/v1/idm/users/${data.id}/changepassword`,
    null,
    { params: { ...data } }
  );
};

export const getUserInfoAPI = () => {
  return axiosInceptors.get<any>(NAME + 'connect/userinfo');
};

export const deleteUserInfoAPI = (id: string) => {
  return axiosInceptors.delete<any>(NAME + `api/v1/idm/users/${id}`);
};

export const getMemberProfileAPI = () => {
  return axiosInceptors.get<ResponseServerAPI<Member>>('Member/profile');
};

export const getManufacturerProfileAPI = () => {
  return axiosInceptorsBasic.get<ResponseServerAPI<Member>>('api/v1/Manufacturer/profile');
};

export const updateMemberProfileAPI = (info: Partial<Member>) => {
  return axiosInceptors.post<ResponseServerAPI<Member>>('Member/update', info);
};
