import { ActionSaga } from 'src/types/saga.types';
import { getCountByMemberAPI, getOrderByMemberAPI, getOrderListAPI } from './apis';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import {
  getCountByMemberSuccess,
  getOrderByMemberSuccess,
  getOrderListMoreSuccess,
  orderError,
  setCanLoadMore,
} from './actions';
import { GetListOrderParams } from './types';

export function* getOrderByMemberWORKER({ payload }: ActionSaga<any>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }

    const { data }: SagaReturnType<typeof getOrderByMemberAPI> = yield call(
      getOrderByMemberAPI,
      payload
    );
    yield put(getOrderByMemberSuccess(data.data.content));
  } catch (err) {
    yield put(orderError());
    console.error(err);
  }
}

export function* getOrderListWORKER({ payload }: ActionSaga<GetListOrderParams>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }

    const { data }: SagaReturnType<typeof getOrderByMemberAPI> = yield call(
      getOrderListAPI,
      payload
    );
    yield put(getOrderByMemberSuccess(data.data.content));
    yield put(setCanLoadMore(data.data.page < data.data.totalPages));
  } catch (err) {
    yield put(orderError());
    console.error(err);
  }
}

export function* getOrderListMoreWORKER({ payload }: ActionSaga<GetListOrderParams>) {
  try {
    if (!payload) {
      throw new Error('payload is undefined');
    }

    const { data }: SagaReturnType<typeof getOrderByMemberAPI> = yield call(
      getOrderListAPI,
      payload
    );
    yield put(getOrderListMoreSuccess(data.data.content));
    yield put(setCanLoadMore(data.data.page < data.data.totalPages));
  } catch (err) {
    yield put(orderError());
    console.error(err);
  }
}

export function* getCountByMemberWORKER() {
  try {
    const { data }: SagaReturnType<typeof getCountByMemberAPI> = yield call(getCountByMemberAPI);
    yield put(getCountByMemberSuccess(data.data));
  } catch (err) {
    yield put(orderError());
    console.error(err);
  }
}
