import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import Section from 'src/components/section/Section';
import { useTranslation } from 'react-i18next';

const B2CAction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Section
      title={t('resourcestopic upload fee') as string}
      style={{ background: '#312F30', color: '#fff' }}
    >
      <div className="b2c-container text-center d-flex justify-content-center">
        <Button color="primary" size="lg" onClick={() => navigate('/get-instant-quote')}>
          {t('get an instant quote') as string}
        </Button>
      </div>
    </Section>
  );
};

export default React.memo(B2CAction);
