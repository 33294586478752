import { t } from 'i18next';
import toast from 'react-hot-toast';

export const showSuccessToast = (content?: string) => {
  toast.success(content || t('Action Success'));
};

export const showErrorToast = (content?: string) => {
  toast.error(content || t('Action Error'));
};
