import { useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  rtl?: boolean;
  buttonText?: string;
  titleExtra?: string;
  extra?: string;
  dark?: boolean;
};

const IntroduceService: React.FC<ProductDescriptionProps> = (props) => {
  const { rtl, image, title, buttonText, dark, titleExtra, extra } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div
      style={{
        // marginTop: '132px',
        padding: '80px 0',
        background: dark ? '#312F30' : 'rgb(245, 245, 245)',
      }}
      className="b2c-container section-upload-part"
    >
      <div className="container">
        <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
          <div className="col-md-6 col-lg-6">
            <div className={`${isMobile ? 'm-auto' : rtl ? 'ml-[10%]' : 'mr-[10%]'}`}>
              <img src={image} alt="" />
            </div>
          </div>
          <div className="col-md-6 col-lg-6 m-auto">
            <h1
              style={{
                color: dark ? 'white' : 'black',
              }}
            >
              {title}
            </h1>
            {titleExtra && (
              <p
                style={{
                  color: dark ? 'white' : 'black',
                  fontWeight: '400',
                  fontSize: '32px',
                  lineHeight: '38px',
                }}
              >
                {titleExtra}
              </p>
            )}
            <div
              style={{ fontWeight: '400', fontSize: '18px', lineHeight: '140%', color: '#6B6B6B' }}
              className={`extra mb-4`}
            >
              {extra}
            </div>
            <Button size="lg" color="primary" onClick={() => navigate('/get-instant-quote')}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IntroduceService);
