import { createAction } from '@reduxjs/toolkit';
import { CreatePayPalBody, ExecutePayPal } from './types';
import { PaymentBody } from 'src/features/cart/types';

export const createPayPal = createAction<CreatePayPalBody>('paypal/create');
export const createPayPalSuccess = createAction<string>('paypal/create/success');

export const createPayment = createAction<PaymentBody>('payment/create');

export const executePayPal = createAction<ExecutePayPal>('paypal/execute');
export const executePayPalSuccess = createAction('paypal/execute/success');

export const paypalError = createAction('paypal/error');
