import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';
import arrowRight from 'src/assets/images/arrow-right.png';

const Post5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        titleDark={
          t('resourcestopic application of 3D printing in construction banner tilte') as string
        }
        descriptionSmall={
          t('resourcestopic application of 3D printing in construction banner sub title') as string
        }
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();

  return (
    <Section className="posts">
      <div className="post-container">
        {/* <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('introduction')}
          </span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ height: '57px', borderBottom: '1px solid #312F30' }}
        >
          <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
          <span
            style={{
              marginLeft: '16px',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '24px',
              color: '#000000',
            }}
          >
            {t('resourcestopic the additive')}
          </span>
        </div> */}
        <div className="post-content">
          <h3>{t('resourcestopic application of 3D printing in construction article 1 title')}</h3>
          <p>
            {t('resourcestopic application of 3D printing in construction article 1 context 1')}
          </p>
          <p>
            {t('resourcestopic application of 3D printing in construction article 1 context 2')}
          </p>
          <p>
            {t('resourcestopic application of 3D printing in construction article 1 context 3')}
          </p>
          <p>
            {t('resourcestopic application of 3D printing in construction article 1 context 4')}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 1'
            )}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 2'
            )}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 3'
            )}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 4'
            )}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 5'
            )}
          </p>
          <p>
            {t(
              'resourcestopic application of 3D printing in construction article 1 context 4 sub 6'
            )}
          </p>
          <h3>{t('resourcestopic application of 3D printing in construction article 2 title')}</h3>
          <p>{t('resourcestopic application of 3D printing in construction article 2 context')}</p>
          <h4>
            <b>
              {t('resourcestopic application of 3D printing in construction article 2.1 title')}
            </b>
          </h4>
          <p>
            {t('resourcestopic application of 3D printing in construction article 2.1 context 1')}
            {t('resourcestopic application of 3D printing in construction article 2.1 context 2')}
          </p>
          <h4>
            <b>
              {t('resourcestopic application of 3D printing in construction article 2.2 title')}
            </b>
          </h4>
          <p>
            {t('resourcestopic application of 3D printing in construction article 2.2 context')}
          </p>
          <h4>
            <b>
              {t('resourcestopic application of 3D printing in construction article 2.3 title')}
            </b>
          </h4>
          <p>
            {t('resourcestopic application of 3D printing in construction article 2.3 context 1')}
          </p>
          <p>
            {t('resourcestopic application of 3D printing in construction article 2.3 context 2')}
          </p>
          <p>
            {t('resourcestopic application of 3D printing in construction article 2.3 context 3')}
          </p>
          <p>{t('resourcestopic application of 3D printing in construction article 3 context')}</p>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Post5);
