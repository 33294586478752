import React from 'react';
import B2CBanner from '../../components/resources/PostDetail/PostBanner';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import Section from 'src/components/section/Section';
import DesignReadyBanner from 'src/pages/components/resources/DesignReadyBanner';
import { useTranslation } from 'react-i18next';
import topicbanner2 from 'src/assets/images/resources/topicbanner2.png';

const Post2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <B2CBanner
        background={topicbanner2}
        titleDark={t('resourcestopic PLA application title') as string}
        descriptionSmall={t('') as string}
      />

      <PostContent />

      <FeatureArticle />

      <DesignReadyBanner />
    </>
  );
};

const PostContent = () => {
  const { t } = useTranslation();
  return (
    <Section className="posts">
      <div className="post-container">
        <div className="post-content">
          <p>{t('resourcestopic PLA application article content 1')}</p>
          <p>{t('resourcestopic PLA application article content 2')}</p>
          <p>{t('resourcestopic PLA application article content 3')}</p>

          <div className="post-content--section">
            <h3>{t('resourcestopic PLA application article title 1')}</h3>

            <p>{t('resourcestopic PLA application article content 4')}</p>
            <p>{t('resourcestopic PLA application article content 5')}</p>
            <p>{t('resourcestopic PLA application article content 6')}</p>
            <p>{t('resourcestopic PLA application article content 7')}</p>
            <p>{t('resourcestopic PLA application article content 8')}</p>
            <p>{t('resourcestopic PLA application article content 9')}</p>
            <h3>{t('resourcestopic PLA application article title 2')}</h3>
            <b>{t('resourcestopic PLA application article subtitle 1')}</b>

            <p>{t('resourcestopic PLA application article content 10')}</p>
            <p>{t('resourcestopic PLA application article content 11')}</p>
            <p>{t('resourcestopic PLA application article content 12')}</p>
            <p>{t('resourcestopic PLA application article content 13')}</p>
            <p>{t('resourcestopic PLA application article content 14')}</p>
            <p>{t('resourcestopic PLA application article content 15')}</p>
            <b>{t('resourcestopic PLA application article subtitle 2')}</b>

            <p>{t('resourcestopic PLA application article content 16')}</p>
            <p>{t('resourcestopic PLA application article content 17')}</p>
            <p>{t('resourcestopic PLA application article content 18')}</p>
            <p>{t('resourcestopic PLA application article content 19')}</p>
            <p>{t('resourcestopic PLA application article content 20')}</p>
            <b>{t('resourcestopic PLA application article subtitle 3')}</b>

            <p>{t('resourcestopic PLA application article content 21')}</p>
            <p>{t('resourcestopic PLA application article content 22')}</p>
            <p>{t('resourcestopic PLA application article content 23')}</p>
            <p>{t('resourcestopic PLA application article content 24')}</p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Post2);
