import React from 'react';
import Section from 'src/components/section/Section';
import { Table } from 'react-bootstrap';
import CollapseCard from 'src/components/collapse/CollapseCard';
import arrowRight from 'src/assets/images/3dPrinting/arrow-right.png';
import { useTranslation } from 'react-i18next';

const B2CChooseProduct = () => {
  const { t } = useTranslation();
  return (
    <Section
      title={t('printingservice choose materials') as string}
      extra={
        <>
          {t('printingservice need help')} <a href="/">{t('printingservice selecting link')}</a>
          &nbsp;{t('for your application')}
        </>
      }
      style={{ background: '#F5F5F5' }}
    >
      <div className="b2c-container choose-product">
        <div className="row mb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 overflow-auto">
            <Table
              bordered
              size="xl"
              style={{
                borderCollapse: 'collapse',
                borderRadius: '8px',
                overflow: 'hidden',
                fontFamily: "'Barlow'",
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '140%',
              }}
            >
              <thead
                style={{
                  background: '#0076E2',
                  color: 'white',
                }}
              >
                <tr>
                  <th>PLA (1 tyle)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nylon (1 tyle)</td>
                </tr>
                <tr>
                  <td>ABS (1 tyle)</td>
                </tr>
                <tr>
                  <td>PETG (1 tyle)</td>
                </tr>
                <tr>
                  <td>TPU (1 tyle)</td>
                </tr>
                <tr>
                  <td>ASA (1 tyle)</td>
                </tr>
                <tr>
                  <td>PEI (1 tyle)</td>
                </tr>
                <tr>
                  <td>PC (1 tyle)</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 overflow-auto">
            <Table bordered size="xl">
              <thead
                style={{
                  background: '#0076E2',
                  color: 'white',
                }}
              >
                <tr>
                  <th>SLS ({t('selective laser sintering')})</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nylon (2 tyles)</td>
                </tr>
              </tbody>
            </Table>
            <Table bordered size="xl">
              <thead
                style={{
                  background: '#0076E2',
                  color: 'white',
                }}
              >
                <tr>
                  <th>MJF ({t('multiJet fusion')})</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nylon (2 tyles)</td>
                </tr>
              </tbody>
            </Table>
            <Table bordered size="xl">
              <thead
                style={{
                  background: '#0076E2',
                  color: 'white',
                }}
              >
                <tr>
                  <th>SLA ({t('stereolithography')})</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nylon (2 tyles)</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="text-left">{t('printingservice standard processing')}</h1>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <CollapseCard title="FMD" open />
            <CollapseCard title="SLA" />
            <CollapseCard title="SLS/MJF" />
          </div>
        </div>
      </div>
      <Section
        title={t('printingservice how ensure') as string}
        extra={t('printingservice how ensure context')}
      >
        <div className="b2c-container">
          <div
            style={{
              maxWidth: '846px',
              alignItems: 'center',
              textAlign: 'center',
              border: '1px solid #312F30',
              borderRadius: '8px',
              margin: 'auto',
              background: 'white',
            }}
          >
            <div
              className="d-flex align-center justify-content-center"
              style={{
                height: '64px',
                fontFamily: "'Barlow'",
                background: 'rgb(0, 88, 136)',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '24px',
                lineHeight: '29px',
                color: '#FFFFFF',
                alignItems: 'center',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              {t('standard')}
            </div>
            <div className="p-4">
              <CardLine text={t('quantity of parts') as string} />
              <CardLine text={t('number of parts inspected') as string} />
              <CardLine text={t('surface finish') as string} />
              <CardLine text={t('tolerances') as string} />
            </div>
          </div>
        </div>
      </Section>
    </Section>
  );
};

type CardLineProps = {
  text?: string;
};

const CardLine: React.FC<CardLineProps> = (props) => {
  const { text } = props;
  return (
    <div className="d-flex align-items-center mb-3">
      <img style={{ width: '16px', height: '11.33px' }} src={arrowRight} alt="" />
      <span
        style={{
          marginLeft: '16px',
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default React.memo(B2CChooseProduct);
