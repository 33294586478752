import { axiosInceptors } from 'src/services/axiosInceptors';
import { ResponseServerAPI } from 'src/types/server.types';
import { CreatePayPalBody, ExecutePayPal } from './types';
import { PaymentBody } from 'src/features/cart/types';

const ROOT = 'paypal';

const isTestUrl = process.env.NODE_ENV === 'development';

export const createPayPalAPI = (payload: CreatePayPalBody) => {
  return axiosInceptors.post<ResponseServerAPI<string>>(ROOT + '/create', {
    ...payload,
    isTestUrl,
  });
};

export const createPayment = (payload: PaymentBody) => {
  return axiosInceptors.post<ResponseServerAPI<string>>('payment/create', {
    ...payload,
    isTestUrl,
  });
};

export const executePayPalAPI = (payload: ExecutePayPal) => {
  return axiosInceptors.post<ResponseServerAPI<any>>(
    ROOT + `/execute?paymentId=${payload.paymentId}&payerId=${payload.payerId}`
  );
};
