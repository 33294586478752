import { createAction } from '@reduxjs/toolkit';
import { Member } from '../member/types';
import {
  Authentication,
  AuthenticationUI,
  LoginForm,
  NewAuthentication,
  RefreshTokenBody,
} from './types';
import { LanguageCode } from 'src/locales/type';

export const jwtInfo = createAction<undefined>('authentication/jwt/info');
export const jwtInfoSuccess = createAction<Authentication>('authentication/jwt/info/success');
export const jwtInfoError = createAction<undefined>('authentication/jwt/info/error');

export const getUserInfo = createAction('authentication/getUserInfo');
export const getUserInfoSuccess = createAction<Member>('authentication/getUserInfo/success');

export const setLanguage = createAction<LanguageCode>('language');
export const setCountryGlobal = createAction<string>('country');

export const getMemberProfile = createAction('authentication/getMemberProfile');
export const getMemberProfileSuccess = createAction<any>('authentication/getMemberProfile/success');

export const getManufacturerProfile = createAction('authentication/getManufacturerProfile');
export const getManufacturerProfileSuccess = createAction<any>(
  'authentication/getManufacturerProfile/success'
);

export const login = createAction<LoginForm>('authentication/jwt/login');
export const loginSuccess = createAction<NewAuthentication>('authentication/jwt/login/success');
export const loginError = createAction<undefined>('authentication/jwt/login/error');

export const manufacturerLogin = createAction<LoginForm>('authentication/manufacturer/jwt/login');
export const manufacturerLoginSuccess = createAction<NewAuthentication>(
  'authentication/manufacturer/jwt/login/success'
);
export const manufacturerLoginError = createAction<undefined>(
  'authentication/manufacturer/jwt/login/error'
);

export const refreshToken = createAction<Required<Pick<RefreshTokenBody, 'value' | 'useId'>>>(
  'authentication/jwt/refreshToken'
);
export const refreshTokenSuccess = createAction<NewAuthentication>(
  'authentication/jwt/refreshToken/success'
);

export const logout = createAction<Partial<Omit<RefreshTokenBody, 'useId'>>>(
  'authentication/jwt/logout'
);

export const authenticationError = createAction('authentication/error');
export const setUI = createAction<AuthenticationUI>('authentication/setUI');
