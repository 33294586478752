import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import check from 'src/assets/images/3dPrinting/check.png';
import { useTranslation } from 'react-i18next';

type CollapseCardProps = {
  title?: string;
  open?: boolean;
};

const CollapseCard: React.FC<CollapseCardProps> = (props) => {
  const { title, open } = props;
  const [show, setShow] = useState(open ?? false);
  const { t } = useTranslation();

  return (
    <div className={`collapseCard`}>
      <button
        style={{
          borderBottom: show ? '0px' : '1px solid rgb(49, 47, 48)',
          borderBottomLeftRadius: show ? '0px' : '8px',
          borderBottomRightRadius: show ? '0px' : '8px',
        }}
        className={`collapsible-header ${show ? 'active' : ''}`}
        onClick={() => setShow(!show)}
      >
        {title}
      </button>
      <Collapse in={show}>
        <div className="collapsible">
          <p>{t('printingservice standard no additional')}</p>
          <div className="d-flex align-items-center mt-3">
            <img alt="" style={{ width: '16px', height: '11.33px' }} src={check} />
            <span>{t('printingservice all surfaces')}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <img alt="" style={{ width: '16px', height: '11.33px' }} src={check} />
            <span>{t('printingservice all surfaces')}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <img alt="" style={{ width: '16px', height: '11.33px' }} src={check} />
            <span>{t('printingservice all surfaces')}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <img alt="" style={{ width: '16px', height: '11.33px' }} src={check} />
            <span>{t('printingservice all surfaces')}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <img alt="" style={{ width: '16px', height: '11.33px' }} src={check} />
            <span>{t('printingservice all surfaces')}</span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default React.memo(CollapseCard);
