import React from 'react';
import Banner from 'src/components/banner/Banner';
import Button from 'src/components/button/Button';
import banner99 from 'src/assets/images/banner99.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

const B2CBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Banner
      style={{
        height: '466px',
        background: `url(${banner99})`,
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="xl">
        <Banner.Text
          titleDark={t('stereolithography (sla) 3d printing service')}
          descriptionSmall={t('slaprintingservice slogan')}
          descriptionSmallProps={{
            style: {
              width: '100%',
            },
          }}
          action={
            <Button size="lg" color="primary" onClick={() => navigate('/get-instant-quote')}>
              {t('get an instant 3d printing quote')}
            </Button>
          }
        />
      </Container>
    </Banner>
  );
};

export default React.memo(B2CBanner);
