import { NewAuthentication } from 'src/features/authentication/types';

export const v1hubCustomerToken = 'v1hub';
export const v1hubManufacturerToken = 'v1hub_manufacturer';

export default function useAuth() {
  const storage = localStorage.getItem(v1hubCustomerToken);
  const storageParse: NewAuthentication = storage ? JSON.parse(storage) : '';

  const storageManufacturer = localStorage.getItem(v1hubManufacturerToken);
  const storageManufacturerParse: NewAuthentication = storageManufacturer
    ? JSON.parse(storageManufacturer)
    : '';

  return {
    accessToken: storageParse ? storageParse?.accessToken : '',
    accessTokenManufacturer: storageManufacturerParse ? storageManufacturerParse?.accessToken : '',
  };
}
export const getAccessToken = () => {
  const storageManufacturer = localStorage.getItem(v1hubManufacturerToken);
  const storageManufacturerParse: NewAuthentication = storageManufacturer
    ? JSON.parse(storageManufacturer)
    : '';

  return storageManufacturerParse ? storageManufacturerParse?.accessToken : '';
};
