import { axiosInceptors } from 'src/services/axiosInceptors';
import { BillingAddressBody } from './types';

const ROOT = 'Order/billingaddress';

export const createBillingAddressAPI = (payload: BillingAddressBody) => {
  return axiosInceptors.post(ROOT + '/create', { ...payload });
};

export const updateBillingAddressAPI = (payload: BillingAddressBody) => {
  return axiosInceptors.put(ROOT + '/update', { ...payload });
};

export const getBillingAddressByMemberAPI = () => {
  return axiosInceptors.get(ROOT + '/get-by-member');
};
