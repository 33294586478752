import React, { ReactNode } from 'react';
import img1 from 'src/assets/images/fdmPrinting/img1.png';
import img2 from 'src/assets/images/fdmPrinting/img2.png';
import img3 from 'src/assets/images/fdmPrinting/img3.png';
import img4 from 'src/assets/images/fdmPrinting/img4.png';
import img5 from 'src/assets/images/fdmPrinting/img5.png';
import IntroduceService from '../IntroduceService';
import { useTranslation } from 'react-i18next';
import { Container, useMediaQuery } from '@mui/material';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  extra?: string | ReactNode;
  table?: ReactNode;
  tableExtra?: string | ReactNode;
  rtl?: boolean;
};
const ProductDescription: React.FC<ProductDescriptionProps> = (props) => {
  const { t } = useTranslation();
  const { image, title, extra, table, tableExtra, rtl } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Container maxWidth="xl" sx={{ margin: '132px auto' }}>
      <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
        <div className="col-md-6 col-lg-6">
          <div className={`${isMobile ? 'm-auto' : rtl ? 'ml-[15%]' : 'mr-[15%]'}`}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 m-auto">
          <section className="section ">
            <h1 className="text-left">{title}</h1>
            <div
              className="extra"
              style={{ width: '100%', textAlign: 'left', marginBottom: '20px', marginTop: '0' }}
            >
              {extra}
            </div>
            <div className="section-content p-0">
              {table}
              <p className="table-extra">{tableExtra}</p>
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
};

const DescriptionList = () => {
  const { t } = useTranslation();
  return (
    <>
      <ProductDescription
        image={img1}
        title={t('hubs manufacturing standards')}
        extra={t('slaprintingservice hubs manufacturing standards context')}
        table={
          <table className="table table-xl table-bordered">
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('requirement')}</th>
                <th>{t('specification')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('maximum build size')}</td>
                <td>395 x 500 x 395 mm (15.53&apos; x 19.68&apos; x 15.53&apos;)</td>
              </tr>
              <tr>
                <td>{t('standard lead time')}</td>
                <td>5 {t('business days')}</td>
              </tr>
              <tr>
                <td>{t('dimensional accuracy')}</td>
                <td>
                  {t('lower limit of', {
                    percent: '0.5%',
                    minimet: '0.15',
                    degree: '0.006 in',
                  })}
                </td>
              </tr>
              <tr>
                <td>{t('layer height')}</td>
                <td>50-100 um</td>
              </tr>
              <tr>
                <td>{t('infill')}</td>
                <td>0.2 mm</td>
              </tr>
            </tbody>
          </table>
        }
      />
      <ProductDescription
        rtl
        image={img2}
        title={t('industrial materials for sla')}
        extra={t('slaprintingservice industrial materials for sla context')}
        table={
          <table
            className="table table-xl table-bordered"
            style={{
              borderCollapse: 'collapse',
              borderRadius: '8px',
              overflow: 'hidden',
              fontFamily: 'Barlow',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '140%',
            }}
          >
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('resin')}</th>
                <th>{t('description')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('accura clearvue')}</td>
                <td>{t('slaprintingservice accura clearvue context')}</td>
              </tr>
              <tr>
                <td>{t('accura xtreme white 200')}</td>
                <td>{t('slaprintingservice accura xtreme white 200 context')}</td>
              </tr>
              <tr>
                <td>{t('accura 25')}</td>
                <td>{t('slaprintingservice accura 25 context')}</td>
              </tr>
            </tbody>
          </table>
        }
      />

      <IntroduceService
        image={img5}
        title={t('laserprintingservice upload parts')}
        buttonText={t('get an instant quote') as string}
      />

      <ProductDescription
        rtl
        image={img3}
        title={t('our sla manufacturing standard')}
        extra={
          <>
            {t('fdmprintingservice our fdm manufacturing standards context')}

            <br />
            <ul className="mt-4">
              <li>{t('slaprintingservice standard 1')}</li>
              <li>{t('slaprintingservice standard 2')}</li>
              <li>{t('slaprintingservice standard 3')}</li>
              <li>{t('slaprintingservice standard 4')}</li>
            </ul>
          </>
        }
      />
      <ProductDescription
        image={img4}
        title={t('design guidelines for sla')}
        extra={t('fdmprintingservice design guidelines for fdm context')}
        table={
          <table
            className="table table-xl table-bordered"
            style={{
              borderCollapse: 'collapse',
              borderRadius: '8px',
              overflow: 'hidden',
              fontFamily: 'Barlow',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '140%',
            }}
          >
            <thead style={{ background: 'rgb(0, 88, 136)', color: 'white' }}>
              <tr>
                <th>{t('feature')}</th>
                <th>{t('recommended size')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('unsupported walls')}</td>
                <td>1.0 mm (0.0393&apos;&apos;)</td>
              </tr>
              <tr>
                <td>{t('supported walls')}</td>
                <td>0.5 mm (0.0197&apos;&apos;)</td>
              </tr>
              <tr>
                <td>{t('minimum feature size')}</td>
                <td>0.2 mm (0.00787&apos;&apos;)</td>
              </tr>
              <tr>
                <td>{t('minimum hole diameter')}</td>
                <td>4.0 mm (0.157&apos;&apos;)</td>
              </tr>
            </tbody>
          </table>
        }
        tableExtra={
          <>
            {t('learn more about the process in our')}{' '}
            <a className="text-decoration-none" href="/">
              {t('knowledge base article')}
            </a>
          </>
        }
      />
    </>
  );
};

export default React.memo(DescriptionList);
