import { createAction } from '@reduxjs/toolkit';
import { CreateAccountForm, ForgotForm, VerifyEmail } from './types';

export const idmRegister = createAction<{ form: CreateAccountForm; callback: () => void }>(
  'idm/users/register'
);
export const idmRegisterSuccess = createAction('idm/users/register/success');
export const idmRegisterError = createAction('idm/users/register/error');

export const forgotPassword = createAction<ForgotForm>('idm/users/forgot-password');
export const forgotPasswordSuccess = createAction('idm/users/forgot-password/success');
export const forgotPasswordError = createAction('idm/users/forgot-password/error');

export const idmVerifyEmail = createAction<VerifyEmail>('idm/users/verify-email');
export const idmVerifyEmailSuccess = createAction('idm/users/verify-email/success');
export const idmVerifyEmailError = createAction('idm/users/verify-email/error');
