import { useCallback } from 'react';

export const localStorageKey = {
  LANG: 'LANGUAGE',
  COUNTRY: 'COUNTRY',
};

export const useLocalStorage = () => {
  const setLang = useCallback((value: string) => {
    localStorage.setItem(localStorageKey.LANG, value);
  }, []);

  const getLang = useCallback(() => {
    return localStorage.getItem(localStorageKey.LANG);
  }, []);

  const setCountry = useCallback((value: string) => {
    localStorage.setItem(localStorageKey.COUNTRY, value);
  }, []);

  const getCountry = useCallback(() => {
    return localStorage.getItem(localStorageKey.COUNTRY);
  }, []);

  return { setLang, getLang, setCountry, getCountry };
};
