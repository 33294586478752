import { axiosInceptors } from 'src/services/axiosInceptors';
import { Cart, CartBody, PaymentBody } from './types';
import { ResponseServerAPI } from 'src/types/server.types';

const ROOT = 'Cart';

export const createOrUpdateCartAPI = (payload: CartBody) => {
  return axiosInceptors.post(ROOT + '/create-or-update', [{ ...payload }]);
};

export const changeQuantityCartAPI = (payload: CartBody) => {
  return axiosInceptors.post(ROOT + '/change-quantity', payload);
};

export const getViewCartAPI = (payload: CartBody) => {
  if (!payload?.memberId) {
    throw new Error('MemberId is required!');
  }
  return axiosInceptors.get<ResponseServerAPI<Cart[]>>(ROOT + '/view-cart', {
    params: { memberId: payload.memberId },
  });
};

export const useCartAPI = () => {
  return axiosInceptors.get<Cart[]>(ROOT + '/use-cart');
};

export const deleteCartAPI = (payload: CartBody) => {
  return axiosInceptors.delete(ROOT + '/' + payload?.id);
};

export const emptyCartAPI = () => {
  return axiosInceptors.delete(ROOT + '/empty-cart');
};

export const createFromOrderAPI = (payload: CartBody) => {
  if (!payload.orderId) {
    throw new Error('Order is required!');
  }
  return axiosInceptors.post(ROOT + '/re-create', payload);
};

export const checkPaymentStatus = (id: string) => {
  return axiosInceptors.get(`payos/transaction/${id}`);
};

export const createPayment = (body: PaymentBody) => {
  return axiosInceptors.post(`payment/create`, body);
};

export const getPromotion = (subTotal: number, totalItem: number, promotionCode?: string) => {
  if (!promotionCode) throw new Error();

  return axiosInceptors.get(`Promotion/get-by-code`, {
    params: {
      promotionCode,
      subTotal,
      totalItem,
    },
  });
};
