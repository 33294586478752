import { all, fork } from 'redux-saga/effects';
import { adminUserSaga } from '../features/adminUser/saga';
import { authenticationSaga } from '../features/authentication/saga';
import { cartSaga } from '../features/cart/sagas';
import { shippingAddressSaga } from '../features/shippingAddress/sagas';
import { createOrderSaga } from '../features/createOrder/sagas';
import { billingAddressSaga } from '../features/billingAddress/sagas';
import { paypalSaga } from '../features/paypal/sagas';
import { orderSaga } from '../features/order/sagas';
import { systemSaga } from '../features/system/sagas';

export default function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(adminUserSaga),
    fork(cartSaga),
    fork(shippingAddressSaga),
    fork(billingAddressSaga),
    fork(createOrderSaga),
    fork(paypalSaga),
    fork(orderSaga),
    fork(systemSaga),
  ]);
}
