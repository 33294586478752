/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import { forgotPassword, idmRegister, idmVerifyEmail } from './actions';
import { forgotPasswordWorker, registerUserWorker, verifyEmailWorker } from './workers';

export function* adminUserSaga() {
  yield takeLatest(idmRegister, registerUserWorker);
  yield takeLatest(forgotPassword, forgotPasswordWorker);
  yield takeLatest(idmVerifyEmail, verifyEmailWorker);
}
