/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  getManufacturerProfile,
  getMemberProfile,
  getUserInfo,
  jwtInfo,
  login,
  logout,
  manufacturerLogin,
  refreshToken,
} from './actions';
import {
  getJwtInfoWorker,
  getMemberProfileWORKER,
  getUserInfoWORKER,
  loginWorker,
  logoutWorker,
  refreshTokenWorker,
  manufacturerLoginWorker,
  getManufacturerProfileWORKER,
} from './workers';

export function* authenticationSaga() {
  yield takeLatest(jwtInfo, getJwtInfoWorker);
  yield takeLatest(getUserInfo, getUserInfoWORKER);
  yield takeLatest(login, loginWorker);
  yield takeLatest(manufacturerLogin, manufacturerLoginWorker);
  yield takeLatest(getMemberProfile, getMemberProfileWORKER);
  yield takeLatest(getManufacturerProfile, getManufacturerProfileWORKER);
  yield takeLatest(refreshToken, refreshTokenWorker);
  yield takeLatest(logout, logoutWorker);
}
