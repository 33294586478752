import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCode } from 'src/locales/type';
import VN from 'src/assets/images/vietnam.jpg';
import KO from 'src/assets//images/korea.jpg';
import EN from 'src/assets/images/united-kingdom.jpg';

export interface IDataLang {
  image: string;
  lang: string;
  country: string;
  value: LanguageCode;
}

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const changeLanguage = useCallback(
    (language: LanguageCode) => {
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  const dataLang: IDataLang[] = [
    {
      image: VN,
      lang: 'Tiếng Việt',
      country: 'VietNam',
      value: LanguageCode.vi,
    },
    {
      image: EN,
      lang: 'English',
      country: 'England',
      value: LanguageCode.en,
    },
    {
      image: KO,
      lang: 'Korean',
      country: 'Korea',
      value: LanguageCode.ko,
    },
  ];

  return { changeLanguage, dataLang };
};
