import React from 'react';
import { Table } from 'react-bootstrap';
import Section from 'src/components/section/Section';
import { useTranslation } from 'react-i18next';

export const Guidelines = () => {
  const { t } = useTranslation();
  return (
    <div className="container guidelines" style={{ margin: '100px auto' }}>
      <Section
        align="left"
        title={t('design guidelines for mjf') as string}
        extra={t('mjfprintingservice design guidelines for mjf context')}
        extraProps={{
          style: {
            padding: '0 3.5rem',
          },
        }}
        titleProps={{
          style: {
            padding: '5rem 3.5rem 1rem 3.5rem',
          },
        }}
      >
        <Table bordered size="xl">
          <thead
            style={{
              background: '#0076E2',
              color: 'white',
            }}
          >
            <tr>
              <th>{t('feature')}</th>
              <th>{t('recommended size')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('unsupported walls')}</td>
              <td>1.0 mm (0.040&apos;&apos;)</td>
            </tr>
            <tr>
              <td>{t('supported walls')}</td>
              <td>0.7 mm (0.030&apos;&apos;)</td>
            </tr>
            <tr>
              <td>{t('minimum detail size')}</td>
              <td>0.25 mm (0.010&apos;&apos;)</td>
            </tr>
            <tr>
              <td>{t('minimum hole size')}</td>
              <td>1.0 mm (0.040&apos;&apos;)</td>
            </tr>
            <tr>
              <td>{t('moving parts')}</td>
              <td>
                {t('between moving surfaces', {
                  value: '0.5 mm (0.020&apos;&apos;)',
                })}
              </td>
            </tr>
            <tr>
              <td>{t('assembly clearance')}</td>
              <td>
                {t('between moving surfaces', {
                  value: '0.4 mm (0.016&apos;&apos;)',
                })}
              </td>
            </tr>
            <tr>
              <td>{t('maximum wall thickness')}</td>
              <td>{t('laserprintingservice maximum wall thickness context')}</td>
            </tr>
          </tbody>
        </Table>
      </Section>
    </div>
  );
};
