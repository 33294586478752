import React from 'react';
import Section from 'src/components/section/Section';
import arrow from 'src/assets/images/resources/arrow.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ListTopic = () => {
  const { t } = useTranslation();

  const listTopic = [
    {
      title: t('3d printing'),
      description: t('resources 3d printing'),
      link: 'topic/knowledge-base/3d-printing',
    },
    // {
    //   title: t('3d printing applications'),
    //   description: t('resources 3d printing applications'),
    // },
    {
      title: t('cnc machining'),
      description: t('resources cnc machining'),
      link: 'topic/knowledge-base/cnc-machining',
    },
    {
      title: t('injection molding'),
      description: t('resources injection molding'),
      link: 'topic/knowledge-base/injection-molding',
    },
    {
      title: t('design guidelines'),
      description: t('resources design guidelines'),
      link: 'topic/knowledge-base/design-guidelines',
    },
    {
      title: t('cad & file preparation'),
      description: t('resources cad & file preparation'),
      link: 'topic/knowledge-base/cad-&-file-preparation',
    },
    // {
    //   title: t('tips & advice'),
    //   description: t('resources tips & advice'),
    // },
    // {
    //   title: t('post processing & finishing'),
    //   description: t('resources post processing & finishing'),
    // },
    // {
    //   title: t('tables & cheat sheets'),
    //   description: t('resources tables & cheat sheets'),
    // },
    {
      title: t('educational resources'),
      description: t('resources educational resources'),
      link: 'topic/knowledge-base/educational-resources',
    },
  ];

  return (
    <Section className="list-topic">
      <div className="b2c-container">
        <div className="row">
          {listTopic.map((item, index) => (
            <Topic key={index} title={item.title} description={item.description} link={item.link} />
          ))}
        </div>
      </div>
    </Section>
  );
};

type TopicProps = {
  title: string;
  description: string;
  link: string;
};
const Topic: React.FC<TopicProps> = (props) => {
  const { title, description, link } = props;
  const { t } = useTranslation();
  return (
    <div
      className="col-sm-6 col-md-6 col-lg-4 p-4"
      style={{
        padding: '10px',
        margin: '0',
      }}
    >
      <div
        className="p-4"
        style={{
          position: 'relative',
          height: '216px',
          background: '#FBFBFB',
          border: '1px solid #E7EAEF',
        }}
      >
        <h4>{title}</h4>
        <div
          className="extra mb-3"
          style={{
            textAlign: 'left',
            margin: 'auto',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </div>
        <div
          className="d-flex align-items-center"
          style={{
            position: 'absolute',
            bottom: '24px',
          }}
        >
          <img style={{ width: '18px', height: '18px' }} src={arrow} alt="" />
          <NavLink
            style={{
              fontWeight: '500',
              color: '#0076E2',
              margin: '0 5px',
              textDecoration: 'none',
            }}
            to={link}
          >
            {t('read more') as string}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ListTopic);
