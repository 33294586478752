import React from 'react';
import B2CBanner from '../../components/resources/B2CBanner';
import ListTopic from 'src/pages/components/resources/ListTopic';
import IntroduceService from 'src/pages/components/IntroduceService';
import img5 from 'src/assets/images/img5.png';
import ProductView from 'src/pages/components/resources/ProductView';
import FeatureArticle from 'src/pages/components/resources/FeatureArticle';
import B2CAction from 'src/pages/components/layout/B2CAction';
import { useTranslation } from 'react-i18next';

const ResourceList = () => {
  const { t } = useTranslation();
  return (
    <>
      <B2CBanner />

      <ListTopic />

      <IntroduceService
        rtl
        image={img5}
        title={`${t('resources instantaneous')}`}
        // extra="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        buttonText={t('get an instant quote') as string}
      />

      <ProductView />

      <FeatureArticle />

      <B2CAction />
    </>
  );
};

export default React.memo(ResourceList);
