import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Section from 'src/components/section/Section';
import img1 from 'src/assets/images/resources/img1.png';
import Button from 'src/components/button/Button';
import { useTranslation } from 'react-i18next';

type ProductDescriptionProps = {
  image?: string;
  title?: string | ReactNode;
  extra?: string | ReactNode;
  table?: ReactNode;
  tableExtra?: string | ReactNode;
  rtl?: boolean;
};
const ProductDescription: React.FC<ProductDescriptionProps> = (props) => {
  const { image, title, extra, rtl } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Section className="productDescription">
      <div className={`row row-gap-2 ${rtl ? 'flex-row-reverse' : ''}`}>
        <div className="col-md-6 col-lg-6">
          <div>
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 m-auto">
          <section className="section ">
            <h1 className="text-left">{title}</h1>
            <div
              className="extra mb-5"
              style={{ width: '100%', textAlign: 'left', margin: 'auto' }}
            >
              {extra}
            </div>
            <Button
              style={{
                background: '#F2F2F2',
                fontWeight: '600',
                fontSize: '20px',
              }}
              size="lg"
              color="grey"
              onClick={() => navigate('/resources/topic/knowledge-base/cnc-machining')}
            >
              {t('learn more')}
            </Button>
          </section>
        </div>
      </div>
    </Section>
  );
};

const ProductView = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="b2c-container">
        <ProductDescription
          image={img1}
          title={t('resources selecting material')}
          extra={t('resources selecting material context')}
        />
      </div>
    </>
  );
};

export default React.memo(ProductView);
