import { takeLatest } from 'redux-saga/effects';
import {
  createOrder,
  getBillingAddressByMemberInsideCreateOrder,
  getCartInsideCreateOrder,
  getListLeadTime,
  getPaymentMethods,
  getShippingAddressByMemberInsideCreateOrder,
  getShippingOptionCreateOrder,
} from './actions';
import {
  createOrderWORKER,
  getBillingAddressByMemberInsideCreateOrderWORKER,
  getCartInsideCreateOrderWORKER,
  getListLeadTimeWORKER,
  getPaymentMethodsWORKER,
  getShippingAddressByMemberInsideCreateOrderWORKER,
  getShippingOptionCreateOrderWORKER,
} from './workers';

export function* createOrderSaga() {
  yield takeLatest(
    getShippingAddressByMemberInsideCreateOrder,
    getShippingAddressByMemberInsideCreateOrderWORKER
  );
  yield takeLatest(
    getBillingAddressByMemberInsideCreateOrder,
    getBillingAddressByMemberInsideCreateOrderWORKER
  );
  yield takeLatest(getCartInsideCreateOrder, getCartInsideCreateOrderWORKER);
  yield takeLatest(createOrder, createOrderWORKER);
  yield takeLatest(getListLeadTime, getListLeadTimeWORKER);
  yield takeLatest(getShippingOptionCreateOrder, getShippingOptionCreateOrderWORKER);
  yield takeLatest(getPaymentMethods, getPaymentMethodsWORKER);
}
